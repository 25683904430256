import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';


@Component({
  selector: 'app-table-order-menu-categories',
  templateUrl: './table-order-menu-categories.component.html',
  styleUrls: ['./table-order-menu-categories.component.scss'],
})
export class TableOrderMenuCategoriesComponent implements OnInit {

  cat:any;
  constructor(private popoverCtrl: PopoverController, navParams: NavParams) {
    this.cat = navParams.get('cat');
   }

   ngOnInit() {}

   async selectCategory(selectedCat:number) {   
     await this.popoverCtrl.dismiss({newCat: selectedCat});
   }

}
