<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button fill="outline"  (click)="cancelModal()">
                Cancel
            </ion-button>
          </ion-buttons>
        <ion-buttons slot="end">
            <ion-button fill="outline" (click)="addToCart()" 
              [disabled]="((formGroup.invalid)||(!order)||(category.viewOnly) || ((order) && (order?.orderType==='TABLE ORDER')))">
              Add to Order
            </ion-button>
          </ion-buttons>
    </ion-toolbar>
  </ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12" class="ion-text-center"><h3>Menu Item</h3></ion-col>
    </ion-row>

  </ion-grid>
  <ion-card>
    <ion-card-header>
        <ion-card-subtitle>
      <span *ngIf="menuItem.code">{{menuItem.code}}. </span>{{menuItem.name}}
      <span class="ion-float-right fdl-price-txt">{{itemPrice| currency:'USD'}}</span>
        </ion-card-subtitle>
        <div>{{category.name}}</div>
    </ion-card-header>
    <ion-card-content>
        <ion-grid class="ion-no-padding" >
          <ion-row *ngIf="!(order)">
            <ion-col size="12">
                <ion-chip color="danger">No ongoing order. Start an Order first, and re-select the item.</ion-chip>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!(order)">
            <ion-col align-self-center size="12">
              <ion-button fill="outline" (click)="startAnOrder()"> <ion-icon name="add"></ion-icon>Start an Order</ion-button>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="(order) && (order?.orderType==='TABLE ORDER')">
            <ion-col size="12">
                <ion-chip color="danger">Table order in progress. Resume that order or start over that order</ion-chip>
            </ion-col>
          </ion-row>

            <ion-row class="ion-no-padding" *ngIf="menuItem.imageUrl">
              <ion-col class="ion-no-padding" offset-sm="3" size-sm="6" class="nopadding">
                <ion-img [src]="menuItem.imageUrl"></ion-img>
              </ion-col>
            </ion-row>

        <ion-row class="ion-no-padding" >
          <ion-col>{{menuItem.description}}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-badge *ngIf="category.viewOnly" color="secondary">View Only</ion-badge>
            <ion-badge *ngIf="menuItem.spicy" color="danger">Hot & Spicy</ion-badge><span *ngIf="menuItem.spicy">&nbsp;</span> 
            <ion-badge *ngIf="menuItem.vegan" color="success">Vegan</ion-badge><span *ngIf="menuItem.vegan">&nbsp;</span> 
            <ion-badge *ngIf="menuItem.popular" color="default">Popular</ion-badge><span *ngIf="menuItem.popular">&nbsp;</span> 
            <ion-badge *ngIf="menuItem.rawFish" color="danger">Raw Fish</ion-badge><span *ngIf="menuItem.rawFish">&nbsp;</span> 
            <ion-badge *ngIf="menuItem.healthy" color="success"><i class="fa fa-heart"></i>&nbsp;Healthy</ion-badge><span *ngIf="menuItem.healthy">&nbsp;</span> 
            <ion-badge *ngIf="menuItem.vegetarian" color="success">Vegetarian</ion-badge><span *ngIf="menuItem.vegetarian">&nbsp;</span> 
          </ion-col>
        </ion-row>
        </ion-grid> 
      <form [formGroup]="formGroup">
          <ion-grid>
              <ion-row>
                <ion-col size="12" size-sm="6" size-md="4" size-lg="3">
                    <ion-label stacked>Quantity</ion-label>
                    <ion-select class="fdl-width-sm"
                      placeholder="Select Qty"
                      formControlName="qty">
                      <ion-select-option
                       *ngFor="let v of [1,2,3,4,5,6,7,8,9,10,11,12]"
                       [value]="v"
                       >{{v}}</ion-select-option>
                    </ion-select>
                </ion-col>
                <ng-container *ngFor="let opt of category.options" >
                    <ion-col size="12" size-sm="6" size-md="4" size-lg="3"
                       *ngIf="opt.show && opt.showCatOption">
                      <ng-container *ngIf="opt.type=='single'">
                          <ion-label stacked>{{opt.name}}</ion-label>
                          <ion-select  placeholder="Select {{opt.name}}"
                          (ionChange)="onOptionChange($event)"
                            [formControlName]="opt.id"
                          >
                            <ion-select-option *ngFor="let resp of opt.responses; let i=index;"
                             [value]="resp">{{resp.text}}<span *ngIf="resp.extraCost">&nbsp;({{resp.extraCost|currency:'USD'}})</span></ion-select-option>
                          </ion-select> 
                      <ion-item class="no-padding" *ngIf="!formGroup.controls[opt.id].valid  && (formGroup.controls[opt.id].dirty || submitAttempt)">                          
                          <ion-chip color="danger">Please Select {{opt.name}}</ion-chip>
                      </ion-item>
                      </ng-container>
                      <ng-container *ngIf="opt.type=='multi'">
                          <ion-label stacked>{{opt.name}}<span *ngIf="!(opt.minSelections)"></span></ion-label>
                          <ion-select   placeholder="Optional {{opt.name}}" multiple="true"  class="ion-text-wrap"
                          (ionChange)="onOptionChange($event)"
                            [formControlName]="opt.id"
                          >
                            <ion-select-option *ngFor="let resp of opt.responses" 
                               [value]="resp">{{resp.text}}<span *ngIf="resp.extraCost">&nbsp;({{resp.extraCost|currency:'USD'}})</span>
                            </ion-select-option>
                          </ion-select> 
                        </ng-container>
                    </ion-col>
                  </ng-container>
                  <ng-container *ngFor="let opt of menuItem.options" >
                      <ion-col size="12" size-sm="6" size-md="4" size-lg="3"
                         *ngIf="opt.show">
                        <ng-container *ngIf="opt.type=='single'">
                            <ion-label stacked>{{opt.name}}</ion-label>
                            <ion-select  placeholder="Select {{opt.name}}"
                            (ionChange)="onOptionChange($event)"
                              [formControlName]="opt.id"
                            >
                              <ion-select-option *ngFor="let resp of opt.responses; let i=index;"
                               [value]="resp">{{resp.text}}<span *ngIf="resp.extraCost">&nbsp;({{resp.extraCost|currency:'USD'}})</span></ion-select-option>
                            </ion-select> 
                        <ion-item class="no-padding" *ngIf="!formGroup.controls[opt.id].valid  && (formGroup.controls[opt.id].dirty || submitAttempt)">                       
                            <ion-chip color="danger">Please Select {{opt.name}}</ion-chip>
                        </ion-item>
                        </ng-container>
                        <ng-container *ngIf="opt.type=='multi'">
                            <ion-label stacked>{{opt.name}}<span *ngIf="!(opt.minSelections)"> (Optional)</span></ion-label>
                            <ion-select  placeholder="Select {{opt.name}}" multiple="true" 
                            (ionChange)="onOptionChange($event)"
                              [formControlName]="opt.id"
                            >
                              <ion-select-option *ngFor="let resp of opt.responses"
                                 [value]="resp">{{resp.text}}<span *ngIf="resp.extraCost">&nbsp;({{resp.extraCost|currency:'USD'}})</span>
                              </ion-select-option>
                            </ion-select> 
                          </ng-container>
                      </ion-col>
                    </ng-container>
                    
                    <ion-col size="12" size-sm="6" size-md="4" size-lg="3">
                      <ion-label position="stacked">This item is for</ion-label>
                      <ion-input  type="text" placeholder="Optional - Person Name" formControlName="personName"></ion-input>
                    </ion-col>
                    
                    <ng-container *ngIf="!(restaurant.hideSplInst)">
                      <ion-col size="12" size-sm="6" size-md="4" size-lg="3">
                        <ion-label stacked>Special Request</ion-label>
                        <ion-textarea  placeholder="Optional No extra or substition requests please" formControlName="specialRequest">
                        </ion-textarea>
                      </ion-col>
                  </ng-container>
               </ion-row>
            </ion-grid>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
