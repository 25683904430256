import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TableOrderPage } from './table-order.page';
import { TableOrderStep1Component } from '../table-order-step1/table-order-step1.component';
import { TableOrderBatchComponent } from '../table-order-batch/table-order-batch.component';
import { TableOrderFinishComponent } from '../table-order-finish/table-order-finish.component';
import { LoginStatusModule } from '../../account/login-status/login-status.module';
import {RestaurantInfoModule} from '../../restaurant/restaurant-info/restaurant-info-module';

const routes: Routes = [
  {
    path: '',
    component: TableOrderPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    LoginStatusModule,
    RestaurantInfoModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TableOrderPage,
    TableOrderStep1Component,
    TableOrderBatchComponent,
    TableOrderFinishComponent
  ],
  entryComponents:[
    TableOrderStep1Component,
    TableOrderBatchComponent,
    TableOrderFinishComponent
  ]
})
export class TableOrderPageModule {}
