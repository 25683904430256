import { Injectable } from '@angular/core';
import * as Moment from 'moment';
import { Storage } from "@capacitor/storage"

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { 
    Storage.migrate();
  }
  public set(key: string, value: any): Promise<void> {
    if (value == null) {
      return this.remove(key);
    }
    const o: any = {};
    o.key = key;
    if (typeof(value) === 'object' ) {
      o.value = JSON.stringify(value);
    } else {
      o.value = value;
    }
    return  Storage.set(o);
  }
  public set2(key: string, value: any, timeToLive: string): Promise<void> {
    if (value == null) {
      return this.remove(key);
    }
    const expires = Moment().add(Moment.duration(timeToLive)).toDate();
    return this.set(key, {value, expires});
  }

  public get(key: string): Promise<object>  {
    return new Promise<object>((resolve, reject) => {
      const o: any = {};
      o.key = key;
      Storage.get(o).then((v) => {
        let retVal = null;
        if ( (v) && (v.value)) {
          try {
            retVal = JSON.parse(v.value);
          } catch (e) {
            retVal = v.value;
          }
        }
        resolve(retVal);
      }).catch((reason) => {
        reject(reason);
      });
    });
  }
  public get2(key: string): Promise<object>  {
    return new Promise<object>((resolve, reject) => {
      let retVal = null;
      const o: any = {};
      o.key = key;
      this.get(key).then( (v: any) => {
        if (v){
          if (Moment().isBefore(Moment(v.expires))){
            retVal = v.value;
          }
        }
        resolve(retVal);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public remove(key: string): Promise<void> {
    const o: any = {};
    o.key = key;
    return Storage.remove(o);
  }
}
