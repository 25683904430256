import { Component, OnInit, OnDestroy } from '@angular/core';
import { Menu, Category, MenuItem, Option } from '../../menu/menu.model';
import { ModalController, NavParams, ToastController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Safe } from '../../../core/safe';
import { CartItem, ItemOption, OptionResponse } from '../../cart/cart.model';
import { OrderService } from '../../order/order.service';
import { UIHelpers } from 'src/app/core/uihelpers';
import { StartAnOrderPage } from '../../order/start-an-order/start-an-order.page';
import { OverlayEventDetail } from '@ionic/core';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { MinMaxSelValidator } from 'src/app/core/min-max-sel.validator';
import { TableOrder, TableOrderBatch, TableOrderPerson } from '../table-order.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-order-menu-item',
  templateUrl: './table-order-menu-item.page.html',
  styleUrls: ['./table-order-menu-item.page.scss'],
})
export class TableOrderMenuItemPage implements OnInit {
  menu: Menu;
  category: Category;
  menuItem: MenuItem;
  menuIndex: number;
  catIndex: number;
  itemPrice: number;
  data: any;
  formGroup: FormGroup;
  ctrls: any;
  submitAttempt: boolean;
  catOptionSelected: any;
  optionSelected: any;
  order: TableOrder;
  restaurant: Restaurant;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private uiHelpers: UIHelpers,
    private toastCtrl: ToastController,
    private restaurantService: RestaurantService,
    public navCtrl: NavController,
    public router: Router
  ) { }

  ngOnInit() {

    this.restaurantService.getRestaurant().subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;
        this.orderService.getOrder().subscribe({
          next: (order) => {
            if ((order) && (order.orderType === 'TABLE ORDER')) {
              this.order = order as TableOrder;
            }
            this.buildCtrls();
          }
        });

      }
    });
  }
  buildCtrls() {

    this.menu = this.navParams.get('menu');
    this.category = this.navParams.get('category');
    this.menuItem = this.navParams.get('menuItem');
    this.menuIndex = this.navParams.get('menuIndex');
    this.catIndex = this.navParams.get('catIndex');
    this.itemPrice = this.menuItem.price;
    this.data = {};
    this.data.qty = 1;

    this.catOptionSelected = this.initOptionSelected(this.category.options);
    this.optionSelected = this.initOptionSelected(this.menuItem.options);

    this.processForDepOptions(this.category.options, this.catOptionSelected);
    this.processForDepOptions(this.menuItem.options, this.optionSelected);
    this.processCatOptions(this.category.options);


    this.ctrls = {};
    this.ctrls.qty = [1, Validators.required];
    this.buildOptCtrls(this.category.options);
    this.buildOptCtrls(this.menuItem.options);
    this.ctrls.personName = [this.order.people[this.order.activePersonId]];
    this.ctrls.specialRequest = [''];
    this.submitAttempt = false;
    this.formGroup = this.formBuilder.group(this.ctrls);
  }
  cancelModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.router.navigateByUrl('/table-order');
    this.modalController.dismiss({
      continue: false
    });
  }
  cancel() {
    this.modalController.dismiss({
    continue: false
  });
}

  private buildOptCtrls(options) {
    if (options) {
      for (const opt of options) {
        if (((typeof opt.showCatOption === 'undefined') || (opt.showCatOption))) {
          if (opt.type === 'single') {
            if (opt.show) {
              this.ctrls[opt.id] = ['', Validators.required];
            } else {
              this.ctrls[opt.id] = [''];
            }
          } else if (opt.type === 'multi') {
            this.ctrls[opt.id] =
              [null, (new MinMaxSelValidator(opt.minSelections, opt.maxSelections)).isValid];

          }
          for (const resp of opt.responses) {
            if (resp.selected) {
              this.ctrls[opt.id][0] = resp;
            }
          }
        }
      }
    }
  }
  private triggerDependentOption(options, i, selVal) {
    const opt = options[i];
    if (!(Safe.isEmpty(opt.depedentTriggerValue))) {
      // var selVal=$scope.optionSelected[i];
      const nextOpt = options[i + 1];
      if (opt.depedentTriggerValue.indexOf('!') === 0) {
        nextOpt.show = (selVal !== opt.depedentTriggerValue.slice(1));
      } else if (opt.depedentTriggerValue.indexOf('#') === 0) {
        nextOpt.show = (selVal.indexOf(opt.depedentTriggerValue.slice(1)) >= 0);
      } else {
        nextOpt.show = (selVal === opt.depedentTriggerValue);
      }
      const nextCtrl = this.formGroup.controls[nextOpt.id];
      if (nextOpt.show) {
        nextCtrl.setValidators([Validators.required]);
      } else {
        nextCtrl.clearValidators();
        nextCtrl.reset();
      }
    }
  }

  private updatePirceAndTriggerDependentOption(options: any[], form: any) {
    if (options) {
      for (let i = 0; i < options.length; ++i) {
        const opt = options[i];
        if (typeof (form[opt.id]) !== 'undefined') {
          if (opt.type === 'multi') {
            if (form[opt.id] != null) {
              for (const resp of form[opt.id]) {
                this.itemPrice += resp.extraCost;
              }
            }

          } else {
            const resp = form[opt.id];
            if (resp) {
              if (resp.extraCost) {
                this.itemPrice += resp.extraCost;
              }
              this.triggerDependentOption(options, i, resp.text);
            }
          }
        }

      }
    }
  }
  onOptionChange($event) {
    this.itemPrice = this.menuItem.price;
    const form = this.formGroup.value;
    this.updatePirceAndTriggerDependentOption(this.category.options, form);
    this.updatePirceAndTriggerDependentOption(this.menuItem.options, form);
  }
  private initOptionSelected(options) {
    const optionSelected = [];
    if (options) {
      for (let i = 0; i < options.length; ++i) {
        const opt = options[i];
        for (const resp of opt.responses) {
          if (resp.selected) {
            optionSelected[i] = resp.text;
          }
        }
      }
    }
    return optionSelected;
  }

  private processForDepOptions(options, selValArray) {
    if (options) {
      for (let i = 0; i < options.length; ++i) {
        const opt = options[i];
        opt.show = true;
        // this.triggerDependentOption(options, i, selValArray[0]);
        if (!(Safe.isEmpty(opt.depedentTriggerValue))) {
          ++i;
          if (opt.depedentTriggerValue.indexOf('!') === 0) {
            options[i].show = (selValArray[0] !== opt.depedentTriggerValue.slice(1));
          } else if (opt.depedentTriggerValue.indexOf('#') === 0) {
            options[i].show = (selValArray[0].indexOf(opt.depedentTriggerValue.slice(1)) >= 0);
          } else {
            options[i].show = (selValArray[0] === opt.depedentTriggerValue);
          }

        }
      }
    }
  }
  private showCatOption(opt) {
    let result = true;
    if (!Safe.isEmpty(this.menuItem.includedCatOptions)) {
      result = (this.menuItem.includedCatOptions.indexOf(opt.name) >= 0);
    } else if (!Safe.isEmpty(this.menuItem.excludedCatOptions)) {
      result = (!(this.menuItem.excludedCatOptions.indexOf(opt.name) >= 0));
    }
    return result;
  }

  private processCatOptions(options) {
    if (options) {
      for (const opt of options) {
        opt.showCatOption = this.showCatOption(opt);
      }
    }
  }
  private buildCartItemOptions(itemOptions: ItemOption[], options: ItemOption[], form: FormGroup) {
    if (options) {
      for (const opt of options) {
        if (typeof (form[opt.id]) !== 'undefined') {
          const itemOption: ItemOption = new ItemOption();
          itemOption.id = opt.id;
          itemOption.name = opt.name;
          itemOption.type = opt.type;
          itemOption.responses = [];
          if (opt.type === 'multi') {
            if (form[opt.id] !== null) {
              for (const resp of form[opt.id]) {
                const optResp: OptionResponse = new OptionResponse();
                optResp.extraCost = resp.extraCost;
                optResp.selected = true;
                optResp.text = resp.text;
                itemOption.responses.push(optResp);
              }
            }

          } else {
            const resp = form[opt.id];
            if (resp) {
              const optResp: OptionResponse = new OptionResponse();
              optResp.extraCost = resp.extraCost;
              optResp.selected = true;
              optResp.text = resp.text;
              itemOption.responses.push(optResp);
            }
          }
          itemOptions.push(itemOption);

        }

      }
    }

  }

  addToOrder() {
    try {

      this.submitAttempt = true;
      if (this.formGroup.valid) {
        const form = this.formGroup.value;
        const item: CartItem = new CartItem();
        item.code = this.menuItem.code;
        item.name = this.menuItem.name;
        item.personName = form.personName;
        item.notes = form.specialRequest;
        item.price = this.menuItem.price;
        item.qty = form.qty;
        item.menuName = this.menu.name;
        item.catName = this.category.name;
        item.sortOrder = this.menuIndex * 100 + this.catIndex;
        item.remind = false;
        item.options = [];
        this.buildCartItemOptions(item.options, this.category.options, form);
        this.buildCartItemOptions(item.options, this.menuItem.options, form);
        let b = this.order.batches[this.order.state - 2];
        if (!b) {
          b = new TableOrderBatch();
          b.batchId = this.order.state - 2;
          this.order.batches[b.batchId] = b;
          b.batchName = TableOrder.batchNames[b.batchId];
        }
        if (!b.people) {
          b.people = [];
        }
        let p = b.people[this.order.activePersonId];
        if (!p) {
          p = new TableOrderPerson();
          p.personId = this.order.activePersonId;
          p.personName = this.order.people[this.order.activePersonId];
          b.people[p.personId] = p;
        }
        if (!p.items) {
          p.items = [];
        }
        p.items.push(item);
        this.orderService.updateOrder(this.order).then(() => {
          this.orderService.addToCart(item);
          this.cancelModal();
        });

      }
    } catch (msg) {
      this.uiHelpers.showErr('Add to cart failed. ' + msg);
    }

  }
  startAnOrder() {
    this.presentStartOrderModal();
  }
  async presentStartOrderModal() {
    const modal = await this.modalController.create({
      component: StartAnOrderPage,
      componentProps: {
      }
    });
    // modal.onDidDismiss().then( (detail: OverlayEventDetail) => {
    //   if ( (detail) && (detail.data) &&
    //     (detail.data.continue) ) {
    //   }
    // });
    modal.present();
  }
  ngOnDestroy(): void {
  }

}
