import { ErrorHandler, Injectable } from '@angular/core';
import { GrayLogService } from '../graylog.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class GrayLogErrorHandler implements ErrorHandler {
  constructor(
    private grayLogService: GrayLogService
  ) {}
  handleError(error) {
    const message = (error.message) ? error.message : error.toString();
    const stack = (error.stack) ? error.stack : '';
    if (environment.logToGralogServer) {
      this.grayLogService.logError('jserror',message, stack);
    } else {
      throw error;
    }
  }
}
