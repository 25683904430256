export const environment = {
  production: true,
  logToGralogServer: false,
  graylogUrl: 'https://log.w3cloud.com/gelf',
  baseUrl: 'https://test.foodalot.com/',
  name: 'Test',
  googleMerchantId: '742386873196341156',
  customApp: false,
  restaurantFilter: '',
  showTest787:false,
  stripe:{
    publishableKey:'pk_test_BKfpaASwlTraodCGHjZ2lSBT'
  }
};

