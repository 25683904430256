<ion-header>
  <ion-toolbar>
    <ion-title>OTP</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()">Confirm</ion-button>
    </ion-buttons>


  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="formGroup">
  <ion-grid>
    <ion-row>
      <ion-col>
        <p>We sent a 6 digit one-time password (OTP) to {{mobileNo}}. Please enter it here.</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col offset-xs="2" size-xs="8" offset-md="4" size-md="4">
       
        <ion-item>
          <ion-label position="floating">OTP</ion-label>
          <ion-input  formControlName="otp" type="tel"
          auto-complete="one-time-code"
          [brmasker]="{mask:'000000'}" (ionChange)="otpFieldChange()"
        
          >
          </ion-input>
        </ion-item>
  
 
     
      </ion-col>
    
    </ion-row>
  </ion-grid>
</form>
</ion-content>
