import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { Order } from "../../order.model";
import { Platform } from "@ionic/angular";

declare var google;

@Component({
  selector: "app-delivery",
  templateUrl: "./delivery.component.html",
  styleUrls: ["./delivery.component.scss"],
})
export class DeliveryComponent implements OnInit {
  googleAutocomplete: any;
  restaurantPoint: any;
  distance: string;

  @ViewChild("autocomplete", { read: ElementRef })
  autocompleteInput: ElementRef;

  constructor(public platform: Platform) {
   this.restaurantPoint = new google.maps.LatLng( 39.27085, -84.37416 );
  this.distance = '';
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.autocompleteInput.nativeElement.getInputElement().then((el) => {
        this.googleAutocomplete = new google.maps.places.Autocomplete(el, {
          types: ["geocode"],
        });
        this.googleAutocomplete.setFields(["geometry"]);
        this.googleAutocomplete.addListener("place_changed", () => {
          var place = this.googleAutocomplete.getPlace();
          this.calcDistance(this.restaurantPoint, place.geometry.location);
        });
      });
    });
  }

//location1 and location2 are google.maps.LatLng(x,y) objects
calcDistance(location1, location2) {
  // computeDistanceBetween is available only with libraries=geometry in the google maps api in the index.html
  var distance_in_meters = (google.maps.geometry.spherical.computeDistanceBetween(location1, location2));
  var distance_in_miles = distance_in_meters * 0.000621371; // convert meters to miles  
}

haversine_distance(location1, location2) {
  this.distance = '';
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = location1.lat() * (Math.PI/180); // Convert degrees to radians
  var rlat2 = location2.lat() * (Math.PI/180); // Convert degrees to radians
  var difflat = rlat2-rlat1; // Radian difference (latitudes)
  var difflon = (location2.lng()-location1.lng()) * (Math.PI/180); // Radian difference (longitudes)

  var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
  this.distance = d.toString();
  return d;
}

  canStart(): boolean {
    return true;
  }

  initOrder(): Order {
    const order: Order = new Order();
    order.orderType = "DELIVERY";

    return order;
  }
}
