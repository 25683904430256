<ion-content #popoverContent class="popover-page" scrollEvents="true" scrollY="true">
  <ion-toolbar color="secondary">
    <ion-item color="secondary">
      <p>Jump to Category</p>
    </ion-item>
    </ion-toolbar>
  <ion-list>
<ion-content scroll-events="true" scroll-y="true">
    <ng-container *ngFor="let category of cat; let i = index">
    <ion-item (click)="selectCategory(i)"
      detail
    ><ion-label>{{ category.name }}</ion-label>
    </ion-item>
  </ng-container>    
</ion-content>
  </ion-list>

</ion-content>
