<ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-back-button></ion-back-button>
      </ion-buttons>
      <app-logo></app-logo>
    </ion-toolbar>
  </ion-header>
  
<ion-content>
  <ion-list >
    <ion-item style="margin-top:10px;" lines="none" *ngIf="environment.customApp">
  <app-fdl-logo ></app-fdl-logo>
  </ion-item>
    <ion-list-header>Account</ion-list-header>
  </ion-list>
  <ion-list *ngIf="!user">
  <ion-item lines="full">
    <ion-icon name="person" slot="start"></ion-icon>
    <ion-label>
      Guest
    </ion-label>
  </ion-item>
  <ion-item  (click)="login()" detail>
    <ion-label  class="ion-text-wrap">
      <h1>Login</h1>
      <div>Already have an account? Login.</div>
    </ion-label>
  </ion-item>
  <ion-item  (click)="signup()" detail>
    <ion-label class="ion-text-wrap">
      <h1>Signup</h1>
      <div>New to FoodAlot? Signup. It is easy.</div>
    </ion-label>
  </ion-item>
  <ion-item  routerLink="/resetpwd" detail>
    <ion-label>
      <h1>Reset Password</h1>
      <div>Don't remember your password? Reset.</div>
    </ion-label>
  </ion-item>
  <ion-item  detail routerLink="/my-orders">
    <ion-label><h1>Order History</h1><div>See your past orders</div></ion-label>
  </ion-item>
  </ion-list>
  <ion-list *ngIf="user">
    <ion-item lines="full">
      <ion-icon name="person" slot="start"></ion-icon>
      <ion-label>
        {{user.firstName}} {{user.lastName}}
      </ion-label>
    </ion-item>
  <ion-item>
    <ion-icon name="mail" slot="start"></ion-icon>
    <ion-label>{{user.emailId}}</ion-label>
  </ion-item>
  <ion-item (click)="logout()" detail>
    <ion-icon name="log-out" slot="start"></ion-icon>
    <ion-label>Logout</ion-label>
  </ion-item>
  <ion-item  detail routerLink="/my-orders">
    <ion-icon name="archive-outline" slot="start"></ion-icon>
    <ion-label>Order History</ion-label>
  </ion-item>
  <ion-item-divider mode="ios">
    <ion-label color="danger" mode="ios">Danger Zone</ion-label>
  </ion-item-divider>
  <ion-item>
    <ion-button color="danger"  (click)="presentAlertConfirm();"><ion-icon name="close-circle-outline" slot="start"></ion-icon> Delete Account</ion-button>
  </ion-item>

</ion-list>


</ion-content>