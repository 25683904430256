import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { LoginStatusComponent } from "./login-status.component";

@NgModule({
    declarations: [
        LoginStatusComponent
    ],
    imports: [
      CommonModule,
      IonicModule.forRoot()
    ],
    exports: [
        LoginStatusComponent

    ]
})
export class LoginStatusModule { }