import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RewardAccount } from '../reward.model';
import { GetBrightnessReturnValue, ScreenBrightness } from '@capacitor-community/screen-brightness';


@Component({
  selector: 'app-show-qr',
  templateUrl: './show-qr.component.html',
  styleUrls: ['./show-qr.component.scss'],
})
export class ShowQRComponent implements OnInit {
  rewardAccount: RewardAccount;
  prevBrightness: GetBrightnessReturnValue;


  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    ScreenBrightness.getBrightness().then((value:GetBrightnessReturnValue)=>{
      this.prevBrightness=value;
      ScreenBrightness.setBrightness({brightness:1.0});
    });

  }

  close(){
    if (this.prevBrightness){
      ScreenBrightness.setBrightness(this.prevBrightness).finally(()=>{
        this.modalController.dismiss();
      });
    }else{
      this.modalController.dismiss();
    }
  }

}
