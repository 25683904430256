import { Component, OnInit, Input } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-invalid-messages',
  templateUrl: './invalid-messages.component.html',
  styleUrls: ['./invalid-messages.component.scss'],
})
export class InvalidMessagesComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() validationMessages: [{type: string, message: string}];
  constructor() { }
  ngOnInit() {
  }

}
