import {
  Component,
  OnInit,
  AfterContentInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { Restaurant } from '../../restaurant/restaurant.model';
import { OrderService } from '../order.service';
import { Subscribable, Subscription } from 'rxjs';
import { Order } from '../order.model';
import * as Moment from 'moment';
import { ModalController } from '@ionic/angular';
import { CarryoutComponent } from './carryout/carryout.component';
import { OrderTypeInterface } from './order-type.interface';
import { NoWaitDineInComponent } from './no-wait-dine-in/no-wait-dine-in.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { UIHelpers } from 'src/app/core/uihelpers';
import { CurbsidePickupComponent } from './curbside-pickup/curbside-pickup.component';
import { DriveThruPickupComponent } from './drive-thru-pickup/drive-thru-pickup.component';
import {GrayLogService} from '../../../core/graylog.service'

@Component({
  selector: 'app-start-an-order',
  templateUrl: './start-an-order.page.html',
  styleUrls: ['./start-an-order.page.scss'],
})
export class StartAnOrderPage implements OnInit, AfterContentInit, OnDestroy {
  restaurant: Restaurant;
  order: Order;
  orderType: string;
  public orderDateList: any;
  public orderSlots: any;
  private subscriptions: Subscription[] = [];
  public orderTypeInterface: OrderTypeInterface;
  @ViewChild('carryoutComp')
  carryoutComp: CarryoutComponent;
  @ViewChild('dineInComp')
  dineInComp: NoWaitDineInComponent;
  @ViewChild('deliveryComp')
  deliveryComp: DeliveryComponent;
  @ViewChild('curbsidePickupComp')
  curbsidePickupComp: CurbsidePickupComponent;
  @ViewChild('driveThruPickupComp')
  driveThruPickupComp: DriveThruPickupComponent;
  constructor(
    private modalController: ModalController,
    private restaurantService: RestaurantService,
    private orderService: OrderService,
    private uiHelpers: UIHelpers,
    private grayLogService: GrayLogService
  ) {
    this.orderType = 'CARRYOUT';
    this.orderTypeInterface = this.carryoutComp;
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }
  loadRestaurant() {
    this.subscriptions.push(
      this.restaurantService.getRestaurant().subscribe({
        next: (restaurant: Restaurant) => {
          this.restaurant = restaurant;
        },
        error: (err) => {
          this.uiHelpers.showErr('Error in loadRestaurant' + err);
        },
      })
    );
  }

  loadOrder() {
    this.subscriptions.push(
      this.orderService.getOrder().subscribe({
        next: (order) => {
          this.order = order;
        },
        error: (err) => {
          this.uiHelpers.showErr("Error in loadOrder" + err);
        },
      })
    );
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.orderTypeInterface = this.carryoutComp;
    }, 0);
    this.loadRestaurant();
    this.loadOrder();
  }
  orderTypeChange($event) {
    this.orderType = $event.detail.value;
    setTimeout(() => {
      if (this.orderType === 'CARRYOUT') {
        this.orderTypeInterface = this.carryoutComp;
      } else if (this.orderType === 'NO WAIT DINE-IN') {
        this.orderTypeInterface = this.dineInComp;
      } else if (this.orderType === 'DELIVERY') {
        this.orderTypeInterface = this.deliveryComp;
      } else if (this.orderType === 'CURBSIDE PICKUP') {
        this.orderTypeInterface = this.curbsidePickupComp;
      } else if (this.orderType === 'DRIVE-THRU PICKUP') {
        this.orderTypeInterface = this.driveThruPickupComp;
      }
    }, 0);
  }

  async cancelModal() {
    await this.modalController.dismiss();
  }

  orderTimeChange() {}
  start() {
    const order: Order = this.orderTypeInterface.initOrder();
    this.orderService.startNewOrder(order);
    this.modalController.dismiss({
      continue: true,
    });
  }
  get timeSlotAvailable(): boolean {
    let available = true;
    if (this.orderSlots) {
      available = this.orderSlots.length > 0;
    }
    return available;
  }
}
