import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { OrderService } from '../../order/order.service';
import { Order } from '../../order/order.model';
import { AnimationController, Animation } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-restaurant-landing-page',
  templateUrl: './restaurant-landing-page.page.html',
  styleUrls: ['./restaurant-landing-page.page.scss'],
})
export class RestaurantLandingPagePage implements OnDestroy, AfterViewInit {
  restaurant: Restaurant;
  order: Order;
  private fetchRestaurantSubs: Subscription;
  private paramMapSubs: Subscription;
  private prevItemCt: Number;
  private animation: Animation;
  customApp:boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private restaurantService: RestaurantService,
    private orderService: OrderService,
    private animationCtrl: AnimationController
  ) {
    this.prevItemCt=0;
    this.customApp=environment.customApp;
  }
  ngAfterViewInit(): void {
    this.initSubs();

    this.animation=this.animationCtrl.create()
    .addElement(document.querySelector('.animate-cart'))
    .duration(750)
    .iterations(4)
    .fromTo('opacity', '0.2', '1');
  }

  initSubs() {
    this.paramMapSubs = this.activatedRoute.paramMap.subscribe(
      {
        next: (paramMap) => {
          const uri = paramMap.get('uri');
          this.fetchRestaurantSubs = this.restaurantService.fetchRestaurant(uri)
            .subscribe({
              next: (restaurant) => {
                this.restaurant = restaurant;
                this.orderService.getOrder().subscribe({
                  next: (order: Order) => {
                    this.order = order;
                    let itemCt=this.itemCt;
                    if (itemCt != this.prevItemCt) {
                        this.animation.play();
                      this.prevItemCt=itemCt;
                     }
                  }
                });
              }
            });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.fetchRestaurantSubs) {
      this.fetchRestaurantSubs.unsubscribe();
    }
    if (this.paramMapSubs) {
      this.paramMapSubs.unsubscribe();
    }
  }
  get itemCt(): number {
    let itemCt = 0;
    if (this.order?.cart?.items) {
      itemCt = this.order?.cart?.items.length;
    }
    return itemCt;
  }


}
