import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { NoWaitDineInComponent } from './no-wait-dine-in.component';
import { InvalidMessagesModule } from 'src/app/core/invalid-messages/invalid-messages.module';

@NgModule({
    declarations: [
      NoWaitDineInComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      InvalidMessagesModule,
      IonicModule.forRoot()
    ],
    exports: [
        NoWaitDineInComponent
    ]
})

export class NoWaitDineInModule { }
