import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../core/storage.service';
import { Settings } from './settings.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  darkMode: boolean;
  settings: Settings=null;
  static SETTINGS_KEY='foodalot:settings';

  constructor(
    private storageService: StorageService
  ) { 
  }

  ngOnInit() {
    this.darkMode=document.body.classList.contains('orange-theme-dark');
    this.storageService.get(SettingsPage.SETTINGS_KEY).then((settings: any)=>{
      if (settings){
        this.darkMode=settings.darkMode;
        this.settings=settings;
      }
    });
   
  }
  setDarkMode() {
    if (this.darkMode){
      document.body.classList.remove('orange-theme');
      document.body.classList.add('orange-theme-dark');
    }else{
      document.body.classList.remove('orange-theme-dark');
      document.body.classList.add('orange-theme');
    }
    if (!this.settings){
      this.settings={};
    }
    this.settings.darkMode=this.darkMode;
    this.storageService.set(SettingsPage.SETTINGS_KEY, this.settings);
  }


}
