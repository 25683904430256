import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Menu } from './menu.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private baseUrl: string;
  private activeMenus: BehaviorSubject<Menu>;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
    this.activeMenus = new BehaviorSubject<Menu>(null);
  }
  public reset(){
    this.activeMenus.next(null);
  }
  getActiveMenus(restaurantUri: string): Observable<Menu> {

    if ( this.activeMenus.value === null ) {
        const param: any = {};
        param.restaurantUri = restaurantUri;
        this.http.post(this.baseUrl + 'menu/fetchactive', param)
          .subscribe({
            next: (menus: any) => {
              for ( const menu of menus) {
                menu.categories = menu.published;
                delete menu.published;
                const availableHours = menu.availableHours.json;
                delete menu.availableHours;
                menu.availableHours = availableHours;
              }
              this.activeMenus.next(menus);
            }
          });
    }
    return this.activeMenus;
  }
}
