import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingPagePage } from './features/core/landing-page/landing-page.page';
import { RestaurantLandingPagePage } from './features/core/restaurant-landing-page/restaurant-landing-page.page';
import { AuthGuardService } from './core/auth-guard.service';
import { AccountTabPage } from './features/account/account-tab/account-tab.page';

const routes: Routes = [
{
    path: '',
    redirectTo: 'landing-page/app-home-tab',
    pathMatch: 'full'
  },
  { path: 'restaurant-list', loadChildren: () => import('./features/restaurant/restaurant-list/restaurant-list.module').then(m => m.RestaurantListPageModule) },
  /* This code is without tabs
  {
     path: 'restaurant/:uri',
      loadChildren: './features/restaurant/restaurant-detail/restaurant-detail.module#RestaurantDetailPageModule'
  },
  */
 {
  path: 'restaurant/:uri',
  component: RestaurantLandingPagePage,
  children: [
    {
      path: 'startorder',
      loadChildren: () => import('./features/restaurant/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule)
      },
      {
    path: 'home',
    loadChildren: () => import('./features/restaurant/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule)
    },
    {
       path: 'menu',
       loadChildren: () => import('./features/menu/menu/menu.module').then(m => m.MenuPageModule)
    },
    { path: 'cart',
     loadChildren: () => import('./features/cart/cart.module').then(m => m.CartPageModule) },
     { path: 'my-orders', loadChildren: () => import('./features/order/my-orders/my-orders.module').then(m => m.MyOrdersPageModule) },
     { path: 'more-tab', loadChildren: () => import('./features/core/more-tab/more-tab.module').then(m => m.MoreTabPageModule) },
     {
      path: 'reward',
      loadChildren: () => import('./features/reward/reward/reward.module').then( m => m.RewardPageModule)
    }   



  ]
},

  { path: 'landing-page',
    component: LandingPagePage,
    children: [
      {
        path: 'app-home-tab',
        loadChildren: () => import('./features/home/home-tab/home-tab.module').then(m => m.HomeTabPageModule)
      },
      { path: 'restaurant-list', loadChildren: () => import('./features/restaurant/restaurant-list/restaurant-list.module').then(m => m.RestaurantListPageModule)},
      { path: 'my-orders', loadChildren: () => import('./features/order/my-orders/my-orders.module').then(m => m.MyOrdersPageModule) },
      { path: 'account-tab', loadChildren: () => import('./features/account/account-tab/account-tab.module').then(m => m.AccountTabPageModule) },
      { path: 'more-tab', loadChildren: () => import('./features/core/more-tab/more-tab.module').then(m => m.MoreTabPageModule) }
    ]
  },
  { path: 'my-orders', loadChildren: () => import('./features/order/my-orders/my-orders.module').then(m => m.MyOrdersPageModule) }
  ,

  { path: 'home-tab', loadChildren: () => import('./features/home/home-tab/home-tab.module').then(m => m.HomeTabPageModule) },
  { path: 'start-an-order', loadChildren: () => import('./features/order/start-an-order/start-an-order.module').then(m => m.StartAnOrderPageModule) },
  { path: 'restaurant/:uri/menu-item', loadChildren: () => import('./features/menu/menu-item/menu-item.module').then(m => m.MenuItemPageModule) },
  { path: 'login',
    loadChildren: () => import('./features/account/login/login.module').then(m => m.LoginPageModule)
  },
  { path: 'signup', loadChildren: () => import('./features/account/signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'resetpwd', loadChildren: () => import('./features/account/resetpwd/resetpwd.module').then(m => m.ResetpwdPageModule) },
  {
     path: 'restaurant/:uri/order',
    loadChildren: () => import('./features/order/order/order.module').then(m => m.OrderPageModule)
  },
  {
    
    path: 'checkout/get-contact-info',
   loadChildren: () => import('./features/checkout/get-contact-info/get-contact-info.module').then(m => m.GetContactInfoPageModule)
 },
  {
    path: 'activateaccount',
    loadChildren: () => import('./features/account/activateaccount/activateaccount.module').then(m => m.ActivateaccountPageModule)
  },
  { path: 'table-order', loadChildren: () => import('./features/order-from-table/table-order/table-order.module').then(m => m.TableOrderPageModule) },
  { path: 'table-order-menu',
    loadChildren: () => import('./features/order-from-table/table-order-menu/table-order-menu.module').then(m => m.TableOrderMenuPageModule) },
  { path: 'table-order-menu-item',
    loadChildren: () => import('./features/order-from-table/table-order-menu-item/table-order-menu-item.module').then(m => m.TableOrderMenuItemPageModule) },
  { path: 'review-and-pay', loadChildren: () => import('./features/order-from-table/review-and-pay/review-and-pay.module').then(m => m.ReviewAndPayPageModule) },
  {
    path: 'test-order-date-time',
    loadChildren: () => import('./tests/order/test-order-date-time/test-order-date-time.module').then(m => m.TestOrderDateTimePageModule)
   },
  { path: 'checkin', loadChildren: () => import('./features/order/checkin/checkin.module').then(m => m.CheckinPageModule) },
  {
    path: 'get-contact-info',
    loadChildren: () => import('./features/checkout/get-contact-info/get-contact-info.module').then( m => m.GetContactInfoPageModule)
  },
  {
    path: 'checkout/stripe-card-submit',
    loadChildren: () => import('./features/checkout/stripe-card-submit/stripe-card-submit.module').then( m => m.StripeCardSubmitPageModule)
  },
  {
    path: 'tests/home',
    loadChildren: () => import('./tests/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'confirm-order',
    loadChildren: () => import('./features/checkout/confirm-order/confirm-order.module').then( m => m.ConfirmOrderPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./features/order/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'account',
    component: AccountTabPage
  },   {
    path: 'support',
    loadChildren: () => import('./features/core/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./features/core/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/core/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'scan-beacon',
    loadChildren: () => import('./features/order-from-table/scan-beacon/scan-beacon.module').then( m => m.ScanBeaconPageModule)
  },
  {
    path: 'intro-slides',
    loadChildren: () => import('./features/core/intro-slides/intro-slides.module').then( m => m.IntroSlidesPageModule)
  },
  {
    path: 'change-time',
    loadChildren: () => import('./features/cart/change-time/change-time.module').then( m => m.ChangeTimePageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./features/order/feedback/feedback.module').then( m => m.FeedbackPageModule)
  }
  ];
// { path: 'resetpwd', loadChildren: './features/resetpwd/resetpwd.module#ResetpwdPageModule' }


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true, onSameUrlNavigation:'reload'  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
{

}
