import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.page.html',
  styleUrls: ['./checkin.page.scss'],
})
export class CheckinPage implements OnInit {
  @Input()
  restaurant: any;
  @Input()
  order: any;
  formGroup: FormGroup;
  validationMessages: any;
  working: boolean;
  successMsg: string;
  errorMsg: string;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private orderService: OrderService
  ) {
    this.validationMessages = {
      locationInfo: [
        { type: 'required', message: 'Car and Location Info is requried.'},
        { type: 'maxLength', message: 'Shorten your info please. No more than 60 chars.'}
      ]};
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      locationInfo: new FormControl ('', Validators.compose([
        Validators.required,
        Validators.maxLength(60)
      ]))});
  }
  async cancelModal() {
    await this.modalController.dismiss();
  }
  checkin() {
    this.successMsg = null;
    this.errorMsg = null;
    if (this.formGroup.valid) {
      this.working = true;
      this.orderService.checkin(
        this.order.restaurant,
        this.order,
      this.formGroup.value.locationInfo
      ).subscribe({
        next: (data) => {
          if (data.code === 0) {
            this.successMsg = 'Restaurant notified. Closing in 3 secs ...';
            setTimeout(() => {
              this.modalController.dismiss({
                success: true
              });
            }, 3000);
          } else {
            this.errorMsg = 'Unable to checkin. Please call restaurant';
          }
        },
        error: () => {
          this.errorMsg = 'Unable to checkin. Please call restaurant';
        },
        complete: () => {
          this.working = false;
        }
      });

    }
  }

}
