import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
/**
 * SubscriptionMgr
 * Use this in all components to manage subscriptions.
 */
export class SubscriptionMgr {
    subscriptions: Subscription[] = [];
    /**
     * Call this functioy to identify the class of an object, eg: rest, cust, order
     * @param subscription
     */
    putSubscription(key: string,subscription: Subscription) {
        if (this.subscriptions[key]) {
            this.subscriptions[key].unsubscribe();
            this.subscriptions[key] = null;
        }

        this.subscriptions[key] = subscription;
    }
    /**
     * Call this method from onDescroy angular life cycle event.
     */
    unsuscribeAll() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }
}
