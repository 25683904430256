import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { StartAnOrderPage } from './start-an-order.page';
import { CarryoutModule } from './carryout/carryout.module';
import { NoWaitDineInModule } from './no-wait-dine-in/no-wait-dine-in.module';
import {DeliveryModule} from './delivery/delivery.module';
import { CurbsidePickupModule } from './curbside-pickup/curbside-pickup.module';
import { DriveThruPickupModule } from './drive-thru-pickup/drive-thru-pickup.module';

const routes: Routes = [
  {
    path: '',
    component: StartAnOrderPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CarryoutModule,
    DeliveryModule,
    CurbsidePickupModule,
    DriveThruPickupModule,
    NoWaitDineInModule,
    RouterModule.forChild(routes)
  ],
  declarations: [StartAnOrderPage]
})
export class StartAnOrderPageModule {}
