<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Rewards</ion-title>
    <ion-buttons slot="end" *ngIf="rewardAccount?.mobileNo">
      <ion-button fill="clear" (click)="openToolBarMenu()" ><ion-icon slot="icon-only" 
        ios="ellipsis-vertical-outline" md="ellipsis-vertical-sharp"
        
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
 
</ion-header>

<ion-content *ngIf="!rewardAccount?.mobileNo">
  
<app-join-reward padding usage="embeded"></app-join-reward>
</ion-content>

<ion-content  *ngIf="rewardAccount?.mobileNo">

  <ion-menu side="end" type="overlay" menuId="toolBarMenu" contentId="main">
    <ion-content>
      <ion-list>
        <ion-item><ion-button fill="clear" (click)="logout()">Logout</ion-button></ion-item>
        <ion-item-divider  mode="ios">
          <ion-label>
            Danger Zone
          </ion-label>
        </ion-item-divider>
    
        <ion-item><ion-button fill="clear" color="danger" (click)="presentAlertConfirm()">Delete</ion-button></ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item lines="fulls">
        <ion-avatar>
          <ion-icon name="person-circle-sharp" size="large"></ion-icon>
        </ion-avatar>
        <div>
          <ion-text>Hi, {{rewardAccount.fullName}}</ion-text>
          <br/><ion-text class="fdl-text-sm">Rewards Member</ion-text>
        </div>  
        <div slot="end">
        <ion-button color="secondary" (click)="showQR()">
          <ion-icon  slot="icon-only" name="qr-code-outline"
          ></ion-icon>
        </ion-button>
        <br/><ion-text class="fdl-text-sm">Show ID</ion-text>
      </div>
      </ion-item>
      </ion-col>
    </ion-row></ion-grid>
<app-reward-detail></app-reward-detail>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-content>
