import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { RegExPatterns } from '../../../core/regex-patterns';
import { UIHelpers } from '../../../core/uihelpers';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { ConfirmOtpComponent } from '../confirm-otp/confirm-otp.component';
import { RewardAccount } from '../reward.model';
import { RewardService } from '../reward.service';

@Component({
  selector: 'app-login-reward',
  templateUrl: './login-reward.component.html',
  styleUrls: ['./login-reward.component.scss'],
})
export class LoginRewardComponent implements OnInit  {

  subRestaurant: Subscription;
  restaurant: Restaurant;
  formGroup: FormGroup;
  validationMessages: any;
  rewardAccount: RewardAccount;

  constructor(
    private restaurantService: RestaurantService,
    private rewardService: RewardService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private uiHelpers: UIHelpers
    
  ) { }

  ngOnInit() {

    this.subRestaurant=this.restaurantService.getRestaurant().subscribe( (restaurant: Restaurant)=>{
      this.restaurant=restaurant;
    }

    );

    this.formGroup = this.formBuilder.group({

      mobileNo: [null, Validators.compose([
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(RegExPatterns.phoneNo)
        ])]

    });
    this.validationMessages = {
      mobileNo: [
        {type: 'required', message: 'Mobile No is required'},
        {type: 'maxlength', message: 'Mobile No is too long'},
        {type: 'pattern', message: 'Invalid Mobile No'}
      ]
    };
  }
  ngOnDestroy(): void {
    if (this.subRestaurant){
      this.subRestaurant.unsubscribe();
    }
  }
  confirmOtp(mobileNo){
    this.modalController.create({
      component: ConfirmOtpComponent,
      componentProps: {
        mobileNo
      }}).then((modal)=>{
        modal.present();
      });
  }
  sendOtp(){
    if (this.formGroup.valid){
      let json=this.formGroup.value;
    
      this.rewardService.sendOtp(json.mobileNo)
        .then(()=>{
          this.confirmOtp(json.mobileNo);
          this.modalController.dismiss();
        })
        .catch((data:any)=>{
          this.uiHelpers.showErr('Error. '+data.text)
        });

    }
    
  }
  cancel(){
    this.modalController.dismiss();
  }
  
}
