
<ion-content>
  <ion-toolbar>
    <ion-button size="large" (click)="close()" slot="end" fill="clear" >
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
          <ion-icon name="person-circle-sharp" size="large"></ion-icon>

      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text>Hi, {{rewardAccount.fullName}}</ion-text>
        <br/><ion-text class="fdl-text-sm">Rewards Member</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <qrcode [qrdata]="rewardAccount.mobileNo" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        Scan at the restaurant counter.
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        Earn points &unlock rewards
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>