import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { ToastController, NavController } from '@ionic/angular';
import { UIHelpers } from 'src/app/core/uihelpers';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})

export class SignupPage implements OnInit {
  signuppageForm: FormGroup;
  emailIdAlreadyExists: boolean;
  environment: any;

  error_messages = {
    emailId: [
      {type: 'required', message: 'Email is required.'},
      {type: 'minlength', message: 'Email length must be longer or equal than 6 characters.'},
      {type: 'maxlength', message: 'Email length must be lower or equal to 50 characters.'},
      {type: 'pattern', message: 'Please enter a valid email address.'}
    ],

    password: [
      {type: 'required', message: 'Password is required.'},
      {type: 'minlength', message: 'Password length must be longer or equal than 6 characters.'},
      {type: 'maxlength', message: 'Password length must be lower or equal to 30 characters.'},
      {type: 'maxlength', message: 'Password must contain numbners uppercase & lowercase characters.'}
    ],

    firstName: [
      {type: 'required', message: 'FirstName is required.'},
      {type: 'maxlength', message: 'First Name is  too long. Max 40 chars allowed'},
      {type: 'pattern', message: 'Only alpha numeric chars and spaces are allowed.'}
    ],

    lastName: [
       {type: 'required', message: 'LastName is required.'},
       {type: 'maxlength', message: 'Last Name is  too long. Max 40 chars allowed'},
       {type: 'pattern', message: 'Only alpha numeric chars and spaces are allowed.'}
    ]};

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private toastCtrl: ToastController,
    private uiHelpers: UIHelpers,
    private navCtrl: NavController
    ) {
      this.environment=environment;
        this.signuppageForm = this.formBuilder.group({
        password: new FormControl ('', Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(30),
          Validators.pattern(/^[a-zA-Z0-9]+$/)
        ])),

        emailId: new FormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
        ])),

        firstName: new FormControl('', Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z0-9\s]+$/)
        ])),

        lastName: new FormControl('', Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z0-9\s]+$/)
        ]))
        });
      }

  ngOnInit() {
  }

  signup() {
    if (this.signuppageForm.valid) {
      const json = this.signuppageForm.value;
      json.emailId = json.emailId.toLowerCase();
      this.userService.signup(json).then(() => {
        this.navCtrl.pop().then(() => {
          this.router.navigate(['activateaccount'], {
            state: {
              emailId: json.emailId
            }
          });
        });
    }).catch((err) => {
        this.uiHelpers.showErr('Signup error. ' + err);
       });
    }
  }

  emailIdChanged() {
    this.emailIdAlreadyExists = false;
    if (this.signuppageForm.controls.emailId.valid) {
      const emailId: string = this.signuppageForm.controls.emailId.value;
      this.userService.isEmailIdUnique(emailId).then((isUnique: boolean) => {
        if (!isUnique) {
          this.emailIdAlreadyExists = true;
        }
      }).catch((err) => {
        this.uiHelpers.showErrAutoClose('Unable to check email id for uniqueness');
         });
    }
  }
  login(){
    this.navCtrl.pop().then(() => {
      this.navCtrl.navigateForward('/login');
  
    });
  }
  resetPwd(){
    this.navCtrl.pop().then(() => {
      this.navCtrl.navigateForward('/resetpwd');
      });    

  }
}
