import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { InvalidMessagesModule } from 'src/app/core/invalid-messages/invalid-messages.module';
import { CurbsidePickupComponent } from './curbside-pickup.component';
import { OrderDateTimeModule } from '../../order-date-time/order-date-time.module';


@NgModule({
    declarations: [
        CurbsidePickupComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      OrderDateTimeModule,
      InvalidMessagesModule,
      IonicModule.forRoot()
    ],
    exports: [
        CurbsidePickupComponent
    ]
})

export class CurbsidePickupModule {}
