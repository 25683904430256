import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { LandingPagePageModule } from './features/core/landing-page/landing-page.module';

import { RestaurantLandingPagePageModule } from './features/core/restaurant-landing-page/restaurant-landing-page.module';
import { StartAnOrderPageModule } from './features/order/start-an-order/start-an-order.module';
import { MenuItemPageModule } from './features/menu/menu-item/menu-item.module';
import { UIHelpers } from './core/uihelpers';
import { LoginPageModule } from './features/account/login/login.module';
import { SignupPageModule } from './features/account/signup/signup.module';
import {ResetpwdPageModule} from './features/account/resetpwd/resetpwd.module';
import { MenuCategoriesListComponent } from './features/menu/menu-categories-list/menu-categories-list.component';
import { GrayLogErrorHandler } from './core/js-error-handling/graylog-error-handler';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { TableOrderPageModule } from './features/order-from-table/table-order/table-order.module';
import { TableOrderStep1Component } from './features/order-from-table/table-order-step1/table-order-step1.component';
import { TableOrderMenuPageModule } from './features/order-from-table/table-order-menu/table-order-menu.module';
import { TableOrderMenuItemPageModule } from './features/order-from-table/table-order-menu-item/table-order-menu-item.module';
// tslint:disable-next-line: max-line-length
import { TableOrderMenuCategoriesComponent } from './features/order-from-table/table-order-menu-categories/table-order-menu-categories.component';
import { CheckinPageModule } from './features/order/checkin/checkin.module';
import { IBeacon } from '@ionic-native/ibeacon/ngx';
import { GetContactInfoPageModule } from './features/checkout/get-contact-info/get-contact-info.module';
import { RewardPageModule } from './features/reward/reward/reward.module';
import {ConfirmOtpModule} from './features/reward/confirm-otp/confirm-otp.module'
import { LoginRewardModule } from './features/reward/login-reward/login-reward.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MenuCategoriesListComponent,
    TableOrderMenuCategoriesComponent
    
  ],
  entryComponents: [
    MenuCategoriesListComponent,
    TableOrderMenuCategoriesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    LandingPagePageModule,
    RestaurantLandingPagePageModule,
    StartAnOrderPageModule,
    MenuItemPageModule,
    LoginPageModule,
    SignupPageModule,
    ResetpwdPageModule,
    TableOrderPageModule,
    TableOrderMenuPageModule,
    TableOrderMenuItemPageModule,
    CheckinPageModule,
    GetContactInfoPageModule,
    RewardPageModule,
    ConfirmOtpModule,
    LoginRewardModule,
    NgxStripeModule.forRoot(environment.stripe.publishableKey)
    ],
  providers: [
    StatusBar,
    SplashScreen,
    UIHelpers,
    BarcodeScanner,
    IBeacon,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: GrayLogErrorHandler},
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
   }    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
