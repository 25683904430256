import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderTypeInterface } from '../order-type.interface';
import { Order } from '../../order.model';
import * as Moment from 'moment';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-curbside-pickup',
  templateUrl: './curbside-pickup.component.html',
  styleUrls: ['./curbside-pickup.component.scss'],
})
export class CurbsidePickupComponent
implements OnInit, OrderTypeInterface {

  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }
  canStart(): boolean {
    return this.formGroup.valid;
  }
  initOrder(): Order {
    const order: Order = new Order();
    order.orderType = 'CURBSIDE PICKUP';
    order.orderDt = this.deriveOrderDt(
      this.formGroup.controls.orderDate.value,
      this.formGroup.controls.orderTime.value
    );
    order.hrsOfOprName = this.formGroup.controls.orderTime.value.hrsOfOprName;
    return order;
  }
  private deriveOrderDt(orderDate, orderTime): Date {
    const orderDt = Moment(orderDate);
    const tm = new Date(orderTime.slotTime);
    orderDt.hour(tm.getHours());
    orderDt.minute(tm.getMinutes());
    orderDt.second(0);
    orderDt.millisecond(0);
    return orderDt.toDate();
  }


  ngOnInit() {
    this.formGroup = this.formBuilder.group({});
  }

}
