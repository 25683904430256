import { Pipe, PipeTransform } from "@angular/core";
import { Injectable } from '@angular/core';

@Pipe({
    name: "searchFilter",
    pure: false
})

@Injectable()
export class SearchFilterPipe implements PipeTransform {

	notNull(s){
		return ((typeof s) != 'undefined')&&(s!=null); 
	}
    isNull(s){
		return ((typeof s) == 'undefined')||(s==null); 
	}
 
    transform(items: Array<any>, searchText: string): Array<any> {
        let result=items;
        if (this.notNull(searchText) && searchText.length>2){
            result=items.filter(item => {
                let regExp=new RegExp(searchText, "i");
                if (item.name.match(regExp))
                    return true;
                if (this.notNull(item.description) && item.description.match(regExp)){
                    return true;
                }
                return false;
            });

        }
        return result;
    
    }
  
}