<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="app-home-tab">
      <ion-icon name="home-outline"></ion-icon>
      <ion-label>Home</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="restaurant-list">
      <ion-icon name="ios-restaurant"></ion-icon>
      <ion-label *ngIf="!customApp">Restaurants</ion-label>
      <ion-label *ngIf="customApp">Locations</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="account-tab">
      <ion-icon name="person-outline"></ion-icon>
      <ion-label>Account</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="more-tab">
      <ion-icon name="apps"></ion-icon>
      <ion-label>More</ion-label>
    </ion-tab-button>

  </ion-tab-bar>
</ion-tabs>
