<ion-header>
  <ion-toolbar color="primary">
    <ion-title>CHECKIN</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancelModal()" 
        [disabled]="working"
      >
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="formGroup">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h3>{{restaurant.name}}</h3>
        <p>{{restaurant.address.line1}}, {{restaurant.address.city}}, {{restaurant.address.state}} -
          {{restaurant.address.zip}}. </p>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="order.orderType==='CURBSIDE CHECKIN'">
      <ion-col>Checkin, once you are in the parking-lot / designated pickup spot of the restaurant.</ion-col>
    </ion-row>
    <ion-row *ngIf="order.orderType==='DRIVE-THRU PICKUP'">
      <ion-col>Checkin, once you are at the drive-thru window of the restaurant.</ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item class="no-padding">
          <ion-label position="floating">Car and Location Info</ion-label>
          <ion-input type="text"
            formControlName="locationInfo"
            placeholder="Car Color, Make, Model and location" >
          </ion-input>
        </ion-item>
        <app-invalid-messages [control]="formGroup.controls.locationInfo"
        [validationMessages]="validationMessages.locationInfo">
      </app-invalid-messages>


      </ion-col>

    </ion-row>
    <ion-row *ngIf="successMsg">
      <ion-col>
        <ion-text color="success">{{successMsg}}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="errorMsg">
      <ion-col>
        <ion-text color="danger">{{errorMsg}}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col offset-sm="3" size-sm="6">
        <ion-button 
          color="primary" expand="block"
          (click)="checkin()"
          [disabled]="working"
          >CHECKIN</ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col offset-sm="3" size-sm="6">
        <ion-button 
          color="secondary" expand="block" 
          (click)="cancelModal()"
          [disabled]="working"
          >CANCEL</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>

</form>

</ion-content>
