import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { TableOrder } from '../table-order.model';
import { OrderService } from '../../order/order.service';
import { Order } from '../../order/order.model';
import { TableOrderBatchComponent } from '../table-order-batch/table-order-batch.component';
import { UIHelpers } from '../../../core/uihelpers';
import { Router } from '@angular/router';
import { User } from '../../account/user.model';
import { UserService } from '../../account/user.service';
import { NavController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-table-order',
  templateUrl: './table-order.page.html',
  styleUrls: ['./table-order.page.scss'],
})
export class TableOrderPage implements OnInit {
  subscriptions: Subscription[] = [];
  restaurant: Restaurant;
  tableOrder: TableOrder;
  user: User;
  @ViewChild('beverageComp', {static: true})
  public beverageComp: TableOrderBatchComponent;
  @ViewChild('dishComp', {static: true})
  dishComp: TableOrderBatchComponent;
  @ViewChild('finishComp', {static: true})
  finishComp: TableOrderBatchComponent;
  batchComponents:TableOrderBatchComponent[]=[];

  constructor(
    private restaurantService: RestaurantService,
    private orderService: OrderService,
    private uiHelpers:UIHelpers,
    private router: Router,
    private navController: NavController,
    private userService: UserService

  ) {
  }

  ngOnInit() {
    this.batchComponents.push(this.beverageComp);
    this.batchComponents.push(this.dishComp);

    this.subscribeLoggedUser();    
    
    this.subscriptions.push(this.restaurantService.getRestaurant().subscribe(
      {
        next: (restaurant: Restaurant) => {
          this.restaurant = restaurant;
          this.subscriptions.push(this.orderService.getOrder().subscribe({
            next: (order: Order) => {
              if ( (order) && (order.orderType === 'TABLE ORDER') ) {
                this.tableOrder = order as TableOrder;
              }
            }
          }));

        }
      }
    ));

  }
  subscribeLoggedUser(){
    const subs=this.userService.getLoggedInUser().subscribe({
      next: (user) => {
        this.user = user;
      }
    });
    this.subscriptions.push(subs);

  }

  incPeople(){
    if (this.tableOrder.noOfPeople < 10) {
      this.tableOrder.noOfPeople++;
    }
  }
  decPeople(){
    if (this.tableOrder.noOfPeople > 1) {
      this.tableOrder.noOfPeople--;
    }
  }
  initComps(){
    if (this.tableOrder){
      for(let i=2;i<=this.tableOrder.state;++i){
        this.batchComponents[i-2].initialize();
      }
    }
  }
  next($event) {
    if (this.beverageComp) {
        this.beverageComp.initialize();
    }
  }
  nextToDish() {
    let batch=this.tableOrder.batches[0];
    let sender=this.tableOrder.section+' Table #'+this.tableOrder.table;
    let message="";
    if (batch?.people){
      for(let person of batch.people){
        message+=person.personName+': ';
        if (person.items){
          for(let item of person.items){
            message+=item.name;
            message+' ';
            if (item.options){
              for(let opt of item.options){
                if (opt.selected){
                  message+=opt.name+' ';
                }
              }
            }
            message+="; "
          }
        }
      }
    
    }
    this.orderService.buzz(this.restaurant, sender, message)
    .subscribe((data)=>{
      if (data.code==0){
        this.uiHelpers.showSuccessMsg('Beeverage items sent to your host.');
      }else{
        this.uiHelpers.showErr("Error: "+data.errMsg);
      }
    }, ((err:any)=>{
    })) ;
    if (this.dishComp) {
      this.dishComp.initialize();
    }
  }
  nextToFinish(){
    if (this.finishComp) {
      this.finishComp.initialize();
    }
  }
  reloadNavigate(url) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([url]);
    });
  }


  startOver() {
    this.uiHelpers.confirm('Are you sure?', 'All items in your table order will be removed.', () => {
      this.orderService.clearCart();
      this.router.navigate(['/restaurant', this.restaurant.uri, 'home']);
   });

  }
  gotoPayment() {
    // fixstripe

    // IonicBTWallet.canMakePayments({btAuthorization:
    //     environment.btTokenizationKey}).then((v:any)=>{
    //   if (v.result){
    //     this.router.navigate(['/checkout/', 'mobile-wallet-submit']);

    //   }else{
    //     this.router.navigate(['/checkout/', 'credit-card-submit']);
         
    //   }

    // }).catch((err:any) => {
    //   this.router.navigate(['/checkout/', 'credit-card-submit']);
        
    // });
   
  }

 
}
