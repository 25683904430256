<ion-row [formGroup]="formGroup">
  <ion-col size="12" size-sm="6">
    <ion-item>
  
    <ion-label position="stacked">{{orderTypeLabel}} Date</ion-label>
    <ion-select placeholder="Select Date" formControlName="orderDate"
        (ionChange)="orderDateChange()" label=""
    >
    <ion-select-option 
      *ngFor="let orderDate of orderDateList" 
      [value]="orderDate.dt"
    >{{orderDate.dtStr}}</ion-select-option>
    </ion-select>
  </ion-item>
  <app-invalid-messages
  [control]="formGroup.controls.orderDate"
  [validationMessages]="validationMessages.orderDate"
>
</app-invalid-messages>

  </ion-col>
  <ion-col size="12" size-sm="6">
      <ion-item>
          <ion-label position="stacked">{{orderTypeLabel}} Time</ion-label>
          <ion-select class="width-sm" placeholder="Select Time"
            formControlName="orderTime" label=""
          >
            <ion-select-option
             *ngFor="let orderSlot of orderSlots; let idx = index"
             [value]="orderSlot"
             
             >{{orderSlot.slotTime|date:'hh:mm a'}}</ion-select-option
              >
          </ion-select>
        </ion-item>
        <app-invalid-messages
        [control]="formGroup.controls.orderTime"
        [validationMessages]="validationMessages.orderTime"
      >
      </app-invalid-messages>

  </ion-col>
</ion-row>
