import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { RewardService } from '../reward.service';
import { RegExPatterns } from '../../../core/regex-patterns';
import { FdlValidators } from '../../../core/fdl-validators';
import { ModalController, NavParams, PickerController } from '@ionic/angular';
import { ConfirmOtpComponent } from '../confirm-otp/confirm-otp.component'
import { RewardAccount } from '../reward.model';
import { UIHelpers } from '../../../core/uihelpers';
import { LoginRewardComponent} from '../login-reward/login-reward.component'


@Component({
  selector: 'app-join-reward',
  templateUrl: './join-reward.component.html',
  styleUrls: ['./join-reward.component.scss'],
})
export class JoinRewardComponent implements OnInit {
  subRestaurant: Subscription;
  restaurant: Restaurant;
  formGroup: FormGroup;
  validationMessages: any;
  rewardAccount: RewardAccount;
  @Input()
  usage: string='modal';
  isModal: boolean=false;

  constructor(
    private restaurantService: RestaurantService,
    private rewardService: RewardService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private pickerCtrl: PickerController,
    private uiHelpers: UIHelpers,
    
    
  ) {
   }

  ngOnInit() {
    this.isModal=this.usage=='modal';

    this.subRestaurant=this.restaurantService.getRestaurant().subscribe( (restaurant: Restaurant)=>{
      this.restaurant=restaurant;
    }

    );

    this.formGroup = this.formBuilder.group({

      mobileNo: [null, Validators.compose([
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(RegExPatterns.phoneNo)
        ])],
      fullName: [null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(80),
        Validators.pattern(RegExPatterns.personName)
      ])],
      dob: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(5),
        FdlValidators.monthDay()
      ])]
    });
    this.validationMessages = {
      mobileNo: [
        {type: 'required', message: 'Mobile No is required'},
        {type: 'maxlength', message: 'Mobile No is too long'},
        {type: 'pattern', message: 'Invalid Mobile No'}
      ],
      fullName: [
        {type: 'required', message: 'Name is required'},
        {type: 'minlength', message: 'Name is too short'},
        {type: 'maxlength', message: 'Name is too long'},
        {type: 'pattern', message: 'Invalid Charecters found'}
      ],
      dob: [
        {type: 'required', message: 'Birth Month and Day is required'},
        {type: 'maxlength', message: 'Birth Month and Day  is too short'},
        {type: 'maxlength', message: 'Name is too long'},
        {type: 'pattern', message: 'Invalid Birth Month and Day'},
        {type: 'invalidMonthDay', message: 'Invalid Birth Month and Day'}
        
      ]
    };
  }
  ngOnDestroy(): void {
    if (this.subRestaurant){
      this.subRestaurant.unsubscribe();
    }
  }
  async confirmOtp(mobileNo){

     let modal= await this.modalController.create({
      component: ConfirmOtpComponent,
      componentProps: {
        mobileNo
      }});
      await modal.present();
      await modal.onDidDismiss();
      if (this.isModal){
        this.modalController.dismiss();
      }
  }
  async showLoginPage(){
    let modal=await this.modalController.create({
      component: LoginRewardComponent,
      componentProps: {
      }});
    await modal.present();
    await modal.onDidDismiss();
    if (this.isModal){
        this.modalController.dismiss();
    }

  }

  async join(){
    if (this.formGroup.valid){
      let json=this.formGroup.value;
      try{
        await this.rewardService.join(json.mobileNo, json.fullName, json.dob);
      } catch (data){
        this.uiHelpers.showErr('Join error. '+data.text+'. Please cancel and press login button to get an OTP.');
        return;
      }
      this.confirmOtp(json.mobileNo);
    }
  }
  private makeMonthOptions(){
    let options:any=[];
    let months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let i=1;
    for(let month of months){
      let stri= (i<10)?'0'+i:''+i;
      options.push({
        text:month,
        value: stri
      });
      ++i;
    }
    return options;
  }
  private makeDayOptions(){
    let options:any=[];
    
    let i=1;
    for(let i=1;i<=31;++i){
      let stri= (i<10)?'0'+i:''+i;
      options.push({
        text: stri,
        value: stri
      });
    }
    return options;
  }

  async openPicker() {
    const picker = await this.pickerCtrl.create({
      columns: [
        {
          name: 'month',
          options: this.makeMonthOptions()
        },
        {
          name: 'day',
          options: this.makeDayOptions()          
          
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (value) => {
            this.formGroup.controls.dob.setValue(`${value.month.value}/${value.day.value}`);
          },
        },
      ],
    });

    await picker.present();
  }
  cancel(){
    this.modalController.dismiss();
  }

}
