<form [formGroup]="formGroup">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-text class="ion-text-wrap"><p>Order ahead. Drive to the restaurant's parking lot.
            Press the CHECKIN button, from your order detail page.
            A Restaurants staff will bring your carryout bag to your car.</p></ion-text>
          </ion-item>            
      </ion-col>
    </ion-row>
    <app-order-date-time [formGroup]="formGroup" orderType="CURBSIDE PICKUP">
    </app-order-date-time>
  
  </ion-grid>
  </form>
  