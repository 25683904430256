<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
      <app-logo></app-logo>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="full">
    <ion-list-header><ion-label>Settings</ion-label></ion-list-header>
    <ion-item >
      <ion-label class="ion-text-wrap" >Dark Mode </ion-label>
      <ion-toggle slot="end"
      [(ngModel)]="darkMode" (ionChange)="setDarkMode()"></ion-toggle>
  </ion-item>
  </ion-list>

</ion-content>
