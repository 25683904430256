import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/core/storage.service';
import * as Moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserSession } from './usersession.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl: string;
  private user: BehaviorSubject<User>;
  device: any;
  private callerUrl: string;
  private userSession: UserSession;

  constructor(
    private storageService: StorageService,
    private http: HttpClient

  ) {
    this.baseUrl = environment.baseUrl;
    this.user = new BehaviorSubject<User>(null);
    const unknown='Unknown';
    this.device={
      manufacturer:unknown,
      model: unknown,
      platform: unknown,
      osVersion: unknown,
      uuid: unknown
    };
  
    this.storageService.get('fdlusersession').then((userSession: any) => {
      this.userSession = userSession;
      if (userSession){
        this.loadUser().then((usr: any) => {
        if ( (usr) && (usr.emailId) ) {
          const user: User = {
            emailId: this.userSession.emailId,
            authKey: this.userSession.authKey,
            firstName: usr.name.first,
            lastName: usr.name.last,
            createDt: new Date(),
            phone: usr.phone,
            sendTextMsg: usr.sendTextMsg,
            idStr: usr.idStr,
            customerId: usr.customerId
          };
          this.user.next(user);
          this.heartBeat();
        } else {
          this.user.next(null);
        }
  
        }).catch(err=>{
          this.user.next(null);  
        });
      }else{
        this.user.next(null);
      }
    }).catch((err) => {
      this.user.next(null);
    });
  }
  getCallerUrl(): string {
    return this.callerUrl;
  }
  setCallerUrl(callerUrl: string){
    this.callerUrl = callerUrl;
  }
  heartBeat() {
    const user = this.user.value;
    if ( (user) && (user.authKey)){
      this.expirationCheck();
      if (user.authKey) {
        this.userSession.expirationDt = Moment().add(this.userSession.keepAliveMins, 'm').toDate();
        this.saveUserSession();
      }
    }
  }
  async expirationCheck() {
    const user = this.user.value;
    if ((this.userSession.expirationDt) && (Moment().isSameOrAfter(Moment(this.userSession.expirationDt)))) {
      this.logout().then(() => {
      }).catch((err) => {

      });
    }
  }
  private saveUserSession() {
    this.storageService.set('fdlusersession', this.userSession).then(() => {
    });
  }
  login(json) {
    return new Promise((resolve, reject) => {
      json.manufacturer = this.device.manufacturer;
      json.model = this.device.model;
      json.platform = this.device.platform;
      json.version = this.device.osVersion;
      if (this.device.uuid){
        json.uuid = this.device.uuid;
      }else{
        json.uuid="NA";

      }
      
      this.http.post(this.baseUrl + 'account/mobisignin', json)
        .subscribe((data: any) => {
          if (data.code === 0) {
            const usr = data.retObj2;
            const keepAlivMins = (json.keepMeLoggedIn ? 43200 : 60);
            const user: User = {
              emailId: json.emailId,
              authKey: data.retObj,
              firstName: usr.name.first,
              lastName: usr.name.last,
              createDt: new Date(),
              phone: usr.phone,
              sendTextMsg: usr.sendTextMsg,
              idStr: usr.idStr,
              customerId: usr.customerId
            };
            this.userSession = {
              idStr: usr.idStr,
              keepAliveMins: keepAlivMins,
              emailId: user.emailId,
              authKey: data.retObj,
              expirationDt: Moment().add(keepAlivMins, 'm').toDate()
             };
            this.user.next(user);
            this.saveUserSession();
            resolve(false);
          } else if (data.code === 1) {
            resolve(true);
          } else {
            this.user.next(null);
            reject('Sign In Error: ' + data.text);
          }

        }, err => {
          this.user.next(null);
          reject('Error. ' + err);
        });
    });
  }
  public getLoggedInUser(): Observable<User> {
    return this.user;
  }
  loadUser() {
    return new Promise((resolve, reject) => {
      if ((this.userSession) && (this.userSession.authKey)) {
        const httpOptions = {
          headers: new HttpHeaders({
            emailId: this.userSession.emailId,
            authKey: this.userSession.authKey
          })
        };
        this.http.post(this.baseUrl + 'account/mobigetuser', 
        {
          idStr: this.userSession.idStr
        }, httpOptions)
          .subscribe((data: any) => {
            if (data.code === 0) {
              resolve(data.retObj);
            } else {
              reject({ code: data.code, text: data.text });
            }

          }, err => {
            reject({ code: 99, text: err });
          });
      }
    });
  }
  getUserProfile(usrId) {
    const user = this.user.value;
    return new Promise((resolve, reject) => {
      if (user.authKey) {
        const httpOptions = {
          headers: new HttpHeaders({
            emailId: user.emailId,
            authKey: user.authKey
          })
        };
        this.http.post(
          this.baseUrl + 'order/mobigetuserprofile',
           { userId: usrId }, httpOptions)
          .subscribe((data: any) => {
            if (data.code === 0) {
              resolve(data.retObj);
            } else {
              reject({ code: data.code, text: data.text });
            }

          }, err => {
            reject({ code: 99, text: err });
          });
      }
    });
  }

  delete() {
    const user = this.user.value;
    return new Promise((resolve, reject) => {
      if (user.authKey) {
        const httpOptions = {
          headers: new HttpHeaders({
            emailId: user.emailId,
            authKey: user.authKey
          })
        };
        this.http.post(this.baseUrl + 'account/mobidelete', {}, httpOptions)
          .subscribe((data: any) => {
            if (data.code === 0) {
              this.user.next(null);
              this.userSession = null;
              this.saveUserSession();
              resolve(null);
            } else {
              reject('Error Code: ' + data.code + ' Err Txt:' + data.text);
            }
          }, err => {
            reject('Error. ' + err);
          });
      }
    });
  }

  logout() {
    const user = this.user.value;
    return new Promise((resolve, reject) => {
      if (user.authKey) {
        const httpOptions = {
          headers: new HttpHeaders({
            emailId: user.emailId,
            authKey: user.authKey
          })
        };
        this.http.post(this.baseUrl + 'account/mobisignout', {}, httpOptions)
          .subscribe((data: any) => {
            if (data.code === 0) {
              this.user.next(null);
              this.userSession = null;
              this.saveUserSession();
              resolve(null);
            } else {
              reject('Error Code: ' + data.code + ' Err Txt:' + data.text);
            }
          }, err => {
            reject('Error. ' + err);
          });
      }
    });
  }

  isEmailIdUnique(emlId: string) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + 'account/isemailidunique', { emailId: emlId })
        .subscribe((data: any) => {
          if (data.code === 0) {
            const isUnique: boolean = data.retObj;
            resolve(isUnique);
          } else {
            reject('Error Code: ' + data.code + ' Err Txt:' + data.text);
          }

        }, err => {
          reject('Error. ' + err);
        });
    });
  }

  sendEmailVerificationCode(json) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + '/account/mobisendverificationcode', json)
        .subscribe((data: any) => {
          if (data.code === 0) {
            resolve(null);
          } else {
            reject('Error Code: ' + data.code + ' Err Txt:' + data.text);
          }
        }, err => {
          reject('Error. ' + err);
        });
    });
  }

  signup(json) {
    return new Promise((resolve, reject) => {
        json.manufacturer = this.device.manufacturer;
        json.model = this.device.model;
        json.platform = this.device.platform;
        json.version = this.device.osVersion;
        json.uuid = this.device.uuid;
        this.http.post(this.baseUrl + 'account/mobisignup', json)
            .subscribe((data: any) => {
                if (data.code === 0) {
                  // const user = data.retObj;
                  // this.user.next(user);
                  resolve(null);
                } else {
                  this.user.next(null);
                  reject('Signup  Error: ' + data.text);
                }

            }, err => {
              this.user.next(null);
              reject('Error. ' + err);
            });
    });
}

verifyEmail(emailId: string, activationCode: string) {
    return new Promise((resolve, reject) => {
        const json: any = {};
        json.emailId = emailId;
        json.code = activationCode;
        json.manufacturer = this.device.manufacturer;
        json.model = this.device.model;
        json.platform = this.device.platform;
        json.version = this.device.osVersion;
        json.uuid = this.device.uuid;
        this.http.post(this.baseUrl + 'account/mobiverify/email', json)
            .subscribe((data: any) => {
                if (data.code === 0) {
                  const usr = data.retObj2;
                  const user: User = {
                    emailId: json.emailId,
                    authKey: data.retObj,
                    firstName: usr.name.first,
                    lastName: usr.name.last,
                    createDt: new Date(),
                    phone: usr.phone,
                    sendTextMsg: usr.sendTextMsg,
                    idStr: usr.idStr,
                    customerId: usr.customerId
                  };
                  this.userSession = {
                    idStr: usr.idStr,
                    keepAliveMins: 60,
                    emailId: user.emailId,
                    authKey: data.retObj,
                    expirationDt: Moment().add(60, 'm').toDate()
                   };

                  this.user.next(user);
                  this.saveUserSession();
                  resolve(null);
                } else {
                    this.user.next(null);
                    reject('Error: ' + data.text);
                }

            }, err => {
              this.user.next(null);
              reject('Error: ' + err);
            });
    });
}

resendCode(emailId: string) {
  return new Promise((resolve, reject) => {
      const json: any = {};
      json.emailId = emailId;
      json.manufacturer = this.device.manufacturer;
      json.model = this.device.model;
      json.platform = this.device.platform;
      json.version = this.device.osVersion;
      json.uuid = this.device.uuid;
      this.http.post(this.baseUrl + 'account/mobiverify/email/resendcode', json)
          .subscribe((data: any) => {
              if (data.code === 0) {
                resolve(null);
              } else {
                this.user.next(null);
                reject('Error: ' + data.text);
              }

          }, err => {
            this.user.next(null);
            reject('Error: ' + err);
          });
  });
}
resetPwdStart(emailId: string) {
  return new Promise((resolve, reject) => {
      const json: any = {};
      json.emailId = emailId;
      this.http.post(this.baseUrl + 'account/resetpwd/start', json)
          .subscribe((data: any) => {
              if (data.code === 0) {
                resolve(null);
              } else {
                reject('Error: ' + data.text);
              }

          }, err => {
            this.user.next(null);
            reject('Error: ' + err);
          });
  });
}
resetPwdVerify(emailId: string, securityCode: string) {
  return new Promise((resolve, reject) => {
      const json: any = {};
      json.emailId = emailId;
      json.securityCode = securityCode;
      this.http.post(this.baseUrl + 'account/resetpwd/verify', json)
          .subscribe((data: any) => {
              if (data.code === 0) {
                resolve(null);
              } else {
                reject('Error: ' + data.text);
              }

          }, err => {
            this.user.next(null);
            reject('Error: ' + err);
          });
  });
}
resetPwdFinish(emailId: string, securityCode: string, password: string) {
  return new Promise((resolve, reject) => {
    const json: any = {};
    json.emailId = emailId;
    json.password = password;
    json.securityCode = securityCode;
    json.manufacturer = this.device.manufacturer;
    json.model = this.device.model;
    json.platform = this.device.platform;
    json.version = this.device.osVersion;
    json.uuid = this.device.uuid;
    this.http.post(this.baseUrl + 'account/mobiresetpwd/finish', json)
      .subscribe((data: any) => {
        if (data.code === 0) {
          const usr = data.retObj2;
          const keepAlivMins = (json.keepMeLoggedIn ? 43200 : 60);
          const user: User = {
            emailId: json.emailId,
            authKey: data.retObj,
            firstName: usr.name.first,
            lastName: usr.name.last,
            createDt: new Date(),
            phone: usr.phone,
            sendTextMsg: usr.sendTextMsg,
            idStr: usr.idStr,
            customerId: usr.customerId
          };
          this.userSession = {
            idStr: usr.idStr,
            keepAliveMins: keepAlivMins,
            emailId: user.emailId,
            authKey: data.retObj,
            expirationDt: Moment().add(keepAlivMins, 'm').toDate()
           };

          this.user.next(user);
          this.saveUserSession();
          resolve(false);
        } else if (data.code === 1) {
          resolve(true);
        } else {
          this.user.next(null);
          reject('Sign In Error: ' + data.text);
        }

      }, err => {
        this.user.next(null);
        reject('Error. ' + err);
      });
  });
}


userChanged(user: User){
  this.user.next(user);
}


}

