<ion-tabs>
    <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home">
        <ion-icon name="ios-restaurant"></ion-icon>
        <ion-label *ngIf="!customApp">Restaurant</ion-label>
        <ion-label *ngIf="customApp">Location</ion-label>
      </ion-tab-button>
  
      <ion-tab-button tab="menu">
        <ion-icon name="ios-booklet"></ion-icon>
        <ion-label>Menu</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="cart" class="animate-cart">

<ion-icon name="ios-plate"  ></ion-icon>
          <ion-badge  id="cart-badge" *ngIf="(order?.cart?.items)" color="danger">{{order.cart.items.length}}</ion-badge> 
          <ion-badge id="cart-badge" *ngIf="(order?.cart)&&!(order.cart.items)" color="danger">0</ion-badge>
          <ion-label > My Order</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="my-orders?homeBtn" *ngIf="!(restaurant?.rwdcfg?.rewardEnabled)">
        <ion-icon name="archive-outline"></ion-icon>
        <ion-label>History</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="reward?homeBtn" *ngIf="restaurant?.rwdcfg?.rewardEnabled">
        <ion-icon name="cash-outline"></ion-icon>
        <ion-label>Rewards</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="more-tab">
        <ion-icon name="apps"></ion-icon>
        <ion-label>More</ion-label>
      </ion-tab-button>      
      </ion-tab-bar>
  </ion-tabs>
  