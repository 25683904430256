import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { UIHelpers } from '../../../core/uihelpers';
import { RewardAccount } from '../reward.model';
import { RewardService } from '../reward.service';

@Component({
  selector: 'app-confirm-otp',
  templateUrl: './confirm-otp.component.html',
  styleUrls: ['./confirm-otp.component.scss'],
})
export class ConfirmOtpComponent implements OnInit {
  mobileNo: string;

  formGroup: FormGroup;
  validationMessages: any;
  constructor(
    private navParams: NavParams,
    private formBuilder: FormBuilder,
    private rewardService: RewardService,
    private uiHelpers: UIHelpers,
    private modalController: ModalController
  ){ }

  ngOnInit() {
   
    this.mobileNo=this.navParams.get('mobileNo');
  
    this.formGroup = this.formBuilder.group({
      otp: [null, Validators.compose([
          Validators.required,
          Validators.maxLength(6)
        ])]
      
    });

    this.validationMessages = {
      otp: [
        {type: 'required', message: 'OTP is required'},
        {type: 'maxlength', message: 'OTP too long'}

      ]
    };  
  }
  confirm(){
    if (this.formGroup.valid){
      this.rewardService.otpLogin(this.mobileNo, this.formGroup.value.otp)
        .then(()=>{
          this.uiHelpers.showSuccessMsg('Now, you are a Rewards Member. Welcome Points Granted!')
          this.uiHelpers.showConfetti();
          this.modalController.dismiss();

        }).catch((data:any)=>{
          this.uiHelpers.showErr("Error: " + data.text);
        });
    }

  }
  otpFieldChange(){
    let v:string=this.formGroup?.controls?.otp?.value;
    if (v){
      if (v.length==6){
        this.confirm();
      }
      
    }
  }

}
