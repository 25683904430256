import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { InvalidMessagesComponent } from './invalid-messages.component';

@NgModule({
    declarations: [
      InvalidMessagesComponent
    ],
    imports: [
      CommonModule,
      IonicModule.forRoot(),
    ],
    exports: [
        InvalidMessagesComponent
    ]
  })
 export class InvalidMessagesModule { }
