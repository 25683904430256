import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { ConfirmOtpComponent } from './confirm-otp.component';
import { InvalidMessagesModule } from '../../../core/invalid-messages/invalid-messages.module';

@NgModule({
    declarations: [
        ConfirmOtpComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      InvalidMessagesModule,
      BrMaskerModule,
      IonicModule.forRoot()
    ],
    exports: [
      ConfirmOtpComponent

    ]
  })
 export class ConfirmOtpModule { }