import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ShowQRComponent } from './show-qr.component';
import { QRCodeModule} from 'angularx-qrcode';

@NgModule({
    declarations: [
        ShowQRComponent
    ],
    imports: [
      CommonModule,
      IonicModule.forRoot(),
      QRCodeModule
      
    ],
    exports: [
        ShowQRComponent
    ]
  })
 export class ShowQRModule { }