import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RewardPageRoutingModule } from './reward-routing.module';

import { RewardPage } from './reward.page';
import { JoinRewardComponent } from '../join-reward/join-reward.component';
import { JoinRewardModule } from '../join-reward/join-reward.module';
import { ShowQRModule } from '../show-qr/show-qr.module';
import { RewardDetailModule } from '../reward-detail/reward-detail.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RewardPageRoutingModule,
    JoinRewardModule,
    ShowQRModule,
    RewardDetailModule
  ],
  declarations: [RewardPage]
})
export class RewardPageModule {}
