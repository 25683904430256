import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Restaurant } from '../../restaurant/restaurant.model'
import { Order } from '../../order/order.model'
import { RestaurantService } from '../../restaurant/restaurant.service'
import { OrderService } from '../../order/order.service'
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UIHelpers } from '../../../core/uihelpers'
import { UserService } from '../../account/user.service';
import { User } from '../../account/user.model';

@Component({
  selector: 'app-get-contact-info',
  templateUrl: './get-contact-info.page.html',
  styleUrls: ['./get-contact-info.page.scss'],
})
export class GetContactInfoPage implements  AfterContentInit, OnDestroy {

  formGroup: FormGroup;
  validationMessages: any;
  subscriptions: Subscription[] = [];
  restaurant: Restaurant;
  order: Order;
  user: User;
  private readonly telPattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;


  constructor(
    private restaurantService: RestaurantService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private router: Router,
    private modalController: ModalController,
    private uiHelpers: UIHelpers,
    private userService: UserService

  ) { 
    this.validationMessages = {
      firstName: [
        {type: 'required', message: 'First Name is required'}
      ],
      lastName: [
        {type: 'required', message: 'Last Name is required'}
      ],
      emailId: [
        {type: 'required', message: 'Email ID is required'},
        {type: 'email', message:'Invalid Email ID'}
      ],
      phoneNo: [
        {type: 'required', message: 'Phone No is required'},
        {type: 'pattern', message:'Invalid Phone No'}
      ]


    };
    this.formGroup = this.formBuilder.group({
      firstName: [ '', Validators.required],
      lastName: ['', Validators.required],
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      phoneNo: ['', Validators.compose([Validators.required, Validators.pattern(this.telPattern)])],
      sendTextMsg: [true]
    }
    );


  }
  ngAfterContentInit(): void {
    this.subscribeRestaurant();
  }
  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
 
  save(){
    if (this.formGroup.valid){

      const data = this.formGroup.value;
      this.order.phone = data.phoneNo;
      this.order.emailId = data.emailId;
      this.order.sendTextMsg = data.sendTextMsg;
      this.order.name = data.firstName + ' ' + data.lastName;
      this.order.firstName=data.firstName;
      this.order.lastName=data.lastName;
      
      this.orderService.updateOrder(this.order).then(()=>{
        this.modalController.dismiss();
      }).catch((err)=>{
        this.uiHelpers.showErr("Unable to update contact. ");
      });
      


    }
  }
  cancel(){
    this.modalController.dismiss();
    
  }
  subscribeRestaurant(){
    const subscription = this.restaurantService.getRestaurant().subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;
        this.subscribeUser();
      },
      error: (err: any) => {
        // TODO: Handle Error
      }
    });
    this.subscriptions.push(subscription);
  }
  subscribeOrder(){
    const subscription = this.orderService.getOrder().subscribe({
      next: (order: Order) => {
        this.order = order;
        const ctrls:any = this.formGroup.controls;
        ctrls.phoneNo.value=this.order.phone;
        if (this.user){
          ctrls.emailId.value=this.user.emailId;
          ctrls.sendTextMsg.checked=true;
          ctrls.firstName.value=this.user.firstName
          ctrls.lastName.value=this.user.lastName;
        }else{
          ctrls.emailId.value=this.order.emailId;
          ctrls.sendTextMsg.checked=this.order.sendTextMsg;
          ctrls.firstName.value=this.order.firstName;
          ctrls.lastName.value=this.order.lastName;
  
        }
      },
      error: (err: any) => {
        // TODO: Handle Error

      }
    });
    this.subscriptions.push(subscription);

  }
  subscribeUser(){
    const subscription = this.userService.getLoggedInUser().subscribe({
      next: (user: User) => {
        this.user = user;
        this.subscribeOrder();
      },
      error: (err: any) => {
        // TODO: Handle Error

      }
    });
    this.subscriptions.push(subscription);

  }

  unsubscribeAll(){
    let eoa = false; // End of Array
    while (!eoa){
      const subscription = this.subscriptions.pop();
      if (subscription){
        subscription.unsubscribe();
      }else{
        eoa = true;
      }
    }
  }
}
