<ion-grid>
  <ion-row>
    <ion-col size="12" size-sm="6" size-md="4" size-lg ="3">
      <ion-item>
        <ion-label position="floating">Mobile No</ion-label>
        <ion-input  type="text" [readonly] value="{{rewardAccount.mobileNo}}">
        </ion-input>
      </ion-item>
      </ion-col>
      <ion-col size="12" size-sm="6" size-md="4" size-lg ="3">
        <ion-item>
          <ion-label position="floating">Full Name</ion-label>
          <ion-input  type="text" [readonly] value="{{rewardAccount.fullName}}">
          </ion-input>
        </ion-item>
        </ion-col>
        <ion-col size="6" size-md="4" size-lg ="3">
          <ion-item>
            <ion-label position="floating">Points</ion-label>
            <ion-input  type="text" [readonly] value="{{rewardAccount.points}}">
            </ion-input>
          </ion-item>
          </ion-col>
          <ion-col size="6" size-md="4" size-lg ="3">
            <ion-item>
              <ion-label position="floating">Visits</ion-label>
              <ion-input  type="text" [readonly] value="{{rewardAccount.visitCt}}">
              </ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-list>
            <ion-item>
                <h3>Available Rewards</h3>
             </ion-item>
             <ion-item *ngFor="let rwdItem of restaurant.rwdItems">
              <ion-label position="stacked">{{rwdItem.text}} {{rwdItem.points}} pts</ion-label>
              <ion-chip *ngIf="rewardAccount.visitCt<restaurant?.rwdcfg?.minVisits || rewardAccount.points<rwdItem.points">
                <ng-container *ngIf="rewardAccount.visitCt<restaurant?.rwdcfg?.minVisits">
                  {{restaurant?.rwdcfg?.minVisits-rewardAccount.visitCt}} more visits&nbsp;
                </ng-container>
                <ng-container *ngIf="rewardAccount.points<rwdItem.points">
                  {{rwdItem.points-rewardAccount.points}} more points&nbsp;
                </ng-container>
                needed.
              </ion-chip>
              <ion-chip color="success"
                *ngIf="!(rewardAccount.visitCt<restaurant?.rwdcfg?.minVisits || rewardAccount.points<rwdItem.points)">
                Available
              </ion-chip>

             </ion-item>
          </ion-list>
          </ion-col>
          <ion-col size="6" size-md="4" size-lg ="3" >
          </ion-col>
          </ion-row>
</ion-grid>