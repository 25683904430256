import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIHelpers } from '../../../core/uihelpers';
import { User } from '../user.model';
import { UserService } from '../user.service';


@Component({
  selector: 'fdl-login-status',
  templateUrl: './login-status.component.html',
  styleUrls: ['./login-status.component.scss'],
})
export class LoginStatusComponent implements OnInit {
  @Input()
  public user:User;

  constructor(
    private router: Router,
    private userService: UserService,
    private uiHelpers: UIHelpers
  ) { 

  }

  ngOnInit() {
    
  }
  login(){
    this.userService.setCallerUrl(this.router.url);
    this.router.navigateByUrl('/login');
  }

  signup(){
    this.userService.setCallerUrl(this.router.url);
    this.router.navigateByUrl('/signup');
  }
  logout() {
    this.userService.logout().then(() => {
    }).catch((msg) => {
      this.uiHelpers.showErr('Error in logout' + msg);
    });
  
  }
  
}
