<ion-card  class="ion-no-padding ion-no-margin" *ngIf="tableOrder.state >= thisState">
  <ion-card-subtitle>{{batchName}}</ion-card-subtitle>
  <ion-card-content class="ion-no-padding">
    <p>{{batchDesc}} </p>
    <ion-list *ngFor="let person of batch?.people" class="ion-no-padding">
      <ion-list-header lines="none" class="ion-no-padding"><ion-label class="ion-no-padding"><p>{{person.personName}}</p>
        <p><ion-button *ngIf="tableOrder.state === thisState" size="small" 
        fill="outline" (click)="openMenu(person)"><ion-icon  name="add" slot="start"></ion-icon>Add Item</ion-button> 
        </p>
      </ion-label>        
        </ion-list-header>
      <ion-item *ngIf="!person.items || person.items.length===0"><p>No Items. Add one.</p></ion-item>
      <ng-container *ngIf=" (person.items) && person.items.length > 0">
        <ion-item *ngFor="let item of person.items" class="ion-no-padding">
          <ion-label class="ion-text-wrap ion-no-padding">
            {{item.name}} ({{item.price |currency:'USD'}})
            <ion-text class="ion-float-right">{{item.totalPrice |currency:'USD'}}&nbsp;<ion-button 
              (click)="deleteItem(person, item)"
               size="small" fill="clear" ><ion-icon name="trash-outline" slot="icon-only">
               </ion-icon></ion-button>
            </ion-text>
            <p> 
              <ng-container *ngIf="item.qty>1">Qty: {{item.qty}}&nbsp;</ng-container>
            <ng-container *ngIf="(item.options)">
              <ng-container *ngFor="let opt of item.options; let first=first">
                  <span *ngIf="!(first) && (opt.responses.length > 0)">,</span>
                  <span *ngIf="(opt.responses.length > 0)">{{opt.name}}:</span>
                  <ng-container *ngFor="let resp of opt.responses; let first=first">
                    <span *ngIf="!(first)">,</span>
                    {{resp.text}}
                    <span *ngIf="resp.extraCost">({{resp.extraCost|currency:'USD'}})</span>
                  </ng-container>
              </ng-container>
            </ng-container>
            </p>
            <p *ngIf="item.notes">{{item.notes}}</p>
            <p></p>
            </ion-label>
          </ion-item>
      </ng-container>

    </ion-list>
    <div class="ion-margin-top; width:100%" *ngIf="tableOrder.state === thisState">
      <!--
      <ion-button 
        fill="outline" (click)="previousStep()" slot="start" size="small"
        ><ion-icon name="chevron-up-outline"></ion-icon>Previous</ion-button>-->
        <ion-button fill="outline" [disabled]='thisState===2'
        (click)="previousStep()" 
        >Previous</ion-button>

      <ion-button color="primary" class="ion-float-right"
        [disabled]="lastStep && tableOrder?.cart?.subTotal==0"

        (click)="nextStep()" slot="end" 
        >{{nextBtnLabel}}</ion-button>
    </div>
    </ion-card-content>
</ion-card>
<ion-card  class="ion-no-padding ion-no-margin" *ngIf="tableOrder.state < thisState">
  <ion-card-subtitle>{{batchName}}</ion-card-subtitle>
  <ion-card-content>
    <div>
      <ion-skeleton-text animated="false"></ion-skeleton-text>
      <ion-skeleton-text animated="false" style="width: 88%"></ion-skeleton-text>
      <ion-skeleton-text animated="false" style="width: 70%"></ion-skeleton-text>
      <ion-skeleton-text animated="false" style="width: 60%"></ion-skeleton-text>
    </div>    
  </ion-card-content>
</ion-card>
