import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { InvalidMessagesModule } from '../../../core/invalid-messages/invalid-messages.module';
import { BirthdayComponent} from './birthday.component'

@NgModule({
    declarations: [
        BirthdayComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      InvalidMessagesModule,
      BrMaskerModule,
      IonicModule.forRoot()
    ],
    exports: [
        BirthdayComponent
    ]
  })
 export class BirthdayModule { }