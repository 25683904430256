<ion-item lines="full" class="ion-margin-top" *ngIf="user">
  <ion-icon name="person" slot="start"></ion-icon>  
  <ion-label>Hello, {{user.firstName}}</ion-label>
  <p><ion-button fill="outline" 
    (click)="logout()"
    color="secondary">Logout</ion-button></p>
</ion-item>
<ion-item lines="full" class="ion-margin-top" *ngIf="!user">
  <ion-icon name="person" slot="start"></ion-icon>  
  <ion-label>Hello, Guest</ion-label>
  <ion-button fill="outline" color="secondary" (click)="login()" routerLinkActive="router-link-active" >Login</ion-button>
  <ion-button fill="outline" color="secondary" (click)="signup()">Signup</ion-button>
</ion-item>
