<form [formGroup]="formGroup">
<ion-grid>
  <ion-row>
    <ion-col>
        <ion-label class="fdl-sub-text">Order ahead and pickup from the restaurant.</ion-label>
    </ion-col>
  </ion-row>
  <app-order-date-time [formGroup]="formGroup" orderType="CARRYOUT">
  </app-order-date-time>

</ion-grid>
</form>
