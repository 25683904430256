<ion-header>
  <ion-toolbar color="primary">
   <ion-title>Checkout Contact</ion-title>
   <ion-buttons slot="end">
    <ion-button (click)="cancel()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h3>Contact Information</h3>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" size-sm="6" size-md="4" >
        <ion-item class="ion-no-padding">
          <ion-label position="floating">First Name</ion-label>
          <ion-input type="text" [readonly]="(user)"
          autocapitalize="words"
          formControlName="firstName"
          >
        </ion-input>
        </ion-item>
        <app-invalid-messages
        [control]="formGroup.controls.firstName"
        [validationMessages]="validationMessages.firstName"
      >
      </app-invalid-messages>

      </ion-col>
      <ion-col size="12" size-sm="6" size-md="4" >
        <ion-item class="ion-no-padding">
          <ion-label position="floating">Last Name</ion-label>
          <ion-input type="text" [readonly]="(user)"
          autocapitalize="words"
          formControlName="lastName"
          >
        </ion-input>
        </ion-item>
        <app-invalid-messages
        [control]="formGroup.controls.lastName"
        [validationMessages]="validationMessages.lastName"
      >
      </app-invalid-messages>
      </ion-col>
      
      <ion-col size="12" size-sm="6" size-md="4" >
        <ion-item class="ion-no-padding">
          <ion-label position="floating">Email ID</ion-label>
          <ion-input type="email"
          formControlName="emailId" [readonly]="(user)"
          inputmode="email"
             
          >
        </ion-input>
        </ion-item>
        <app-invalid-messages
        [control]="formGroup.controls.emailId"
        [validationMessages]="validationMessages.emailId"
    >
      </app-invalid-messages>

      </ion-col>
      <ion-col size="12" size-sm="6" size-md="4" >
        <ion-item class="ion-no-padding">
          <ion-label position="floating">Phone No</ion-label>
          <ion-input type="tel"
          placeholder="513-123-4321"
          formControlName="phoneNo"
          [brmasker]="{mask: '000-000-0000x00000'}"
          >
          </ion-input>
          </ion-item>
          <app-invalid-messages
          [control]="formGroup.controls.phoneNo"
          [validationMessages]="validationMessages.phoneNo"
      >
        </app-invalid-messages>
        <ion-text class="form-help-text">Mobile Number Prefered</ion-text>
      </ion-col>
      <ion-col size="12" size-sm="6" style="display:none">
        <ion-item >
          <ion-label>Text Order Updates? </ion-label>
          <ion-toggle formControlName="sendTextMsg" color="primary"></ion-toggle>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col offset-sm="3" size-sm="6">
        <ion-button color="primary" expand="block" (click)="save()">OK</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col offset-sm="3" size-sm="6">
        <ion-button color="secondary" expand="block" (click)="cancel()">Cancel</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
  </form>
</ion-content>
