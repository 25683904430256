import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { CheckinPage } from './checkin.page';
import { InvalidMessagesModule } from 'src/app/core/invalid-messages/invalid-messages.module';

const routes: Routes = [
  {
    path: '',
    component: CheckinPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InvalidMessagesModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [CheckinPage]
})
export class CheckinPageModule {}
