import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-menu-categories-list',
  templateUrl: './menu-categories-list.component.html',
  styleUrls: ['./menu-categories-list.component.scss'],
})
export class MenuCategoriesListComponent implements OnInit {
cat: any;
  constructor(private popoverCtrl: PopoverController, navParams: NavParams) {
    this.cat = navParams.get('cat');
   }

   ngOnInit() {}

   selectCategory(selectedCat: number) {
     this.popoverCtrl.dismiss({newCat: selectedCat});
   }
}


