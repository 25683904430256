import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LoginRewardComponent} from './login-reward.component'



import { InvalidMessagesModule } from '../../../core/invalid-messages/invalid-messages.module';
import { BrMaskerModule } from 'br-mask';

@NgModule({ 
  declarations: [
    LoginRewardComponent
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    InvalidMessagesModule,
    BrMaskerModule,    
    IonicModule.forRoot()
  ],
  exports: [
    LoginRewardComponent

  ]
})
export class LoginRewardModule {}
