import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { Restaurant } from 'src/app/features/restaurant/restaurant.model';
import { RestaurantService } from 'src/app/features/restaurant/restaurant.service';
import { UserService } from 'src/app/features/account/user.service';
import { User } from 'src/app/features/account/user.model';


@Injectable({
    providedIn: 'root'
})
export class GrayLogService implements OnDestroy{
    private platforms: string;
    private host: string;
    private restaurantUri: string;
    private userEmail: string;
    private getRestaurantSubs: Subscription;
    private userSubs: Subscription;
    constructor(
        private http: HttpClient,
        platform: Platform,
        private restaurantService: RestaurantService,
        private userService: UserService
    ) {
        this.platforms = '';
        this.host = environment.name + 'App';
        this.restaurantUri = '';
        this.userEmail = '';
        platform.ready().then(() => {
            if (platform.platforms()) {
                for (const p of platform.platforms()) {
                    this.platforms += p;
                }
            }

        });
        this.init();
    }
    public log(level: number, pathName:string, shortMessage: string, fullMessage: string, extra?: any){
        let data: any = {
            version: '1.1',
            level,
            host: this.host,
            short_message: shortMessage,
            full_message: fullMessage,
            _pathname: pathName,
            _user_agent: this.platforms,
            _uri: this.restaurantUri,
            _user: this.userEmail

        };
        if (extra) {
            data = Object.assign(data, extra);
        }
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Basic ZmRsOmVpajNTYWlkNXlhaVYsb2gxYWV0aHVsdWFsLWlhWWFo'
            })
        };
        this.http.post<any>(environment.graylogUrl, data, httpOptions).subscribe((data)=>{

        });

    }
    public logAlert(pathName:string, shortMessage: string, fullMessage: string, extra?: any) {
        this.log(1, pathName, shortMessage, fullMessage, extra);
    }
    public logError(pathName:string, shortMessage: string, fullMessage: string, extra?: any) {
        this.log(3, pathName, shortMessage, fullMessage, extra);
    }
    public logWarn(pathName:string, shortMessage: string, fullMessage: string, extra?: any) {
        this.log(5, pathName, shortMessage, fullMessage, extra);
    }
    public logInfo(pathName:string, shortMessage: string, fullMessage: string, extra?: any) {
        this.log(6, pathName,shortMessage, fullMessage, extra);
    }


    init(): void {
        this.getRestaurantSubs=this.restaurantService.getRestaurant()
            .subscribe({
                next: (restaurant: Restaurant) => {
                    if (restaurant) {
                        this.restaurantUri = restaurant.uri;
                    } else {
                        this.restaurantUri = '';
                    }
                }
            });
        this.userSubs=this.userService.getLoggedInUser().subscribe({
            next: (user: User) => {
                if (user) {
                    this.userEmail = user.emailId;
                } else {
                    this.userEmail = '';

                }
            }
        });

    }
    ngOnDestroy(): void {
        if (this.getRestaurantSubs) {
            this.getRestaurantSubs.unsubscribe();
        }
        if (this.userSubs){
            this.userSubs.unsubscribe();
        }
    }
}
