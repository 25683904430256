import { CarryoutComponent } from './carryout.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { InvalidMessagesModule } from 'src/app/core/invalid-messages/invalid-messages.module';
import { OrderDateTimeModule } from '../../order-date-time/order-date-time.module';

@NgModule({
    declarations: [
      CarryoutComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      InvalidMessagesModule,
      OrderDateTimeModule,
      IonicModule.forRoot()
    ],
    exports: [
        CarryoutComponent
    ]
})

export class CarryoutModule { }
