export class Safe {
    static isDefined(s) {
        return ((typeof s) !== 'undefined');
    }

    static del(s) {
        // if ((typeof s) != 'undefined') {
        //     //delete(s);
        // }
    }
    static isNull(s) {
        return ((typeof s) === 'undefined') || (s == null);
    }
    static isEmpty(s) {
        return ((typeof s) === 'undefined') || (s == null) || (s === '') || (s.trim() === '');
    }
    static isEqual(s1, s2) {
        return (Safe.isNull(s1) === false) && (Safe.isNull(s2) === false) && (s1 === s2);
    }
    static function(s) {
        return ((typeof s) !== 'undefined') && (s !== null);
    }
    static notNull(s) {
        return ((typeof s) !== 'undefined') && (s !== null);
    }

}


