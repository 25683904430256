import { Cart } from '../cart/cart.model';
import { RewardItem } from '../restaurant/restaurant.model';
export class Order {
    public static orderTypes = {
        carryout: 'CARRYOUT',
        delivery: 'DELIVERY',
        dinein: 'DINE AT RESTAURANT',
        curbsidePickup: 'CURBSIDE PICKUP',
        driveThruPickup: 'DRIVE-THRU PICKUP',
        tableOrder: 'TABLE ORDER'
    };
    public static paymentTypes = {
        payAtRestaurant: 'PAY AT RESTAURANT',
        creditCard: 'CREDIT CARD'
    };
    idStr?:string;
    name: string;
    firstName?:string; // Only full name stored in db. Fn and ln are only for the view.
    lastName?: string;
    phone: string;
    emailId: string;
    dueDt: string; // y-M-d h:m a format
    orderDt: Date; // same as dueDt but stored as a date.
    orderType: string;
    paymentType: string;
    tip: number;
    specialInstructions: string;
    orderTotal: number;
    clientTime: number;
    cart: Cart;
    expirationDt: Date;
    sendTextMsg: boolean;
    hrsOfOprName: string;
    deliveryAddress: any;
    deliveryCompanyName: string;
    deliveryDistance: number;
    deliveryTime: number;
    deliveryFee: number;
    selectedCard: any;
    total: number;
    tax: number;
    taxPercent: number;
    clientTzOffset: number;
    restaurantUri: string;
    couponCode: string;
    couponType: string;
    couponValue: number;
    selectedReward?: RewardItem;
    needFlatware?:string;
    paymentMethodId?:string; //stripe payment method id

    constructor() {
        this.cart = new Cart();
    }
    static  getOrderTypeGenre(orderType: string): string{
        let genre=orderType;
        switch (orderType){
            case Order.orderTypes.carryout:
            case Order.orderTypes.curbsidePickup:
            case Order.orderTypes.driveThruPickup:
                genre = 'Pickup';
                break;
            case Order.orderTypes.delivery:
                genre = 'Delivery';
                break;
            case Order.orderTypes.dinein:
                genre = 'Dine-in';
                break;
            case Order.orderTypes.tableOrder:
                genre = 'Table Order'
        }
        return genre;
    }
    get orderTypeGenre(): string {
        return Order.getOrderTypeGenre(this.orderType);
    }
    static  getOrderTypeFriendly(orderType: string): string{
        let friendly=orderType;
        switch (orderType){
            case Order.orderTypes.carryout:
                friendly = 'Pickup';
                break;
            case Order.orderTypes.curbsidePickup:
                friendly = 'Curbside Pickup';
                break;
            case Order.orderTypes.driveThruPickup:
                break;
            case Order.orderTypes.delivery:
                friendly = 'Delivery';
                break;
            case Order.orderTypes.dinein:
                friendly = 'Dine-in';
                break;
            case Order.orderTypes.tableOrder:
                friendly = 'Table Order';
                break;
        }
        return friendly;
    }
    get orderTypeFriendly(): string {
        return Order.getOrderTypeFriendly(this.orderType);
    }
}
