import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FdlLogoComponent } from './fdl-logo.component'



@NgModule({
  declarations: [FdlLogoComponent],
  imports: [
    CommonModule,
    IonicModule.forRoot(),

  ],
  exports:[
    FdlLogoComponent
  ]
})
export class FdlLogoModule { }
