import { Injectable } from '@angular/core';
import * as Moment from 'moment';
import {DateTime} from 'luxon';
import { Restaurant } from '../restaurant/restaurant.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order } from './order.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestaurantService } from '../restaurant/restaurant.service';
import { Cart, CartItem, ItemOption, OptionResponse } from '../cart/cart.model';
import { find, mapTo, tap } from 'rxjs/operators';
import { StorageService } from '../../core/storage.service';
import { environment } from '../../../environments/environment';
import { User } from '../account/user.model'
import { MenuService } from '../menu/menu.service';
import { TableOrder } from '../order-from-table/table-order.model';
import { RewardService } from '../reward/reward.service'
import { RewardAccount } from '../reward/reward.model';


@Injectable({
  providedIn: 'root'
})

export class OrderService {
  private baseUrl: string;
  private static GuestCheckoutKey = 'foodalot:orders';
  private order: BehaviorSubject<Order> = new BehaviorSubject<Order>(null);
  private guestCheckoutOrders: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private restaurant: Restaurant;
  private rewardAccount: RewardAccount;
  //private lastOrder: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private lastOrder:any;
  private orderContext: any; //Used by My Orders page;

  // tslint:disable-next-line: variable-name
  private _totalSteps: number;
  public EXPIRATION_MINS=40;

  constructor(
    private http: HttpClient,
    restaurantService: RestaurantService,
    private storageService: StorageService,
    private menuService: MenuService,
    rewardService: RewardService
  ) {
    this.baseUrl = environment.baseUrl;
    this.guestCheckoutOrders.next(null);

    restaurantService.getRestaurant().subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;
        //        this.clearStorage();
        // if (restaurant) {
        //   this.initLastOrder();
        // }
      }
    });
    rewardService.getRewardAccount().subscribe({
      next: (rewardAccount: RewardAccount)=>{
        this.rewardAccount=rewardAccount;
      }
    });

  }
  public get totalSteps(): number {
    return this._totalSteps;
  }
  public set totalSteps(value: number) {
    this._totalSteps = value;
  }
  // initLastOrder() {
  //   const key = this.makeKey('lastOrder');
  //   this.storageService.get2(key).then((v) => {
  //     this.lastOrder.next(v);
  //   }).catch((err) => {
  //     this.lastOrder.next(null);
  //   });

  // }

   public getLastOrder(): Promise<any> {
     if (this.lastOrder){
       return new Promise<any>((resolve, reject)=>{
         resolve({value:this.lastOrder});
       })
     }else{
      return this.storageService.get('lastOrder');
     }
  }

  public getOrder(): Observable<Order> {
    let o: Order = this.order.value;

    if ((o) && (o.restaurantUri !== this.restaurant.uri)) {
      o = null;
      this.order.next(null);
    }
    if (o) {
      this.expireIfNeeded();
      o = this.order.value;
      if (o !== this.order.value) {
        this.order.next(o);
      }
    } else {
      this.storageService.get(this.makeKey(':order'))
        .then((order: any) => {
          if (order) {
            const ord: Order = order as Order;
            ord.orderDt=Moment(order.orderDt).toDate();
            ord.cart = new Cart(order.cart);
            this.order.next(ord);
            this.expireIfNeeded();
          }
        });
    }
    return this.order;
  }

  public expireIfNeeded(): void {
    if (this.order.value === null) {
      return;
    }

    const expirationDt = Moment(this.order.value.expirationDt);

    if (Moment().isAfter(expirationDt)) {// empty the cart.
      this.storageService.set(this.makeKey(':order'), null)
        .then(() => {
          this.order.next(null);
        });
    }
  }
  private makeKey(suffix: string): string {
    return this.restaurant.uri + suffix;
  }

  startNewOrder(o: Order): void {
    o.expirationDt = Moment().add(this.EXPIRATION_MINS, 'm').toDate();
    o.selectedReward=undefined;
    o.restaurantUri = this.restaurant.uri;
    this.storageService.set(this.makeKey(':order'), o).then(() => {
      this.order.next(o);
    });
  }
  submitOrder(authKey: string, user: any): Observable<any> {
    const o: Order = this.order.value;
 
    const httpOptions = {
      headers: new HttpHeaders({
        emailId: (user) ? user.emailId : o.emailId,
        authKey: authKey
      })
    };

    o.dueDt = Moment(o.orderDt).format('Y-MM-DD hh:mm A');
    const now = new Date();
    o.clientTime = now.getTime();
    o.clientTzOffset = now.getTimezoneOffset();
    const params: any = {};
    params.uri = o.restaurantUri;
    if (user) {
      params.userId = user.idStr;
    }
    if (o.orderType==='TABLE ORDER'){
      const tableOrder=o as TableOrder;
      if (!o.specialInstructions){
        o.specialInstructions='';
      }
      o.specialInstructions+='; '+ tableOrder.section+' '+tableOrder.table;
    }
    if ( (this.rewardAccount?.mobileNo) &&
      (this.rewardAccount?.restUri) && 
      (this.rewardAccount?.restUri===this.restaurant.uri)){
        params.rewardMobileNo=this.rewardAccount.mobileNo;
      }
  

    params.order = o;
    return this.http.post(
      this.baseUrl + 'order/mobisubmit',
      params,
      httpOptions
    ).pipe(
      tap((data: any) => {
        if (data.code === 0) { // Success
          this.lastOrder=data.retObj;
          this.lastOrder.restaurant=this.restaurant;
          this.storageService.set2('lastOrder',
            this.lastOrder, '24:00"00' // last order lives for 2 hours.
          );
//          this.lastOrder.next(data.retObj);
          this.order.next(null);
          this.storageService.remove(this.makeKey(':order'));
          if (!user) { // if Guest Checkout
            this.storageService.get2(OrderService.GuestCheckoutKey).then((ordersObj) => {
              var orders: Order[];
              if (ordersObj) { // Allready 1 or more existing orders in the past.
                orders = ordersObj as Order[];
              } else {
                orders = [];
                // Guest checkout orders will exist only for 24 hours.
                //this.storageService.set2(OrderService.GuestCheckoutKey, orders, '23:59:59');

              }
              let o = data.retObj;
              o.restaurant = this.restaurant;
              orders.push(o);
              this.guestCheckoutOrders.next(orders);
              // Guest checkout orders will exist only for 24 hours.
              this.storageService.remove(OrderService.GuestCheckoutKey).then(() => {
                this.storageService.set2(OrderService.GuestCheckoutKey, orders, '23:59:59');
              })
            })

          }
        }
      }));
  }

  getAllOrders(user: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        emailId: user.emailId,
        authKey: user.authKey
      })
    };

    return this.http.post(
      this.baseUrl + 'order/mobimyorders',
      { userId: user.idStr },
      httpOptions);
  }
  getGuestCheckoutOrders(): Observable<any>  {
  //  return this.storageService.get2(OrderService.GuestCheckoutKey);
    let orders = this.guestCheckoutOrders.value;
    if (!orders){
      this.storageService.get2(OrderService.GuestCheckoutKey)
        .then((ords: any) => {
          if (ords) {
            this.guestCheckoutOrders.next(ords);
          }
        });
    }
    return this.guestCheckoutOrders;

  }

  public getOrderDateList(rest:Restaurant): any {
    const daysInAdvance=(rest.orderDaysInAdvance)?rest.orderDaysInAdvance:2;
    const orderDatesStrList:any = [];
    const today = Moment();
    const tomorrow = Moment().add(1, 'days');
    let mdt = Moment();
    if (daysInAdvance==-1){ //Preorder logic
      for(let regHr of rest.regularHours){
        const startTime=Moment(regHr.startTime); //starttime should be a valid future date.
        if (startTime.isAfter(mdt)){
          const item: any = {};
          item.dt = startTime.startOf('day').toDate();
          item.dtStr = startTime.format('ddd, MMM Do, YYYY');
          orderDatesStrList.push(item);
        }
      }

    }else{
      for (let i = 0; i < daysInAdvance; ++i) {
        let dtStr = '';
        if (mdt.isSame(today, 'day')) {
          dtStr = 'Today, ' + mdt.format('MMM Do, YYYY');
        } else if (mdt.isSame(tomorrow, 'day')) {
          dtStr = 'Tomorrow, ' + mdt.format('MMM Do, YYYY');
        } else {
          dtStr = mdt.format('ddd, MMM Do, YYYY');
        }
        const item: any = {};
        item.dt = mdt.startOf('day').toDate();
        item.dtStr = dtStr;

        orderDatesStrList.push(item);
        mdt = mdt.add(1, 'days');
      }
    }
    return orderDatesStrList;
  }
  getAllOrderSlots(uri, orderDate) {
    const params: any = {};
    params.uri = uri;
    params.slotDate = orderDate;
    params.cutoffTimeMins = 0;
    return this.http.post(this.baseUrl + 'order/getallorderslots', params);
  }
  getOrderSlotsBySession() {
    let o=this.order.value;
    const params: any = {};
    params.uri = this.restaurant.uri;
    params.slotDate = o.orderDt;
    params.hrsOfOprName=o.hrsOfOprName;
    return this.http.post(this.baseUrl + 'order/getorderslotsbysession', params);
  }

  public addToCart(item: CartItem): void {
    const o: Order = this.order.value;

    o.cart.addItem(item);
    this.storageService.set(this.makeKey(':order'), o);
    this.order.next(o);
  }

  public clearCart() {
    this.storageService.set(this.makeKey(':order'), null);
    this.order.next(null);
  }

  public deleteCartItem(i: number): Promise<void> {
    let o = this.order.value;
    o.cart.removeItem(i);
    if (o.cart.items.length === 0) {
      o = null;
    }
    this.order.next(o);

    return this.storageService.set(this.makeKey(':order'), o);
  }
  public changeQty(i: number, newQty: number): void {
    const o = this.order.value;
    const item = o.cart.items[i];
    item.qty = newQty;
    o.cart.updateSubTotal(i);
    this.storageService.set(this.makeKey(':order'), o);
    this.order.next(o);
  }
  public updateOrder(order): Promise<void> {
    let ord = order;
    if (!(ord instanceof Order)) {
      ord = new Order();
      Object.assign(ord, order);
    }
    this.order.next(ord);
    return this.storageService.set(this.makeKey(':order'), ord);
  }

  public buzz(restaurant: any, sender: string, message: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        authKey: 'e44031c4-c4bc-436f-89ff-c736e925895a'
      })
    };

    return this.http.post(
      this.baseUrl + 'restaurant/buzz',
      {
        uri: restaurant.uri,
        sender,
        message
      },
      httpOptions);
  }
  public checkin(restaurant: any, order: any, locationInfo: string): Observable<any> {
    const httpOptions = {};

    return this.http.post(
      this.baseUrl + 'order/checkin',
      {
        idStr: restaurant.idStr,
        uri: restaurant.uri,
        locationInfo,
        orderNo: order.orderNo,
        orderIdStr: order.idStr
      },
      httpOptions);
  }

  public saveGuestCheckoutOrders(user: User, orderIds: string[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        emailId: user.emailId,
        authKey: user.authKey
      })
    };
    const params: any = {};
    params.orderIds = orderIds;


    return this.http.post(
      this.baseUrl + 'order/mobisaveguestcheckoutorders',
      params,
      httpOptions).pipe(
        tap((data: any) => {
          if (data.code === 0) {
            this.storageService.remove(OrderService.GuestCheckoutKey);
          }

        })
      );
  }
  public getOrderConext(): any {
    return this.orderContext;
  }
  public setOrderContext(orderContext: any): void {
    this.orderContext = orderContext;
  }
  private findMenuItem(menus, itemName): any {
    let result: any = null;

    for (let menu of menus) {
      for (let category of menu.categories) {
        const menuItem = category.menuItems.find((menuItem: any) => {
          return menuItem.name === itemName;
        });
        if (menuItem) {
          result = {};
          result.category=category;
          result.menuItem = menuItem;
          return result;
        }
      }
    }
    return result;
  }
  /**
   * This method concats category options and menuitem options. 
   * Bsides, adjusts for exculded and included cat options.
   */
  private buildAllOptions(data): any[] {
    let allOptions = [];
    const category = data.category;
    const menuItem = data.menuItem;
    if (category?.options) {
      for (let opt of category.options) {
        if ((menuItem.excludedCatOptions) && (
          menuItem.excludedCatOptions.indexOf(opt.name) === -1)
        ) {
          allOptions.push(opt);

        } else if ((menuItem.includedCatOptions) && (
          menuItem.includedCatOptions.indexOf(opt.name) > -1
        )) {
          allOptions.push(opt)
        } else {
          allOptions.push(opt);
        }
      }
    }
    if (menuItem.options) {
      for (let opt of menuItem.options) {
        allOptions.push(opt);
      }
    }
    return allOptions;
  }
  private compWithCurrentMenu(menus, cartItem: CartItem): boolean {
    let menuItem = null;
    let category = null;

    const data = this.findMenuItem(menus, cartItem.name);
    if (data) {
      menuItem = data.menuItem;
      category = data.category;
    } else { //Item Can not be located in menu. Posibily removed or inactive.
      return false;
    }
    const allOptions = this.buildAllOptions(data);
    let cartItemOpt = null;
    for (let opt of allOptions) {
      cartItemOpt = cartItem.options.find((cartItemOpt) => {
        return cartItemOpt.name === opt.name;
      });
      if (!cartItemOpt) {
        return false;
      }

      for (let cartItemOptResp of cartItemOpt.responses) {
        let optResp = opt.responses.find((rsp) => {
          return rsp.text == cartItemOptResp.text;
        });
        if (!optResp) {
          return false;
        }
        //Menu item and options price override history prices.
        cartItemOptResp.extraCost = optResp.extraCost;
        
      }
    }
    cartItem.price = menuItem.price;
    return true;
  }
  // Eliminates menus that are not availabe in the current hour
  // Example. Past order is a lunch item. Guest repeating in dinner hours. Lunch menu has to be removed.
  private filterNotAvailableMenus(allMenus, order): any[] {
    let menus = [];
    for (let menu of allMenus) {
      const good = (menu.availableHours.find((hr) => {
        return hr === order.hrsOfOprName;
      }) != null);
      if (good) {
        menus.push(menu);

      }
    }
    return menus;
  }
  public repeatOrder(srcOrder: any): Promise<any> {
    const order = this.order.getValue();
    try {
      const order = this.order.getValue();
      if (order == null) {
        return new Promise<any>((resolve, reject) => {
          reject({ code: 1, errMsg: 'Can not repeat. Please start an order first and try again.' });
        });
      }
      if ((order?.cart?.items) && (order?.cart?.items.length > 0)) {
        return new Promise<any>((resolve, reject) => {
          reject({ code: 2, errMsg: 'Can not repeat. Plesae startover and try again.' });
        });

      }
    } catch (err) {
      return new Promise<any>((resolve, reject) => {
        reject({ code: 99, errMsg: 'Error' + JSON.stringify(err) });
      });

    }
    let menus=null;
    return new Promise<any>((resolve, reject) => {

      this.menuService.getActiveMenus(this.restaurant.uri).subscribe((allMenus: any) => {
        
          let result = true;
          if ( (!allMenus) || (menus)){
            return;
          }
          menus = this.filterNotAvailableMenus(allMenus, order);
   
          for (let srcItem of srcOrder.orderItems) {
            const cartItem: CartItem = new CartItem();
            cartItem.code = srcItem.code;
            cartItem.name = srcItem.name;
            cartItem.personName = srcItem.personName;
            cartItem.notes = srcItem.notes;
            cartItem.price = srcItem.price;
            cartItem.qty = srcItem.qty;
            cartItem.menuName = srcItem.menuName;
            cartItem.catName = srcItem.catName;
            cartItem.sortOrder = srcItem.sortOrder;
            cartItem.volumeInOz = srcItem.volumeInOz;
            cartItem.alcoholic = srcItem.alcoholic;
            cartItem.sortOrder = srcItem.sortOrder;
            cartItem.remind = false;
            cartItem.options = [];
            for (let srcItemOption of srcItem.options) {
              const cartItemOption = new ItemOption();
              cartItemOption.id = srcItemOption.id;
              cartItemOption.name = srcItemOption.name;
              cartItemOption.type = srcItemOption.type;
              cartItemOption.responses = [];
              for (let srcResp of srcItemOption.responses) {
                const cartItemOptionResponse = new OptionResponse();
                cartItemOptionResponse.text = srcResp.text;
                cartItemOptionResponse.selected = srcResp.selected;
                cartItemOptionResponse.extraCost = srcResp.extraCost;
                cartItemOption.responses.push(cartItemOptionResponse);
              }
              cartItem.options.push(cartItemOption);
            }
            if (this.compWithCurrentMenu(menus, cartItem)) {
              this.addToCart(cartItem);
            } else {
              result = false;
            }
          }
          resolve(result);

      });

    });;
  }
  deleteTableOrderItem(batchIndex, personIndex, itemIdex){
    let o:TableOrder = this.order.value as TableOrder;
    let batch=o.batches[batchIndex];
    let person=batch.people[personIndex];
    
    let item=person.items[itemIdex];
    let cartItemIndex=o.cart.items.indexOf(item);
    o.cart.removeItem(cartItemIndex);
    person.items.splice(itemIdex, 1);
    this.order.next(o);
    return this.storageService.set(this.makeKey(':order'), o);
  }
  adjustOrderDt():boolean{
    let adjusted=false;
    const o=this.order.value;
    if ((o)&&(o.orderDt)&&(this.restaurant?.waitTime)){
      let dueDt=DateTime.fromJSDate(o.orderDt);
      let nowPlusWaitTime=DateTime.now().plus({minutes:this.restaurant.waitTime});
      if(dueDt.diff(nowPlusWaitTime).toMillis()<0){
        //Adjust the time
        o.orderDt=nowPlusWaitTime.toJSDate();
        this.updateOrder(o);
        adjusted=true;
      }
    }
    return adjusted;

  }
  public sendFeedback(orderId: string, stars: number, comment): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        authKey: 'e44031c4-c4bc-436f-89ff-c736e925895a'
      })
    };

    return this.http.post(
      this.baseUrl + 'order/mobisendfeedback',
      {
        stars,
        comment,
        orderId
      },
      httpOptions).toPromise();
  }


}
