<ion-card  class="ion-no-padding ion-no-margin" *ngIf="tableOrder.state === thisState">
  <ion-card-subtitle>Pay & Finish</ion-card-subtitle>
  <ion-card-content>
    <ion-list>
      <ion-item>
        <ion-label>Payment Options</ion-label>
        <p>Apple Pay, Google Pay, Credit card or Request for check</p>
      </ion-item>
      <ion-item>
        <ion-label>Tip Amount</ion-label>
        <p>Ability to add a tip amount.</p>
      </ion-item>
      <ion-item>
        <ion-label>Tax</ion-label>
        <p>Sales tax will be calculated and added as well.</p>
      </ion-item>
      <ion-item>
        <h3>Order Total</h3>
        <ion-label color="damger" slot="end">{{tableOrder.orderTotal |currency:'USD'}}</ion-label>
      </ion-item>
    </ion-list>
    <div class="ion-margin-top ion-text-end" *ngIf="!submited"> 
      <ion-button (click)="finish()">Request Check & Finish<ion-icon slot="end" name="arrow-dropright"></ion-icon> </ion-button>
    </div>
    <div class="ion-margin-top ion-text-end" *ngIf="submited"> 
      <h3>THANK YOU!</h3>
      <ion-button [routerLink]="['/']" routerLinkActive="router-link-active" >Close<ion-icon slot="end" name="arrow-dropright"></ion-icon> </ion-button>

    </div>

  
  </ion-card-content>
</ion-card>
<ion-card  class="ion-no-padding ion-no-margin" *ngIf="tableOrder.state < thisState">
  <ion-card-subtitle>Pay & Finish</ion-card-subtitle>
  <ion-card-content>
    <div>
      <ion-skeleton-text animated="false"></ion-skeleton-text>
      <ion-skeleton-text animated="false" style="width: 88%"></ion-skeleton-text>
      <ion-skeleton-text animated="false" style="width: 70%"></ion-skeleton-text>
      <ion-skeleton-text animated="false" style="width: 60%"></ion-skeleton-text>
    </div>    
  </ion-card-content>
</ion-card>
