<ion-header>  
  <ion-toolbar color="primary">
  <ion-buttons slot="start">
    <ion-back-button defaultHref="restaurant-list"></ion-back-button>
  </ion-buttons>
    
  <ion-title>
    FoodALot SignUp
  </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <ion-grid>
    <form [formGroup]= "signuppageForm">
      <ion-row *ngIf="environment.customApp">
        <ion-col><app-fdl-logo></app-fdl-logo></ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <h6>Sign Up for a FoodALot account and order from restaurants around. </h6>
        </ion-col>
       </ion-row>
       

       <ion-row>
        <ion-col>
          <ion-item class="no-padding">
            <ion-label position="floating">Your Email Address</ion-label> 
            <ion-input formControlName="emailId" type="email" (change)="emailIdChanged()"></ion-input>
          </ion-item>

          <div class="error-messages">
            <ng-container *ngFor="let error of error_messages.emailId">
              <div class="error-message" 
              *ngIf="signuppageForm.get('emailId').hasError(error.type) && signuppageForm.get('emailId').touched">
              {{error.message}}
              </div> 
            </ng-container>
          </div>
      
          <ion-item class="no-padding" *ngIf=emailIdAlreadyExists>
            <ion-text color="danger">An account already exists with this email id. Please go back and Login or Reset Password</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" size-sm="6" size-md="4">
          <ion-item class="no-padding">
            <ion-label position="floating">First Name</ion-label>
            <ion-input formControlName="firstName" type="text"></ion-input>
          </ion-item>
          
          <div class="error-messages">
            <ng-container *ngFor="let error of error_messages.firstName">
              <div class="error-message" 
              *ngIf="signuppageForm.get('firstName').hasError(error.type) && (signuppageForm.get('firstName').dirty || signuppageForm.get('firstName').touched)">
              {{error.message}}
              </div> 
            </ng-container>
          </div>
        </ion-col>
  
        <ion-col size="12" size-sm="6" size-md="4">
          <ion-item class="no-padding">
            <ion-label position="floating">Last Name</ion-label>
            <ion-input formControlName="lastName" type="text"></ion-input>
          </ion-item>
    
          <div class="error-messages">
            <ng-container 
              *ngFor="let error of error_messages.lastName">
              <div class="error-message" 
              *ngIf="signuppageForm.get('lastName').hasError(error.type) && (signuppageForm.get('lastName').dirty || signuppageForm.get('lastName').touched)">
              {{error.message}}
           </div> 
            </ng-container>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-item class="no-padding">
            <ion-label position="floating">Password</ion-label>
            <ion-input formControlName="password" type="password"></ion-input>
          </ion-item>
      
          <div class="error-messages">
            <ng-container *ngFor="let error of error_messages.password">
              <div class="error-message" 
              *ngIf="signuppageForm.get('password').hasError(error.type) && (signuppageForm.get('password').dirty || signuppageForm.get('password').touched)">
              {{error.message}}
              </div> 
            </ng-container>
          </div>
        </ion-col>
      </ion-row>
          
      <ion-row>
        <ion-col offset-sm="3" size-sm="6">
          <ion-button color="primary" expand="block" (click)="signup()">SIGNUP</ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>
