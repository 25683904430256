import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user.model';
import { UIHelpers } from '../../../core/uihelpers';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-account-tab',
  templateUrl: './account-tab.page.html',
  styleUrls: ['./account-tab.page.scss'],
})
export class AccountTabPage implements OnInit {
  user: User;
  environment;
    constructor(
    private userService: UserService,
    private uiHelpers: UIHelpers,
    private router: Router
  ) { 
    this.environment=environment;
  }

  ngOnInit() {
    this.userService.getLoggedInUser().subscribe ( {
      next: (user) => {
        this.user = user;
      }
    });
  }
logout() {
  this.userService.logout().then(() => {
    this.uiHelpers.showSuccessMsg('Logout Success!');
  }).catch((msg) => {
    this.uiHelpers.showErr('Error in logout' + msg);
  });

}
signup(){
  this.userService.setCallerUrl('/landing-page/account-tab');
  this.router.navigateByUrl('/signup');
}
login(){
  this.userService.setCallerUrl('/landing-page/account-tab');
  this.router.navigateByUrl('/login');
}

delete(){
  this.userService.delete().then(()=>{
    this.uiHelpers.showSuccessMsg('Your Account Deleted!');
  }).catch((msg) => {
    this.uiHelpers.showErr('Error in logout' + msg);
  });
}

async presentAlertConfirm() {
  this.uiHelpers.confirm(
    'Delete Confirmation',
    '\
    <h5>You are about to delete your FoodALot account.</h5>\
    <h5>Are you sure?</h5>\
    ',
    () => {
      this.delete();
     }
  );
}
}
