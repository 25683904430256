import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { NavController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  submitAttempt: boolean;
  formGroup: FormGroup;
  passwordToggle: boolean;
  lastErr: string;
  navTo: any;
  toUrl: string;
  environment: any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public navCtrl: NavController,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.environment=environment;
   }

  ngOnInit() {
    this.submitAttempt = false;
    this.passwordToggle = true;
    this.lastErr = null;
    this.formGroup = this.formBuilder.group({
      emailId: [ '', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
      keepMeLoggedIn: [true]
    }
    );
    this.toUrl = this.userService.getCallerUrl();
  }
  login() {
    this.lastErr = null;
    this.submitAttempt = true;
    if (this.formGroup.valid) {
      this.userService.login(this.formGroup.value).then(
        (activationRequired: boolean) => {
          this.navCtrl.pop().then( () => {
          if (activationRequired) {
            this.router.navigate(['activateaccount'], {
              state: {
                emailId: this.formGroup.value.emailId
              }
            });
          } else {
              if (this.toUrl) {
                this.router.navigateByUrl(this.toUrl);
              }
          }
        });

        }).catch((err) => {
          this.lastErr = err;
        }
        );
}
}
signup(){
  this.navCtrl.pop().then(() => {
    this.navCtrl.navigateForward('/signup');

  });
}

resetPwd(){
  this.navCtrl.pop().then(() => {
    this.navCtrl.navigateForward('/resetpwd');

  });
}
}
