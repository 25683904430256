import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { OrderTypeInterface } from '../order-type.interface';
import { Order } from '../../order.model';
import { Restaurant } from 'src/app/features/restaurant/restaurant.model';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestaurantService } from 'src/app/features/restaurant/restaurant.service';
import { OrderService } from '../../order.service';
import * as Moment from 'moment';
import { UIHelpers } from 'src/app/core/uihelpers';

@Component({
  selector: 'app-no-wait-dine-in',
  templateUrl: './no-wait-dine-in.component.html',
  styleUrls: ['./no-wait-dine-in.component.scss'],
})
export class NoWaitDineInComponent
implements OnInit, OnDestroy, OrderTypeInterface  {

  private getRestaurantSubs: Subscription;
  orderDateList: any;
  orderSlots: any;

  formGroup: FormGroup;

  validationMessages: any;

  restaurant: Restaurant;

  constructor(
    private formBuilder: FormBuilder,
    private restaurantService: RestaurantService,
    private orderService: OrderService,
    private uiHelpers: UIHelpers
  ) {
   }
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      orderDate: [null, Validators.required],
      orderTime: [null, Validators.required]
    }
    );
    this.validationMessages = {
      orderDate: [
        {type: 'required', message: 'Pickup date is required.'}
      ],
      orderTime: [
        {
          type: 'required',
          message: 'Online ordering is not available on the selected day.  You can only cancel out of this screen.'
        }
      ],
     };

    this.getRestaurantSubs = this.restaurantService.getRestaurant().subscribe({
      next: (restaurant: Restaurant) => {
        this.restaurant = restaurant;
        this.orderDateList = this.orderService.getOrderDateList(this.restaurant);
        if ( (this.orderDateList) && (this.orderDateList.length > 0) ) {
          this.formGroup.controls.orderDate.setValue(this.orderDateList[0].dt);
          this.orderDateChange();
        }
      }, error: (err) => {
        this.uiHelpers.showErr(err.toString());
        throw err;
      }, complete: () => {
        throw Error('test');
      }
    });
  }
  ngOnDestroy(): void {
    if (this.getRestaurantSubs) {
      this.getRestaurantSubs.unsubscribe();
    }
  }
  canStart(): boolean {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }
  initOrder(): Order {
    const order: Order = new Order();
    order.orderType = 'NO WAIT DINE-IN';
    order.orderDt = this.deriveOrderDt(
      this.formGroup.controls.orderDate.value,
      this.formGroup.controls.orderTime.value
      );
    order.hrsOfOprName = this.formGroup.controls.orderTime.value.hrsOfOprName;
    return order;

  }
  private deriveOrderDt(orderDate, orderTime): Date {
    const orderDt = Moment(orderDate);
    const tm = new Date(orderTime.slotTime);
    orderDt.hour(tm.getHours());
    orderDt.minute(tm.getMinutes());
    orderDt.second(0);
    orderDt.millisecond(0);
    return orderDt.toDate();
  }

  get timeSlotAvailable(): boolean {
    let available = true;
    if (this.orderSlots) {
      available = (this.orderSlots.length > 0);
    }
    return available;
  }

  orderDateChange() {
    this.orderService.getAllOrderSlots(this.restaurant.uri, this.formGroup.controls.orderDate.value)
      .subscribe({
        next: (data: any) => {
          const slots: any = data.retObj;
          this.orderSlots = [];
          for (const slt of slots) {
            if (slt.available) {
              this.orderSlots.push(slt);
            }
          }
          let slot = null;
          if (this.timeSlotAvailable) {
            // To sort the Time slots
            this.orderSlots = this.orderSlots.sort((a: any, b: any) =>
              (a.slotTime) - b.slotTime);
            slot = this.orderSlots[0];
          }
          setTimeout(() => {
            this.formGroup.controls.orderTime.setValue(slot);
            this.formGroup.markAllAsTouched();
          }, 0);
        }
      });
  }
}
