import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OrderService } from '../order.service';
import { SubscriptionMgr } from 'src/app/core/subscription-mgr';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { UIHelpers } from 'src/app/core/uihelpers';
import { StringUtil } from 'src/app/core/stringutils';
import { DateTime } from 'luxon';
import { Order } from '../../order/order.model'

@Component({
  selector: 'app-order-date-time',
  templateUrl: './order-date-time.component.html',
  styleUrls: ['./order-date-time.component.scss'],
})
export class OrderDateTimeComponent implements OnInit {

  @Input()
  formGroup: FormGroup; // Form group is passed from the parent
  @Input()
  set orderType(orderType: string){
    this.savedOrderType = orderType;
    this.orderTypeLabel = Order.getOrderTypeFriendly(orderType);
  }

  get orderType(): string{
    return this.savedOrderType;

  }
  savedOrderType: string;
  orderTypeLabel: string; // Used as prefix to labels of date and time controls.
  validationMessages: any; // Msg array needed by our component
  orderDateList: any; // Dates shown in the date dropdown
  orderSlots: any; // Slots shwon in the time dropdown
  restaurant: Restaurant;


  constructor(
    private orderService: OrderService,
    private subscriptionMgr: SubscriptionMgr,
    private restaurantService: RestaurantService,
    private uiHelpers: UIHelpers,
    private stringUtil: StringUtil

  ) {
    this.validationMessages = {
      orderDate: [
        {
          type: 'required',
          message: this.orderType + ' date is required.'
        }
      ],
      orderTime: [
        {
          type: 'required',
          message: 'Online ordering is not available in the selected day.  You can only cancel out of this screen.'
        }
      ],
    };
  }

  ngOnInit() {
    this.formGroup.addControl('orderDate', new FormControl(null, Validators.required));
    this.formGroup.addControl('orderTime', new FormControl(null, Validators.required));
  
    

    this.subscriptionMgr.putSubscription('rest',
      this.restaurantService.getRestaurant().subscribe({
        next: (restaurant: Restaurant) => {
          this.restaurant = restaurant;
          if (this.restaurant){
            this.orderDateList = this.orderService.getOrderDateList(this.restaurant);
            if ( (this.orderDateList) && (this.orderDateList.length > 0) ) {
              this.formGroup.controls.orderDate.setValue(this.orderDateList[0].dt);
              this.orderDateChange();
            }
            }
        }, error: (err) => {
          this.uiHelpers.showErr(err.toString());
        }, complete: () => {
        }
      }
      ));
  }
  get timeSlotAvailable(): boolean {
    let available = true;
    if (this.orderSlots) {
      available = (this.orderSlots.length > 0);
    }
    return available;
  }

  orderDateChange() {
    this.orderService.getAllOrderSlots(this.restaurant.uri, this.formGroup.controls.orderDate.value)
      .subscribe({
        next: (data: any) => {
          const slots: any = data.retObj;
          this.orderSlots = [];
          for (const slt of slots) {
            if (slt.available) {
              this.orderSlots.push(slt);
            }
          }
          let slot = null;
          if (this.timeSlotAvailable) {
            // To sort the Time slots
            this.orderSlots = this.orderSlots.sort((a: any, b: any) =>
              (a.slotTime) - b.slotTime);
            let topSlot=this.orderSlots[0];
            if (!topSlot.firstSlot){
              let slotTime=DateTime.fromMillis(topSlot.slotTime);

              let nowTime=DateTime.now().set({
                year:slotTime.get('year'),
                month: slotTime.get('month'),
                day: slotTime.get('day')});
              let waitTimeMs=this.restaurant.waitTime*60000;
              let timeDiff=slotTime.diff(nowTime).toMillis();

              if ( (timeDiff>waitTimeMs) && (timeDiff<(waitTimeMs+15*60000))){
                //Add a new slot with ASAP time.
                let newSlot:any={};
                Object.assign(newSlot, topSlot);
                newSlot.slotTime=nowTime.plus({milliseconds:waitTimeMs}).toMillis();
                this.orderSlots.unshift(newSlot);
              }
            }
            slot = this.orderSlots[0];

          }
          setTimeout(() => {
            this.formGroup.controls.orderTime.setValue(slot);
            this.formGroup.markAllAsTouched();
          }, 0);
        }
      });
  }


}
