import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { Subscription, timer } from 'rxjs';
import { UIHelpers } from '../../../core/uihelpers';
import { RewardAccount } from '../reward.model';
import { RewardService } from '../reward.service';
import { ShowQRComponent } from '../show-qr/show-qr.component';

import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.page.html',
  styleUrls: ['./reward.page.scss'],
})
export class RewardPage implements OnInit, OnDestroy{
  rewardAccount: RewardAccount;
  subsRewardService: Subscription;
  restaurant: Restaurant;
  subsRestaurant: Subscription;



  constructor(
    private rewardService: RewardService,
    private menuController: MenuController,
    private uiHelpers:UIHelpers,
    private modalController: ModalController,
    private restaurantService: RestaurantService    

  ) { 
    this.uiHelpers.showConfetti();
  }


  ngOnInit() {

    this.subsRestaurant=this.restaurantService.getRestaurant().subscribe( (restaurant: Restaurant)=>{
      this.restaurant=restaurant;
    });
  
  

  
    this.subsRewardService=this.rewardService.getRewardAccount().subscribe(
      (rewardAccount:RewardAccount)=>{
        this.rewardAccount=rewardAccount;
      }
    );
    this.rewardService.loadAccount();

    

  }
  ngOnDestroy(): void {
    if (this.subsRestaurant){
      this.subsRestaurant.unsubscribe();
    }
    if (this.subsRewardService){
      this.subsRewardService.unsubscribe();
    }
  }

  openToolBarMenu(){
    this.menuController.isOpen('toolBarMenu').then((itsOpen:boolean)=>{
      if (itsOpen){
        this.menuController.enable(false, 'toolBarMenu');
        this.menuController.close('toolBarMenu');

      }else{
        this.menuController.enable(true, 'toolBarMenu');
        this.menuController.open('toolBarMenu');
      }

    })
   


  }
  delete(){
    if (this.rewardAccount==null){
      this.uiHelpers.showErrAutoClose('Error. Reward Account is null');
      return;
    }
    this.rewardService.delete(this.rewardAccount.mobileNo, this.rewardAccount.loginKey).then(()=>{
      this.uiHelpers.showSuccessMsg('Deleted the Reward Account and Rewards')
    })

    this.menuController.enable(false, 'toolBarMenu');
    this.menuController.close('toolBarMenu');
  }

  showQR(){
    this.modalController.create({
      component: ShowQRComponent,
      componentProps: {
        rewardAccount:this.rewardAccount
      }}).then((modal)=>{
        modal.present();
      });
  }
  logout(){
    if (this.rewardAccount==null){
      this.uiHelpers.showErrAutoClose('Error. Reward Account is null');
      return;
    }
    this.rewardService.logout(this.rewardAccount.mobileNo, this.rewardAccount.loginKey).then(()=>{
      this.uiHelpers.showSuccessMsg('Logged out of Rewards Account')
    })
    this.menuController.enable(false, 'toolBarMenu');
    this.menuController.close('toolBarMenu');
  }

  public get availableDiscount():number{
    let discount=0;
    if ( (this.rewardAccount?.availablePoints) &&
        (this.restaurant?.rwdcfg?.pointsToMoney) ){
      discount=this.rewardAccount.availablePoints*this.restaurant.rwdcfg?.pointsToMoney;
      discount=Math.round(discount*100)/100;
    }
    return discount;
  }
  public get pendingPoints():number{
    let points=0;
    if ( (this.rewardAccount.availablePoints) && 
          (this.rewardAccount.points)){
            points=this.rewardAccount.points-this.rewardAccount.availablePoints;
    }
    return points;

  }
  async presentAlertConfirm() {
    this.uiHelpers.confirm(
      'Delete Confirmation',
      '\
      <h5>You are about to delete all the rewards earned so far and reward account.</h5>\
      <h5>This action can not be undone.</h5>\
      <h5>Are you sure?</h5>\
      ',
      () => {
        this.delete();
       }
    );
  }
  
}

