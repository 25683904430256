<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>

    <app-logo></app-logo>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup">
    <ion-item *ngIf="curStep == 1">
      <ion-grid>
        <ion-row>
          <ion-col><h3>Reset Password - Step {{curStep}} of 3</h3></ion-col>
        </ion-row>
        <ion-row>
          <p>Please enter your email id, that was used to sign up with FoodALot.</p>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="no-padding">
              <ion-label position="floating"> Your Email ID</ion-label>
              <ion-input type="email" formControlName="emailId"></ion-input>
            </ion-item>
            <ion-item class="no-padding" *ngIf="formGroup.controls.emailId.invalid  &&  submitAttempt">
              <span class="color-error" *ngIf="formGroup.controls.emailId.errors.required">Email id is required.</span>
              <span class="color-error" *ngIf="formGroup.controls.emailId.errors.email ">Invalid Email Id.</span>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-button (click)="reset()"> Next </ion-button>
        </ion-row>
      </ion-grid>
    </ion-item>

    <ion-item *ngIf="curStep == 2">
      <ion-grid>
        <ion-row>
          <p>An email has been sent to {{formGroup.value.emailId}}, with a security code. Please enter that code here.</p>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="no-padding">
              <ion-label position="floating"> Security code</ion-label>
              <ion-input type="string" formControlName="securityCode"></ion-input>
            </ion-item>
            <ion-item class="no-padding" *ngIf="formGroup.controls.securityCode.invalid  &&  submitAttempt">
                <span class="color-error" *ngIf="formGroup.controls.securityCode.errors.required">Please enter the security code.</span>               
              </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-button (click)="reset()"> Next </ion-button>
        </ion-row>
      </ion-grid>
    </ion-item>

    <ion-item *ngIf="curStep == 3">
      <ion-grid>
        <ion-row>
          <p>Please enter a new Password.</p>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="no-padding">
              <ion-label position="floating"> Password</ion-label>
              <ion-input type="password" formControlName="password"></ion-input>
            </ion-item>
            <ion-item class="no-padding" *ngIf="formGroup.controls.password.invalid  &&  submitAttempt">
              <span class="color-error" *ngIf="formGroup.controls.password.errors.required">Please enter a
                password</span>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item class="no-padding">
              <ion-label position="floating"> ReType Password</ion-label>
              <ion-input type="password" formControlName="password2"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-button (click)="reset()"> Finish </ion-button>
        </ion-row>
      </ion-grid>
    </ion-item>
    <ion-row>
      <ion-col>
        <ion-item class="no-padding" class="text-wrap"  *ngIf="lastErr">
          <span class="color-error">{{lastErr}}</span>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
</ion-content>