import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RestaurantInfoComponent } from './restaurant-info.component';

@NgModule({
    declarations: [
        RestaurantInfoComponent
    ],
    imports: [
      CommonModule,
      IonicModule.forRoot()
    ],
    exports: [
        RestaurantInfoComponent
    ]
  })
 export class RestaurantInfoModule { }
