import { DeliveryComponent } from './delivery.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { InvalidMessagesModule } from 'src/app/core/invalid-messages/invalid-messages.module';

@NgModule({
    declarations: [
        DeliveryComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      InvalidMessagesModule,
      IonicModule.forRoot()
    ],
    exports: [
        DeliveryComponent
    ]
})

export class DeliveryModule { }
