<ion-header *ngIf="isModal">
  <ion-toolbar color="primary">
    <ion-title>Join Rewards</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
<form [formGroup]="formGroup">
<ion-grid>
  <ion-row>
    <ion-col>
      <p>Join {{restaurant?.name}} Rewards Program. Earn {{restaurant?.rwdcfg?.welcomePoints}} Welcome points.</p>
      <p *ngIf="!isModal">Already a rewards member?<ion-button fill="clear" (click)="showLoginPage()">LOGIN</ion-button></p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" size-sm="6" size-md="4" size-lg ="3">
      <ion-item>
        <ion-label position="floating">Mobile No</ion-label>
        <ion-input  formControlName="mobileNo" type="tel" 
        placeholder="513-123-4321"
        [brmasker]="{mask: '000-000-0000'}"
        >
        </ion-input>
      </ion-item>

      <app-invalid-messages
        [control]="formGroup.controls.mobileNo"
        [validationMessages]="validationMessages.mobileNo"
      >
      </app-invalid-messages>
    </ion-col>
    <ion-col size="12" size-sm="6" size-md="4" size-lg ="3">
      <ion-item>
        <ion-label position="floating">Full Name</ion-label>
        <ion-input  formControlName="fullName" type="text" autocomplete="name">
        </ion-input>
      </ion-item>

      <app-invalid-messages
        [control]="formGroup.controls.fullName"
        [validationMessages]="validationMessages.fullName"
      >
      </app-invalid-messages>
    </ion-col>
    <ion-col size="12" size-sm="6" size-md="4" size-lg ="3">
      <ion-item>
        <ion-label position="floating">Birth Month Day</ion-label>
        <ion-input  formControlName="dob" 
          type="text" placeholder="mm/dd" 
          readonly="true"
          (click)="openPicker()" (click)="openPicker">
        </ion-input>
      </ion-item>

      <app-invalid-messages
        [control]="formGroup.controls.dob"
        [validationMessages]="validationMessages.dob"
      >
      </app-invalid-messages>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col offset-sm="3" size-sm="6">
      <ion-button color="primary" expand="block" (click)="join()">Join</ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="isModal">
    <ion-col offset-sm="3" size-sm="6" >
      <ion-button color="secondary" expand="block" (click)="cancel()">Cancel</ion-button>
    </ion-col>
  </ion-row>
  <ion-row >
    <ion-col offset-sm="3" size-sm="6" *ngIf="!isModal">
      <p>Already a rewards member?</p>
      <ion-button color="secondary" expand="block" (click)="showLoginPage()">Login</ion-button>
    </ion-col>
</ion-row>

 </ion-grid>
</form>
</ion-content>