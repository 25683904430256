<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Rewards Login</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
<form [formGroup]="formGroup">

<ion-grid>
  <ion-row>
    <ion-col>
      <p>Login to {{restaurant?.name}} Rewards Program. </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col offset-sm="3" size-sm="6">
      <ion-item>
        <ion-label position="floating">Mobile No</ion-label>
        <ion-input  formControlName="mobileNo" type="tel" 
        placeholder="513-123-4321"
        [brmasker]="{mask: '000-000-0000'}"
        >
        </ion-input>
      </ion-item>

      <app-invalid-messages
        [control]="formGroup.controls.mobileNo"
        [validationMessages]="validationMessages.mobileNo"
      >
      </app-invalid-messages>
    </ion-col>
</ion-row>
<ion-row>
  <ion-col offset-sm="3" size-sm="6">
    <ion-button color="primary" expand="block" (click)="sendOtp()">Send OTP</ion-button>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col offset-sm="3" size-sm="6">
    <ion-button color="secondary" expand="block" (click)="cancel()">Cancel</ion-button>
  </ion-col>
</ion-row>

</ion-grid>
</form>
</ion-content>