<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Menu</ion-title>
    <ion-buttons slot="end"> 
      <ion-button (click)="showSearch = !showSearch"
      slots="icon-only" strong="true">
        <ion-icon name="search-sharp"></ion-icon>
      </ion-button>
      <ion-button (click)="displayCategories($event)"
      slots="icon-only" strong="true">
        <ion-icon name="menu"></ion-icon>
      </ion-button>
    </ion-buttons>        
  </ion-toolbar>
  <ion-searchbar  *ngIf="showSearch" placeholder="Search 3 chars min" showCancelButton=true type="text"
  [(ngModel)]="searchText"  (ionClear)="showSearch = !showSearch"></ion-searchbar>   
</ion-header>
 
<ion-content>

  <ion-segment id="top"></ion-segment>
    <ion-fab horizontal ="end" vertical="bottom" slot="fixed">
        <ion-fab-button size="small" translucent="true" (click)="scrollTo('top')">
          <ion-icon name="chevron-up-outline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    
  <h3 class="ion-padding-start">{{restaurant?.name}}</h3>
  <div class="ion-padding-start">{{restaurant?.address.line1}}, {{restaurant?.address.city}},{{restaurant?.address.state}} - {{restaurant?.address.zip}}</div>

  <ion-segment [(ngModel)]="selectedMenuIndex" class="ion-padding-top" value="0">
    <ion-segment-button value="{{i}}" *ngFor="let menu of menus; let i=index;">
      <ion-label>{{menu.name}}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <section *ngFor="let menu of menus; let i=index;">
    <div *ngIf="selectedMenuIndex==i" >
      <div class="ion-padding"
        style="font-size:larger"
      >{{menu.description}}</div>
      
        
      <section *ngFor="let category of menu.categories;let j=index;" id="cat{{j}}" >
        <ion-grid id="{{j}}" *ngIf="category.show">
          <ion-row>
            <ion-col>
              <ion-item [lines]="(category.description)?'none':'full'">
              <h4>{{category.name}}</h4>
            </ion-item>
            <ion-item lines="full" *ngIf="category.description">
              <p >{{category.description}}</p>
              </ion-item>
            
            </ion-col>
          </ion-row>
          <ion-row *ngIf="category.viewOnly" class="ion-no-padding">
            <ion-col class="ion-no-padding">
              <ion-badge color="default">Not for Online Ordering</ion-badge>
            </ion-col>
          </ion-row>

          <ion-row class="ion-no-padding">
            <ion-col size-xs="12" size-sm="6" size-md="4"
              *ngFor="let menuItem of category.menuItems | searchFilter:searchText" class="ion-no-padding">
              <ion-card class="ion-no-padding" (click)="menuItemClick2(category, menuItem, i,j)">
               
                <img src="{{menuItem.imageUrl}}" *ngIf="menuItem.imageUrl" />
                <ion-card-header>
                  <h4>
                  <span class="ion-float-left"><span *ngIf="menuItem.code">{{menuItem.code}}.</span>{{menuItem.name }}</span>
                  
                  <span class="ion-float-right" 
                    *ngIf="!menuItem.priceText && !menuItem.origPrice">{{menuItem.price| currency:'USD'}}</span>
                  
                    <span class="ion-float-right" *ngIf="!menuItem.priceText && menuItem.origPrice">
                      <ion-text color="danger">
                        <s>{{menuItem.origPrice| currency:'USD'}}</s>&nbsp;{{menuItem.price| currency:'USD'}}
                      </ion-text>
                    </span>
                  
                    <div class="ion-float-right" *ngIf="menuItem.priceText">{{menuItem.priceText}}</div>
                  </h4>                  
                </ion-card-header>
                <!--           <ion-card-header><ion-grid><ion-row><ion-col col-8><span *ngIf="menuItem.code">{{menuItem.code}}. </span>{{menuItem.name}}</ion-col><ion-col text-right>{{menuItem.price}}</ion-col></ion-row></ion-grid></ion-card-header>-->
                <ion-card-content class="ion-float-left">
                    {{menuItem.description}}
                    <div class="ion-padding-top">
                    <ion-badge *ngIf="menuItem.spicy" color="danger">Hot & Spicy</ion-badge><span
                      *ngIf="menuItem.spicy">&nbsp;</span>
                    <ion-badge *ngIf="menuItem.vegan" color="success">Vegan</ion-badge><span
                      *ngIf="menuItem.vegan">&nbsp;</span>
                    <ion-badge *ngIf="menuItem.popular" color="danger">Popular</ion-badge><span
                      *ngIf="menuItem.popular">&nbsp;</span>
                    <ion-badge *ngIf="menuItem.rawFish" color="danger">Raw Fish</ion-badge><span
                      *ngIf="menuItem.rawFish">&nbsp;</span>
                    <ion-badge *ngIf="menuItem.origPrice" color="danger">Sale</ion-badge><span
                      *ngIf="menuItem.rigPrice">&nbsp;</span>
                    <ion-badge *ngIf="menuItem.healthy" color="success"><i class="fa fa-heart"></i>&nbsp;Healthy
                    </ion-badge><span *ngIf="menuItem.healthy">&nbsp;</span>
                    <ion-badge *ngIf="menuItem.vegetarian" color="success">Vegetarian</ion-badge><span
                      *ngIf="menuItem.vegetarian">&nbsp;</span>
                      </div>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </div>
  </section>
</ion-content>
<ion-footer style="height:2.25em!important;">
  <ion-toolbar color="tertiary" style="height:2.25em!important;padding:0px;">
  <ion-text>{{activeBatchName}}&nbsp;for&nbsp;{{activePersonName}}</ion-text>
</ion-toolbar>
</ion-footer>