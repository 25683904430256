import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StorageService } from './core/storage.service';
import { SettingsPage } from './features/core/settings/settings.page';
import {GrayLogService} from './core/graylog.service'
import { RewardService } from './features/reward/reward.service';
import { PushyService } from './core/pushy.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment.test';


declare var Pushy:any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Restaurants',
      url: '/restaurant-list',
      icon: 'home'
    }
  ];
  public darkMode = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storageService: StorageService,
    private grayLogService: GrayLogService,
    private rewardService: RewardService,
    private pushyService: PushyService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      try{
        this.pushyService.init(Pushy, (data)=>{
          if (data?.restUri){
            this.router.navigateByUrl(`/#/restaurant/${data.restUri}/reward`);
          }
          
        });
      } catch(err){
        
      };
      this.storageService.get(this.rewardService.STORAGE_KEY+'currentRewardAccount').then((value:any)=>{
      });

      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.darkModeCheck();

  }
  darkModeCheck(){
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode=prefersDark.matches;
    //Override is stored in local storage by the settings page
    this.storageService.get(SettingsPage.SETTINGS_KEY).then((settings: any)=>{
      if (settings){
        this.darkMode=settings.darkMode;
      }
      this.setDarkMode(this.darkMode);
    }, ()=>{
      this.setDarkMode(this.darkMode);
    });
    this.grayLogService.logInfo('foodalot', 'appLaunched','', {darkMode: this.darkMode});
  }

  setDarkMode(darkMode) {
    if (darkMode){
      document.body.classList.remove('orange-theme');
      document.body.classList.add('orange-theme-dark');
    }else{
      document.body.classList.remove('orange-theme-dark');
      document.body.classList.add('orange-theme');

    }
  }
  
}
