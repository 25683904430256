<ion-header>  
  <ion-toolbar color="primary">
  <ion-buttons slot="start">
    <ion-back-button defaultHref="restaurant-list"></ion-back-button>
  </ion-buttons>
    
  <ion-title>
    Login
  </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup">
    <ion-grid>
      <ion-row *ngIf="environment.customApp">
        <ion-col><app-fdl-logo></app-fdl-logo></ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p> Login to your FoodALot account. </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="no-padding">
            <ion-label position="floating"> Your Email ID</ion-label>
            <ion-input type="email" formControlName="emailId"></ion-input>
          </ion-item>
          <ion-item class="no-padding" *ngIf="formGroup.controls.emailId.invalid  &&  submitAttempt">
            <span class="color-error" *ngIf="formGroup.controls.emailId.errors.required">Email id is required.</span>
            <span class="color-error"
              *ngIf="formGroup.controls.emailId.errors.email && !formGroup.controls.emailId.errors.required">Invalid
              Email Id.</span>
          </ion-item>
        </ion-col>

      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="no-padding">
            <ion-label position="floating"> Password</ion-label>
            <ion-input type="{{passwordToggle?'password':'text'}}" 
              formControlName="password"></ion-input>
          </ion-item>
          <ion-item class="no-padding" *ngIf="formGroup.controls.password.invalid  &&  submitAttempt">
            <span class="color-error">Password is required.</span>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-item class="no-padding">
            <ion-label>Keep me logged in</ion-label>
            <ion-toggle formControlName="keepMeLoggedIn"></ion-toggle>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col> 
          <ion-item class="no-padding" text-wrap *ngIf="lastErr">
            <span class="color-error">{{lastErr}}</span>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col offset-sm="3" size-sm="6">
          <ion-button color="primary" expand="block" (click)="login()">LOGIN</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col offset-sm="3" size-sm="6">
          Forgot Password?<br/>
          <ion-button color="secondary" expand="block" (click)="resetPwd()">RESET PASSWORD</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>