import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { JoinRewardComponent } from './join-reward.component';
import { InvalidMessagesModule } from '../../../core/invalid-messages/invalid-messages.module';
import { BirthdayModule } from '../birthday/birthday.module';

@NgModule({
    declarations: [
        JoinRewardComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      InvalidMessagesModule,
      BrMaskerModule,
      BirthdayModule,
      IonicModule.forRoot()
    ],
    exports: [
        JoinRewardComponent
    ]
  })
 export class JoinRewardModule { }