import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { PushyService } from "../../core/pushy.service";
import { StorageService } from "../../core/storage.service";
import { Restaurant } from "../restaurant/restaurant.model";
import { RestaurantService } from "../restaurant/restaurant.service";
import { RewardAccount } from "./reward.model";

@Injectable({
    providedIn: 'root'
  })
  
 export class RewardService {
    private bsubRewardAccount: BehaviorSubject<RewardAccount | null>;
    private restaurant: Restaurant;
    private baseUrl: string;
    public STORAGE_KEY = 'foodalot:reward:';

    public constructor (
        private http: HttpClient,
        restaurantService: RestaurantService,
        private storageService: StorageService,
        private pushyService: PushyService

    ){
        this.baseUrl=environment.baseUrl;
        this.bsubRewardAccount= new BehaviorSubject<RewardAccount|null>(null);

        restaurantService.getRestaurant().subscribe({
            next: (restaurant: Restaurant) => {
              this.restaurant = restaurant;
              if (restaurant?.rwdcfg?.rewardEnabled){
//                let rewardAccount: RewardAccount=this.bsubRewardAccount.value;

/*               if ((rewardAccount?.restUri) && (rewardAccount?.restUri!==this.restaurant.uri)){

               }*/
              }
              
            }
          });
    }
    public getRewardAccount(): Observable<RewardAccount|null> {
      return this.bsubRewardAccount;
    }
    public loadAccount() {

        if(this.restaurant?.uri)
        {
          this.storageService.get(this.STORAGE_KEY+this.restaurant.uri).then(
            (data:any)=>{
              if ( (data?.mobileNo) && (data?.loginKey)) {
                this.http.post(
                  this.baseUrl + 'reward/authenticate',
                  {
                    mobileNo: data.mobileNo,
                    restUri: this.restaurant.uri,
                    loginKey: data.loginKey
                  }
              ).toPromise().then((data:any)=>{
                this.storageService.get(this.STORAGE_KEY+this.restaurant.uri+'currAcct')
                  .then((v)=>{
                    this.storageService.set(this.STORAGE_KEY+this.restaurant.uri+'prevAcct', v);
                    this.storageService.set(this.STORAGE_KEY+this.restaurant.uri+'currAcct', data.retObj);

                  });
                this.bsubRewardAccount.next(data.retObj);

              }).catch((data)=>{
                console.error(JSON.stringify(data));
                this.storageService.remove((this.STORAGE_KEY+this.restaurant.uri));
              });
              }
            }
          )
        }
    }
    join(mobileNo, fullName, dob) {
        const json={
            mobileNo,
            fullName,
            dob,
            pushyDeviceToken: this.pushyService.deviceToken,
            restUri: this.restaurant?.uri
        }
        return new Promise((resolve, reject) => {

            this.http.post(this.baseUrl + 'reward/join', json)
                .subscribe((data: any) => {
                    if (data.code === 0) {
                      resolve(null);
                    } else {
                      reject(data);
                    }
                }, err => {
                  reject({code: 99, text: 'Error ' + err});
                });
        });
    }
  sendOtp(mobileNo) {
    const json = {
      mobileNo,
      restUri: this.restaurant?.uri
    }
    return new Promise((resolve, reject) => {

      this.http.post(this.baseUrl + 'reward/sendotp', json)
        .subscribe((data: any) => {
          if (data.code === 0) {
            resolve(null);
          } else {
            reject(data);
          }
        }, err => {
          reject({ code: 99, text: 'Error ' + err });
        });
    });

  }
    otpLogin(mobileNo, otp) {
        const json={
            mobileNo,
            otp,
            restUri: this.restaurant?.uri
        }
        return new Promise((resolve, reject) => {

            this.http.post(this.baseUrl + 'reward/otplogin', json)
                .subscribe((data: any) => {
                    if (data.code === 0) {
                      let rewardAccount: RewardAccount= data.retObj;
                      const localData={
                        restUri: rewardAccount.restUri,
                        mobileNo: rewardAccount.mobileNo,
                        loginKey: rewardAccount.loginKey
                      };
                      this.storageService.set(this.STORAGE_KEY+rewardAccount.restUri, localData);
                      this.storageService.set(this.STORAGE_KEY+'currentRewardAccount', localData);
                      this.storageService.get(this.STORAGE_KEY+this.restaurant.uri+'currAcct')
                      .then((v)=>{
                        this.storageService.set(this.STORAGE_KEY+this.restaurant.uri+'prevAcct', v);
                        this.storageService.set(this.STORAGE_KEY+this.restaurant.uri+'currAcct', data.retObj);
    
                      });
    
                      this.bsubRewardAccount.next(rewardAccount);
                      resolve(rewardAccount);
                    } else {
                      reject(data);
                    }
                }, err => {
                    reject({code: 99, text: 'Error ' + err});
                });
        });
    }
    delete(mobileNo, loginKey) {
      const json={
          mobileNo,
          restUri: this.restaurant?.uri,
          loginKey
      }
      return new Promise((resolve, reject) => {

          this.http.post(this.baseUrl + 'reward/delete', json)
              .subscribe((data: any) => {
                  if (data.code === 0) {
                    this.storageService.remove(this.STORAGE_KEY+this.restaurant.uri+'prevAcct');
                    this.storageService.remove(this.STORAGE_KEY+this.restaurant.uri+'currAcct');
                    this.bsubRewardAccount.next(null);
                    resolve(null);
                  } else {
                    reject(data);
                  }
              }, err => {
                  reject({code: 99, text: 'Error ' + err});
              });
      });
  }
   
    logout(mobileNo, loginKey) {
      const json={
          mobileNo,
          restUri: this.restaurant?.uri,
          loginKey
      }
      return new Promise((resolve, reject) => {

          this.http.post(this.baseUrl + 'reward/logout', json)
              .subscribe((data: any) => {
                  if (data.code === 0) {
                    this.storageService.remove(this.STORAGE_KEY+this.restaurant.uri+'prevAcct');
                    this.storageService.remove(this.STORAGE_KEY+this.restaurant.uri+'currAcct');
                    this.bsubRewardAccount.next(null);
                    resolve(null);
                  } else {
                    reject(data);
                  }
              }, err => {
                  reject({code: 99, text: 'Error ' + err});
              });
      });
  }


}