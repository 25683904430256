<ion-header >
  <ion-toolbar color="primary">
    
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
      <!--
      <ion-button routerDirection="back" (click)="back()">
        <ion-icon
        ios="chevron-back-outline"
        md="arrow-back-outline"
        ></ion-icon>Back
      </ion-button>
      -->
    </ion-buttons>
    <ion-title>Table Order</ion-title>
  </ion-toolbar>

</ion-header>

<ion-content>
  <fdl-login-status [user]="user"></fdl-login-status>
  <ion-item lines="none">
    <ion-button  size="small" (click)="startOver()" fill="outline">Start Over</ion-button>
  </ion-item>
 <app-restaurant-info [restaurant]="restaurant"></app-restaurant-info>
  <ion-item lines="none">
    <ion-label>Your Location<p>Section {{tableOrder?.section}} Table #{{tableOrder?.table}}</p></ion-label>
   
  </ion-item>
  
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <app-table-order-step1 
          [tableOrder]="tableOrder"
          (next) = "next($event)"
          >
        </app-table-order-step1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <app-table-order-batch 
          [tableOrder]="tableOrder"
          batchName="Beverages" [thisState]="2"
          batchDesc="Add Beverage for each person in your group. Water is available for free in the Beverage Menu."
          (next)="nextToDish()"
          #beverageComp></app-table-order-batch>
      </ion-col>
    </ion-row>
    
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <app-table-order-batch 
            [tableOrder]="tableOrder"
            batchName="Dishes" [thisState]="3"
            batchDesc="Add Appetizer, Entree and Desert for each person in your group."

            (next) = "gotoPayment()"
            #dishComp></app-table-order-batch>
        </ion-col>
      </ion-row>
      <ion-row>
  
      <ion-col size-md="6" offset-md="3">
        <app-table-order-finish
          [tableOrder]="tableOrder"
          #finishComp>
        </app-table-order-finish>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>
