import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import party from "party-js";

@Injectable()
export class UIHelpers {
    constructor(
        private toastCtrl: ToastController,
        private alertController: AlertController
        ) {

    }
    showErr(msg) {
        const toast = this.toastCtrl.create({
            message: msg,
            position: 'bottom',
            color: 'danger',
            buttons: [
                {
                    side:'end',
                    text:'Close',
                    role:'cancel'
                }
            ]
        });
        toast.then((elt) => {
            elt.present();

        });
    }
    
    showErrAutoClose(msg) {
        const toast = this.toastCtrl.create({
            message: msg,
            position: 'bottom',
            duration: 2500,
            cssClass: 'error'
        });
        toast.then((elt) => {
            elt.present();
        });
    }
    showSuccessMsgWithClose(msg){
        const toast = this.toastCtrl.create({
            message: msg,
            position: 'top',
            cssClass:'success',
            buttons: [
                {
                    side:'end',
                    text:'Close',
                    role:'cancel'
                }
            ]

        });
        toast.then((elt) => {
            elt.present();
        });
    }
    showSuccessMsg(msg){
        const toast = this.toastCtrl.create({
            message: msg,
            position: 'top',
            duration: 4000,
            color: 'success',
            buttons: [
                {
                  side: 'start',
                  icon: 'checkmark-circle',
                  text: '',
                  handler: () => {
                  }
                }
            ]
          });
        toast.then((elt) => {
              elt.present();
          });
    }
    async confirm(title, msg, yesCallback) {
        const alert = await this.alertController.create({
          header: title,
          message: msg,
          buttons: [
            {
              text: 'No Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            }, {
              text: 'Yes Proceed',
              handler: yesCallback
            }
          ]
        });
        await alert.present();
      }
      async msgPopup(title, msg, yesCallback) {
        const alert = await this.alertController.create({
          header: title,
          message: msg,
          buttons: [
            {
              text: 'Okay',
              handler: yesCallback
            }
          ]
        });
        await alert.present();
      }

      showWarning(msg: string): Promise<HTMLIonToastElement> {
        const toast = this.toastCtrl.create({
            message: msg,
            position: 'top',
            buttons: [
                {
                    side:'end',
                    text:'Close',
                    role:'cancel'
                }
            ],

            color: 'warning',
            cssClass: 'fdl-warning-toast'
        });
        toast.then((elt) => {
            elt.present();
        });
        return toast;
    }
    
    showConfetti(){
        party.confetti(document.body, {
          count: 100
          });
          new Promise( resolve => setTimeout(resolve, 1500) ).then(()=>{
            party.confetti(document.body, {
              count: 100
              });
              new Promise( resolve => setTimeout(resolve, 1500) ).then(()=>{
                party.confetti(document.body, {
                  count: 100
                  });
              
              });
              
          });
    
      }

}
