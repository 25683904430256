import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StringUtil {
    public static removeAllSpaces(str): string {
        return str.replace(/ /g, '');
    }
    public toTitleCase(str) {
        return (str)?str.toLowerCase().replace(/(^|\s)\S/g,
            (t) => {
                return t.toUpperCase();
            }):'';
    }
}
