<form [formGroup]="formGroup">
  <ion-grid>
    <ion-row>
      <ion-col>
          <ion-label class="fdl-sub-text">Order ahead. Drive to the restaurant's drive-thru.
            Press the CHECKIN button, from your order detail page.
            </ion-label>
      </ion-col>
    </ion-row>
    <app-order-date-time [formGroup]="formGroup" orderType="DRIVE-THRU PICKUP">
    </app-order-date-time>
  
  </ion-grid>
  </form>
  