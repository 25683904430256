import { FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FdlValidators {
    static minMaxSel(minSel: number, maxSel: number): any {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === 'something') {
                return { failed: true };
            }
            return null;
        };
    }
    static monthDay(): ValidatorFn {
        const failed = {invalidMonthDay: true };
        return  (control: AbstractControl): {[key: string]: any} => {
          try {
            const v: string = control.value;
            if ( (v) && v.length >= 3) {
              const tokens: string[] = v.split('/');
              if (tokens.length != 2){
                return {};
              }
              const month = parseInt( tokens[0], 10 );
              const day = parseInt( tokens[1], 10 );;
              if ((month<0) || (month>12)){
                return failed;
              }
              if ((day<0) || (day>31)){
                return failed;
              }
              let dt=new Date(1970, month-1, day);
              if (dt.toString()==='Invalid Date'){
                return failed;
              }          
            }
          } catch (err) {
            return failed;
    
          }
          return {};
        };
    }
}
