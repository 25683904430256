import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TableOrderMenuPage } from './table-order-menu.page';
import { SharedFilterPipeModule } from 'src/app/core/shared-filter-pipe.module';

const routes: Routes = [
  {
    path: '',
    component: TableOrderMenuPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedFilterPipeModule,
    RouterModule.forChild(routes)
  ],
  declarations: [TableOrderMenuPage]
})
export class TableOrderMenuPageModule {}
