import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableOrder } from '../table-order.model';

import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrderService } from '../../order/order.service';

@Component({
  selector: 'app-table-order-step1',
  templateUrl: './table-order-step1.component.html',
  styleUrls: ['./table-order-step1.component.scss']
})
export class TableOrderStep1Component implements OnInit {
  @Input() tableOrder: TableOrder;
  @Output()  next = new EventEmitter<string>();
  Arr = Array;
  formGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService
  ) {
  }
  buildFormGroup() {
    const ctrls = {};
    for (let i = 0; i < this.tableOrder.noOfPeople; ++i) {
      if ( (this.tableOrder?.people) && (this.tableOrder.people[i]) ){
        ctrls['person' + i] = new FormControl (this.tableOrder.people[i], Validators.compose([
          Validators.required
          ]));
      }else{
        ctrls['person' + i] = new FormControl ('Person ' + (i + 1), Validators.compose([
          Validators.required
          ]));
      }

    }
    this.formGroup = this.formBuilder.group(ctrls);
  }
  ngOnInit() {
    this.buildFormGroup();
  }

  incPeople() {
    if (this.tableOrder.noOfPeople < 10) {
      this.tableOrder.people = [];
      for (let i = 0; i < this.tableOrder.noOfPeople; ++i) {
        this.tableOrder.people.push(this.formGroup.controls['person' + i].value);
      }      
      this.tableOrder.noOfPeople++;
      this.buildFormGroup();
    }
  }
  decPeople(){
    if (this.tableOrder.noOfPeople > 1) {
      this.tableOrder.noOfPeople--;
      this.buildFormGroup();
    }
  }
  nextAction() {
    if (this.formGroup.valid) {
      this.tableOrder.people = [];
      for (let i = 0; i < this.tableOrder.noOfPeople; ++i) {
        this.tableOrder.people.push(this.formGroup.controls['person' + i].value);
      }
      this.tableOrder.name = this.tableOrder.people[0];
      this.tableOrder.state = 2;
      this.orderService.updateOrder(this.tableOrder).then(()=>{
        this.next.emit('done');
      });
    }
  }
  selectAll($event){
    $event.selectAll();
  }

}
