import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Menu, Category } from '../../menu/menu.model';
import { MenuService } from '../../menu/menu.service';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { Restaurant } from '../../restaurant/restaurant.model';
import { Subscription } from 'rxjs';
import { OrderService } from '../../order/order.service';
import { Order } from '../../order/order.model';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { TableOrderMenuItemPage } from '../table-order-menu-item/table-order-menu-item.page';
import { OverlayEventDetail } from '@ionic/core';
import { TableOrderMenuCategoriesComponent } from '../table-order-menu-categories/table-order-menu-categories.component';
import { TableOrder } from '../table-order.model';

@Component({
  selector: 'app-table-order-menu',
  templateUrl: './table-order-menu.page.html',
  styleUrls: ['./table-order-menu.page.scss'],
})
export class TableOrderMenuPage
  implements OnInit, OnDestroy, AfterContentInit {
  public restaurant: Restaurant;
  public menus: Menu[];
  private getRestaurantSubs: Subscription;
  private getActiveMenusSubs: Subscription;
  public selectedMenuIndex: number;
  public selectedMenu: number;
  public order: TableOrder;
  searchText: string;
  showSearch = false;
  public nextTimeSlot;
  activePersonName: string;
  activeBatchName: string;

  constructor(
    private restaurantService: RestaurantService,
    private menuService: MenuService,
    private orderService: OrderService,
    private router: Router,
    private modalController: ModalController,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.getRestaurantSubs = this.restaurantService.getRestaurant()
      .subscribe((restaurant: Restaurant) => {
        this.restaurant = restaurant;
        this.getActiveMenusSubs = this.menuService.getActiveMenus(this.restaurant.uri)
          .subscribe((menus: any) => {
            if (menus) {
              this.menus = menus;
              this.menus.sort((a: Menu, b: Menu) => {
                return (a.sortOrder - b.sortOrder);
              });
              this.selectedMenuIndex = 0;
              this.orderService.getOrder().subscribe({
                next: (order: Order) => {
                  if ((order) && (order.orderType === 'TABLE ORDER')) {
                    this.order = order as TableOrder;
                    this.activeBatchName = TableOrder.batchNames[this.order.state - 2];
                    this.activePersonName = this.order.people[this.order.activePersonId];
                    if (this.activeBatchName === 'Dishes') {
                      this.filterMenu((c: Category) => {
                        const cn1 = c.name.toLowerCase();
                        const cn2 = this.activeBatchName.toLowerCase();
                        return cn2.indexOf(cn1)<0;
                      });
                    } else {
                      this.filterMenu((c: Category) => {
                        const cn = c.name.toLowerCase();
                        return (cn.indexOf('beverages')>=0);
                      });

                    }
                  }
                }
              });
            }

          }, (error) => {
          });

      });
  }
  filterMenu(includeCat) {

    if (this.menus) {
      for (const m of this.menus) {
        for (const c of m.categories) {
          c.show = includeCat(c);
        }

      }

    }
  }
  ngOnDestroy(): void {
    if (this.getRestaurantSubs) {
      this.getRestaurantSubs.unsubscribe();
    }
    if (this.getActiveMenusSubs) {
      this.getActiveMenusSubs.unsubscribe();
    }
  }
  ngAfterContentInit() {
    this.selectedMenuIndex = 0;
  }
  menuItemClick2(category, menuItem, menuIndex, catIndex) {
    const menu: Menu = this.menus[this.selectedMenuIndex];
    const props: any = {};
    props.menu = menu;
    props.category = category;
    props.menuItem = menuItem;
    props.menuIndex = menuIndex;
    props.catIndex = catIndex;
    this.presentMenuItemModal(props);
  }

  private async presentMenuItemModal(props) {
    const modal = await this.modalController.create({
      component: TableOrderMenuItemPage,
      componentProps: props,
      cssClass: 'modal-fullscreen'
    });
    modal.present();
  }


  menuItemClick(category, menuItem, menuIndex, catIndex) {
    const menu: Menu = this.menus[this.selectedMenuIndex];
    const s: any = {};
    s.menu = menu;
    s.category = category;
    s.menuItem = menuItem;
    s.menuIndex = menuIndex;
    s.catIndex = catIndex;

    const navigationExtras: NavigationExtras = {
      state: s
    };
    this.router.navigate(
      ['/table-order-menu-item'],
      navigationExtras
    );
  }
  // To scroll to an element on the page
  scrollTo(elementId: any) {
    const el = document.getElementById(elementId);
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }

  // To Display a popover of menu categories
  async displayCategories(event) {

    const popover = await this.popoverCtrl.create({
      component: TableOrderMenuCategoriesComponent,
      componentProps: { cat: this.menus[this.selectedMenuIndex].categories },
      event
    });

    popover.onDidDismiss().then((detail: OverlayEventDetail) => {
      if ((detail) && (detail.data) && (detail.data.newCat)) {
        this.scrollTo('cat' + detail.data.newCat);

      }
    }
    );

    return await popover.present();
  }
}
