import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, AfterContentInit } from '@angular/core';
import { TableOrder, TableOrderBatch, TableOrderPerson } from '../table-order.model';
import { Router } from '@angular/router';
import { OrderService } from '../../order/order.service';
import { last } from 'rxjs/operators';

@Component({
  selector: 'app-table-order-batch',
  templateUrl: './table-order-batch.component.html',
  styleUrls: ['./table-order-batch.component.scss'],
})
export class TableOrderBatchComponent implements OnInit, AfterContentInit {
  @Input()
  tableOrder: TableOrder;
  @Input()
  batchName: string;
  @Input()
  batchDesc: string;
  @Input()
  thisState: number;
  @Output()  next = new EventEmitter<string>();

  nextBtnLabel="Next";
  lastStep=false;

  batch: TableOrderBatch;
  constructor(
    private router: Router,
    private orderService: OrderService
  ) {
  }
  ngAfterContentInit(): void {
    
    if (this.tableOrder){
      this.tableOrder.cart.subTotal
      if (this.tableOrder.state >=2){
        this.batch = this.tableOrder.batches[this.thisState - 2];
        if (!this.batch) {
          this.batch = new TableOrderBatch();
          this.tableOrder.batches[this.thisState - 2] = this.batch;
          this.batch.batchId = this.thisState - 2;
          this.batch.batchName = this.batchName;
          this.batch.people = [];
          for (let i = 0; i < this.tableOrder.noOfPeople; ++i) {
            const person = new TableOrderPerson();
            person.personId = i;
            person.personName = this.tableOrder.people[i];
            person.items = [];
            this.batch.people.push(person);
          }                  
        }
        
 
      }
    }
  }

  ngOnInit() {
    this.lastStep=(this.thisState-1)==(TableOrder.batchNames.length);
    if (this.lastStep){
      this.nextBtnLabel="Pay & Finish";
    }
  }
  public initialize() {
    this.batch = this.tableOrder.batches[this.thisState - 2];
    if (!this.batch) {
      this.batch = new TableOrderBatch();
      this.tableOrder.batches[this.thisState - 2] = this.batch;
    }
    
    this.batch.batchId = this.thisState - 2;
    this.batch.batchName = this.batchName;
    this.batch.people = [];
    for (let i = 0; i < this.tableOrder.noOfPeople; ++i) {
      const person = new TableOrderPerson();
      person.personId = i;
      person.personName = this.tableOrder.people[i];
      person.items = [];
      this.batch.people.push(person);
    }
  }
  openMenu(person: TableOrderPerson) {
    this.tableOrder.activePersonId = person.personId;
    this.orderService.updateOrder(this.tableOrder).then(() => {
      this.router.navigate(['/table-order-menu']);
    });
  }
  nextStep() {
 
    if (this.tableOrder.state<3){
      this.tableOrder.state++;
    }
    this.orderService.updateOrder(this.tableOrder).then(()=>{
      this.next.emit('done');
    });
  }
  previousStep() {
    if (this.tableOrder.state>2){
      this.tableOrder.state--;
      this.orderService.updateOrder(this.tableOrder);
    }
  }
  deleteItem(person, item){
    let personIndex=this.batch.people.indexOf(person);
    let itemIndex=person.items.indexOf(item);
    this.orderService.deleteTableOrderItem(this.thisState-2, personIndex, itemIndex);
  }
}
