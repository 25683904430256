<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Start New Order</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancelModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <h3>{{restaurant.name}}</h3>
  <p>{{restaurant.address.line1}}, {{restaurant.address.city}}, {{restaurant.address.state}} -
    {{restaurant.address.zip}}. </p>


  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-label stacked>Order Type</ion-label>
        <ion-radio-group value="CARRYOUT"
         (ionChange)="orderTypeChange($event)">
          <ion-list>
            <ion-item>
              <ion-radio value="CARRYOUT">CARRYOUT</ion-radio>
            </ion-item>
            <!-- No Wait Dine-in depricated. Restaurnat can not find a seat.
            <ion-item *ngIf="restaurant?.otAllowDineIn">
              <ion-label>NO WAIT DINE-IN</ion-label>
              <ion-radio value="NO WAIT DINE-IN"></ion-radio>
            </ion-item>-->
            <ion-item *ngIf="restaurant?.otAllowCurbsidePickup">
              <ion-label>CURBSIDE PICKUP</ion-label>
              <ion-radio value="CURBSIDE PICKUP"></ion-radio>
            </ion-item>
            <ion-item *ngIf="restaurant?.otAllowDriveThru">
              <ion-label>DRIVE-THRU PICKUP</ion-label>
              <ion-radio value="DRIVE-THRU PICKUP"></ion-radio>
            </ion-item>
            <ion-item *ngIf="restaurant?.otAllowDelivery" class="ion-text-wrap" 
              href="https://foodalot.com"
              detail>
              <ion-label>DELIVERY</ion-label>
              <ion-text><p>Please go to foodalot.com</p></ion-text>
            </ion-item>
          </ion-list>
        </ion-radio-group>
      </ion-col>
    </ion-row>
  </ion-grid>
  <app-carryout name="carryoutComp" *ngIf="orderType==='CARRYOUT'" #carryoutComp></app-carryout>
  <app-no-wait-dine-in name="dineInComp" *ngIf="orderType==='NO WAIT DINE-IN'" #dineInComp></app-no-wait-dine-in>
  <app-delivery name="deliveryComp" *ngIf="orderType==='DELIVERY'" #deliveryComp></app-delivery>
  <app-curbside-pickup name="curbsidePickupComp" *ngIf="orderType==='CURBSIDE PICKUP'" #curbsidePickupComp></app-curbside-pickup>
  <app-drive-thru-pickup name="driveThruPickup"
    *ngIf="orderType==='DRIVE-THRU PICKUP'" #driveThruPickupComp
  ></app-drive-thru-pickup>


  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-button expand="full" color="primary" type="button" (click)="start();"
          [disabled]="!orderTypeInterface?.canStart()">Start&nbsp;<ion-icon name="arrow-forward"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>