import { Restaurant } from "../restaurant/restaurant.model";

export class OptionResponse {
    text: string;
    selected: boolean;
    extraCost: number;

}
export class ItemOption {
    id: string;
    name: string;
    type: string;
    responses: any[];
}

export class CartItem {
    code: string;
    name: string;
    personName: string;
    notes: string;
    price: number;
    qty: number;
    menuName: string;
    catName: string;
    sortOrder: number;
    personId: number;
    batchId: number;
    batchName: string;

    remind: boolean;
    processingStation: string;
    options: any[];
    totalPrice: number;
    volumeInOz?: number;
    alcoholic?: boolean;
    taxAlways?: boolean;
    tax:number;

    constructor(json?: any) {
        if (json) {
            Object.assign(this, json);
            if (json.options) {

            }
        }

    }
    calcOptionsPrice(): number {
        let total = 0;
        if ( this.options ) {
            // tslint:disable-next-line: prefer-for-of
            for (const opt of this.options) {
                if (opt.responses) {
                    for (const resp of opt.responses) {
                        if ( (resp.selected) && (resp.extraCost) ) {
                            total += resp.extraCost;
                        }
                    }
                }
            }
        }
        return total;
    }
    calcItemPrice(): number {
        return this.price + this.calcOptionsPrice();
    }
    calcTotalPrice() {
        let total: number = this.price + this.calcOptionsPrice();
        total = total * this.qty;
        this.totalPrice = total;
    }
}
export class Cart {
    createDt: Date;
    items: CartItem[];
    subTotal: number;
    //still better understanding needed on idempotencyKey
    //Please always pass null, for now.
    idempotencyKey: string;  // GUID, sent to stripe to avoid duplicate orders.

    constructor(json?: any) {
        this.items = null;
        if (json) {
            Object.assign(this, json);
            if (json.items) {
                const itms: CartItem[] = [];
                for (const iJson of json.items) {
                    const itm = new CartItem(iJson);
                    itms.push(itm);
                }
                this.items = itms;




            }
        }
        //still better understanding needed on idempotencyKey
        //Please always pass null, for now.
        //this.idempotencyKey=crypto.randomUUID();
    }
    // calcSubTotal(){
    //     this.subTotal=0;
    //     if (this.items){
    //         for(var i=0;i<this.items.length;++i){
    //             let item=this.items[i];
    //             this.subTotal+=item.totalPrice;
    //         }
    //     }
    // }
    updateSubTotal(i: number) {
        const item = this.items[i];
        this.subTotal -= item.totalPrice;
        item.calcTotalPrice();
        this.subTotal += item.totalPrice;
    }
    addItem(item: CartItem) {
        if (!(this.items)) {
            this.items = [];
            this.subTotal = 0;
        }
        item.calcTotalPrice();
        this.subTotal += item.totalPrice;

        this.items.push(item);
    }
    removeItem(i: number) {
        this.subTotal -= this.items[i].totalPrice;
        this.items.splice(i, 1);
    }
    calcTax(taxPercent:number, taxAllItems:boolean): number{
        let tax=0.0;
        if (this.items){
            for(let i=0;i<this.items.length;++i){
                
                const item:CartItem=this.items[i];
                item.tax=0.0;
                if (taxAllItems){
                    item.tax=((taxPercent/100)*item.totalPrice);
                    tax+=item.tax;
                } else{
                    if(item.taxAlways){
                        item.tax=((taxPercent/100)*item.totalPrice);
                        tax+=item.tax;
                    }
                }
            }
        }
        tax=Number(tax.toFixed(2))
        return tax;
        
    }

 
}
