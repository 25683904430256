import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Restaurant } from './restaurant.model';
import { MenuService } from '../menu/menu.service';
import { OrderService } from '../order/order.service';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
 
  private baseUrl: string;
  private restaurant: BehaviorSubject<Restaurant>;
  constructor(
    private http: HttpClient,
    private menuService: MenuService
  ) {
    this.baseUrl = environment.baseUrl;

    this.restaurant = new BehaviorSubject<Restaurant>(null);
   }
   fetchRestaurants() {
     // We add a timestamp at the end to disable browser caching.
     return this.http.post(
       this.baseUrl + 'restaurant/fetchall',
       {});
   }
  fetchRestaurant(uri: string) {
    const param: any = {};
    this.menuService.reset();
    param.uri = uri;
    return this.http.post(
      this.baseUrl + 'restaurant/fetchdirect',
      param)
      .pipe(
        tap( (restaurant: Restaurant) => {
          if (!restaurant.orderDaysInAdvance){
            restaurant.orderDaysInAdvance = 1;
          }
          this.restaurant.next(restaurant);
        })

      );
  }
  public getRestaurant(): Observable<Restaurant> {
    return this.restaurant;
  }
  public updateRestaurant(restaurant: Restaurant): void{
    this.restaurant.next(restaurant);
  }
  private quotes:BehaviorSubject<any>=new BehaviorSubject<any>(null);

  public getQuotes(): Observable<any>{
    if (this.quotes.value==null){
      this.http.get('https://foodalot.com/pages/quotes.json?cache_buster='+new Date().getTime())
        .toPromise().then((data)=>{
          this.quotes.next(data);
        });
    }
    return this.quotes;
  }
}
