import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GetContactInfoPageRoutingModule } from './get-contact-info-routing.module';

import { GetContactInfoPage } from './get-contact-info.page';
import { InvalidMessagesModule } from '../../../core/invalid-messages/invalid-messages.module'
import { BrMaskerModule } from 'br-mask';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    InvalidMessagesModule,
    BrMaskerModule,    
    GetContactInfoPageRoutingModule
  ],
  declarations: [GetContactInfoPage]
})
export class GetContactInfoPageModule {}
