import { Component, OnInit, Input, OnDestroy, AfterContentInit } from '@angular/core';
import { OrderTypeInterface } from '../order-type.interface';
import { Order } from '../../order.model';
import { Restaurant } from 'src/app/features/restaurant/restaurant.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as Moment from 'moment';

@Component({
  selector: 'app-carryout',
  templateUrl: './carryout.component.html',
  styleUrls: ['./carryout.component.scss'],
})
export class CarryoutComponent
  implements OnInit, OnDestroy, OrderTypeInterface {

  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {
  }
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});
  }
  ngOnDestroy(): void {
  }
  canStart(): boolean {
    return this.formGroup.valid;
  }
  initOrder(): Order {
    const order: Order = new Order();
    order.orderType = 'CARRYOUT';
    order.orderDt = this.deriveOrderDt(
      this.formGroup.controls.orderDate.value,
      this.formGroup.controls.orderTime.value
    );
    order.hrsOfOprName = this.formGroup.controls.orderTime.value.hrsOfOprName;
    return order;

  }
  private deriveOrderDt(orderDate, orderTime): Date {
    const orderDt = Moment(orderDate);
    const tm = new Date(orderTime.slotTime);
    orderDt.hour(tm.getHours());
    orderDt.minute(tm.getMinutes());
    orderDt.second(0);
    orderDt.millisecond(0);
    return orderDt.toDate();
  }
}
