<ion-card  class="ion-no-padding ion-no-margin" *ngIf="tableOrder.state === 1">
  <ion-card-subtitle>People</ion-card-subtitle>
  <ion-card-content class="ion-no-padding">
    <ion-label>How many in your group, paying together? </ion-label>
  <ion-item lines="none" class="ion-no-padding">
    <ion-button color="light" fill="outline" size="small"  (click)="decPeople()">
      <ion-icon name="remove" color="medium"></ion-icon>
    </ion-button>
    <span style="font-size:larger;font-weight:bold;margin-left:10px;margin-right:10px;">
      {{tableOrder?.noOfPeople}}
    </span>
    <ion-button color="light" fill="outline" size="small" class="ion-margin-end" (click)="incPeople()">
      <ion-icon name="add" color="medium" ></ion-icon>
    </ion-button>
    
  </ion-item>

  <form [formGroup]="formGroup">

  <ion-item lines="none" *ngFor="let i of Arr(tableOrder?.noOfPeople).fill(1); let j= index;" class="ion-no-padding" >
    <ion-label ><ion-icon name="person" slot="start"></ion-icon></ion-label>
    <ion-input  formControlName="person{{j}}" type="text"
     autocapitalize="words"
     (click)="$event.target.select()"></ion-input>
  </ion-item>
  <div class="ion-margin-top ion-text-end">
    <ion-button (click)="nextAction()">Next</ion-button>
  </div>
  </form>
</ion-card-content>  
</ion-card>
<ion-card  class="ion-no-padding ion-no-margin" *ngIf="tableOrder?.state !== 1">
  <ion-card-subtitle>People</ion-card-subtitle>
  <ion-card-content>
    <ion-item>
      <ion-label position="fixed">How many?</ion-label>
      <p >{{tableOrder?.noOfPeople}}</p>
    </ion-item>
    <ion-item>
      <ion-label class="ion-text-wrap"><ion-icon name="person" slot="start"></ion-icon>&nbsp;
      <ng-container *ngFor="let p of tableOrder.people; let last=last">{{p}}<span *ngIf="!last">,&nbsp;</span></ng-container>
    </ion-label>
    </ion-item>
</ion-card-content>
</ion-card>