import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UIHelpers } from '../../../core/uihelpers';
import { Restaurant } from '../../restaurant/restaurant.model';
import { RestaurantService } from '../../restaurant/restaurant.service';
import { RewardAccount } from '../reward.model';
import { RewardService } from '../reward.service';

@Component({
  selector: 'app-reward-detail',
  templateUrl: './reward-detail.component.html',
  styleUrls: ['./reward-detail.component.scss'],
})
export class RewardDetailComponent implements OnInit, OnDestroy{
  rewardAccount: RewardAccount;
  subsRewardService: Subscription;
  restaurant: Restaurant;
  subsRestaurant: Subscription;



  constructor(
    private rewardService: RewardService,
    private menuController: MenuController,
    private uiHelpers:UIHelpers,
    private restaurantService: RestaurantService    

  ) { 
  }


  ngOnInit() {

    this.subsRestaurant=this.restaurantService.getRestaurant().subscribe( (restaurant: Restaurant)=>{
      this.restaurant=restaurant;
    });
    this.subsRewardService=this.rewardService.getRewardAccount().subscribe(
      (rewardAccount:any)=>{
        this.rewardAccount=rewardAccount;
      }
    );
    this.rewardService.loadAccount();

  }
  ngOnDestroy(): void {
    if (this.subsRestaurant){
      this.subsRestaurant.unsubscribe();
    }
    if (this.subsRewardService){
      this.subsRewardService.unsubscribe();
    }
  }
}
