import { Component, OnInit, Input } from '@angular/core';
import { TableOrder } from '../table-order.model';
import { UserService } from '../../account/user.service';
import { OrderService } from '../../order/order.service';
import { User } from '../../account/user.model';
import { UIHelpers } from 'src/app/core/uihelpers';

@Component({
  selector: 'app-table-order-finish',
  templateUrl: './table-order-finish.component.html',
  styleUrls: ['./table-order-finish.component.scss'],
})
export class TableOrderFinishComponent implements OnInit {
  thisState = 6;
  @Input()
  tableOrder: TableOrder;
  user: User;
  submited: boolean;

  constructor(
    private userService: UserService,
    private orderService: OrderService,
    private uiHelpers: UIHelpers

  ) {

   }

  ngOnInit() {
    this.userService.getLoggedInUser().subscribe({
      next: (user: User) => {
        this.user = user;
      }
    });
  }
  public initialize(){
    this.tableOrder.total = this.tableOrder.cart.subTotal;
    this.tableOrder.orderTotal = this.tableOrder.cart.subTotal;
    this.orderService.updateOrder(this.tableOrder);
    this.submited = false;
  }
  finish() {
    this.orderService.submitOrder(this.user.authKey, this.user)
      .subscribe(() => {
        this.submited = true;
        this.uiHelpers.showSuccessMsg('Order succesfully placed');

      });
  }


}
