import { FormControl } from '@angular/forms';

export class MinMaxSelValidator{
    minSel: number;
    maxSel: number;
    constructor(minSel: number, maxSel: number){
        this.minSel = minSel;
        this.maxSel = maxSel;
    }
    public isValid(control: FormControl): any {

        return null;
    }
}