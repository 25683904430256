import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PushyService {
  pushy: any;
  private _deviceToken: string;
  public get deviceToken(): string {
    return this._deviceToken;
  }
  public set deviceToken(value: string) {
    this._deviceToken = value;
  }

  constructor() { }
  init(pushy: any, clickListner) {
    this.pushy = pushy;
    try {
      if (this.pushy) {
        this.pushy.listen();
        this.pushy.register((err, deviceToken) => {
          // Handle registration errors
          //if (err) {
          //  return alert(err);
          //}


          this.deviceToken = deviceToken;

        });
        this.pushy.setNotificationIcon('icon');
        // Enable in-app notification banners (iOS 10+)
        this.pushy.toggleInAppBanner(true);

        // Listen for push notifications
        this.pushy.setNotificationListener(function (data) {

          //   Clear iOS app badge number
          this.pushy.clearBadge();
        });
        // Listen for notification click
        this.pushy.setNotificationClickListener(clickListner);
      }else{
        alert('pushy is null')
      }
    } catch (err) {

    }


  }

}
