<form [formGroup]="formGroup">
  <ion-grid>
    <ion-row>
      <ion-col>
          <ion-label>Order ahead and dine-in restaurant.</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" size-sm="6">
        <ion-item>
      
        <ion-label stacked>Dine-in Date</ion-label>
        <ion-select placeholder="Select Date" formControlName="orderDate"
            (ionChange)="orderDateChange()"
        >
        <ion-select-option 
          *ngFor="let orderDate of orderDateList" 
          [value]="orderDate.dt"
        >{{orderDate.dtStr}}</ion-select-option>
        </ion-select>
      </ion-item>
      <app-invalid-messages
      [control]="formGroup.controls.orderDate"
      [validationMessages]="validationMessages.orderDate"
    >
    </app-invalid-messages>
  
      </ion-col>
      <ion-col size="12" size-sm="6">
          <ion-item>
              <ion-label stacked>Dine-in Time</ion-label>
              <ion-select class="width-sm" placeholder="Select Time"
                formControlName="orderTime"
              >
                <ion-select-option
                 *ngFor="let orderSlot of orderSlots; let idx = index"
                 [value]="orderSlot"
                 
                 >{{orderSlot.slotTime|date:'hh:mm a'}}</ion-select-option
                  >
              </ion-select>
            </ion-item>
            <app-invalid-messages
            [control]="formGroup.controls.orderTime"
            [validationMessages]="validationMessages.orderTime"
          >
          </app-invalid-messages>
    
      </ion-col>
    </ion-row>
  </ion-grid>
  </form>
  