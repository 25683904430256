import { Order } from '../order/order.model';
import { CartItem } from '../cart/cart.model';
export class TableOrderPerson {
    personId: number;
    personName: string;
    items: CartItem[];
}
export class TableOrderBatch {
    batchId: number;
    batchName: string;
    people: TableOrderPerson[];
}
export class TableOrder extends Order {
    public static batchNames = [
        'Beverages', 'Dishes'
    ]
    noOfPeople: number;
    people?: string[];
    section: string;
    table: string;
    batches?: TableOrderBatch[];
    state?: number; // 1 - People, 2-Bevrage, 3-Appetizer, 4-Entree 5-Desert 6-Pay
    activePersonId?: number;
}
