import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UIHelpers } from 'src/app/core/uihelpers';

@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.page.html',
  styleUrls: ['./resetpwd.page.scss'],
})
export class ResetpwdPage implements OnInit {

  curStep: number;
  formGroup: FormGroup;
  submitAttempt: boolean;
  lastErr: string;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    public navCtrl: NavController,
    public router: Router,
    private uiHelpers: UIHelpers,
  ) {
  }

  ngOnInit() {
    this.curStep = 1;
    this.submitAttempt = false;
    this.lastErr = null;
    this.formGroup = this.formBuilder.group({
      emailId: ['', Validators.compose([Validators.required, Validators.email])],
      securityCode: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      password2: [''],
    });
  }

  reset() {
    this.submitAttempt = true;
    if (this.curStep == 1) {
      if (this.formGroup.controls.emailId.valid) {
        this.userService.resetPwdStart(this.formGroup.value.emailId).then(() => {
          this.curStep = 2;
          this.submitAttempt = false;
        }).catch((err) => {
          this.lastErr = err;
        });
      }
    }
    else if (this.curStep == 2) {
      this.lastErr = null;
      if (this.formGroup.controls.emailId.valid && this.formGroup.controls.securityCode.valid) {
        this.userService.resetPwdVerify(this.formGroup.value.emailId, this.formGroup.value.securityCode).then(() => {
          this.curStep = 3;
          this.submitAttempt = false;
        }).catch((err) => {
          this.lastErr = err;
        });
      }
    }
    else if (this.curStep == 3) {
      this.lastErr = null;
      if (this.formGroup.value.password != this.formGroup.value.password2) {
        this.lastErr = "Passwords do not match."
        return;
      }
      if (this.formGroup.valid) {
        this.userService.resetPwdFinish(this.formGroup.value.emailId, this.formGroup.value.securityCode, this.formGroup.value.password).then(() => {
          this.curStep = 1;
          this.uiHelpers.showSuccessMsg('You have reset your password successfully! Please login with your new password.');
          this.navCtrl.pop().then(() => {
            this.navCtrl.navigateForward('/login');
          });
        }).catch((err) => {
          this.lastErr = err;
        });
      }
    }
  }
}
